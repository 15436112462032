<template>
    <div class="level" style="display: inline-flex;">
        <div class="level-item mb-0">
            <div>
                <span class="title is-uppercase is-size-6 mr-1" v-if="name">{{ name }}</span>
                <span class="subtitle is-size-7" v-if="units && price">{{ price | number }} {{ units }}</span>
                <span class="subtitle is-size-7" v-else-if="price > 1000">{{ price | usd }}</span>
                <span class="subtitle is-size-7" v-else-if="price > 0.1">{{ price | usd_2 }}</span>
                <span class="subtitle is-size-7" v-else>{{ price | usd_4 }}</span>
                <span class="subtitle is-size-7 has-text-success" v-if="change >= 0">
                    +{{ Math.abs(change) | percentage_2 }}
                </span>
                <span class="subtitle is-size-7 has-text-danger" v-else-if="change < 0">
                    {{ change | percentage_2 }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: [
        'image',
        'icon',
        'name',
        'price',
        'change',
        'units',
        'url'
    ]
  };
</script>
