<template>
    <b-tooltip label="Copy" type="is-light">
        <b-button type="is-ghost"
            style="padding-top: inherit; padding-bottom: inherit; height: inherit;"
            icon-left="content-copy" :size="size"
            @click="copy"
        >
        </b-button>
    </b-tooltip>
</template>

<script>
  export default {
    props: [
        'size',
        'text'
    ],
    methods: {
        copy() {
            navigator.clipboard.writeText(this.text);
        }
    }
  };
</script>
