<template>
    <div class="hero is-fullheight-with-navbar">
        <div class="hero-head">
            <div class="tothemoon-bg"></div>
        </div>
        <div class="hero-body">
            <div class="container is-fullhd">
                <div class="columns">
                    <div class="column is-12-mobile is-hidden-desktop is-hidden-tablet has-text-centered">
                        <p class="title is-size-1 is-success">Yield Tools for DeFi</p>
                        <p class="subtitle is-size-2">{{ title }}</p>
                    </div>
                    <div class="column is-4-desktop is-4-tablet is-8-mobile m-auto">
                        <img
                            class="is-centered"
                            src="@/assets/home/Yak.png"
                            alt="Yield Yak"
                        />
                    </div>
                    <div class="column is-8-desktop is-8-tablet is-12-mobile mt-auto p-6 mb-6 is-hidden-mobile">
                        <p class="title is-size-1 is-success">{{ title }}</p>
                        <p class="subtitle is-size-3">{{ subtitle }}</p>
                        <div class="buttons is-hidden-mobile">
                            <b-button class="is-uppercase" type="is-success" size="is-extra-large" tag="router-link" :to="{ name: 'Farms' }"
                            >Explore Pools Now</b-button>
                            <b-button class="is-uppercase" size="is-extra-large" tag="a" href="https://docs.yieldyak.com/for-farmers/pool" target="_blank"
                            >How Does It Work?</b-button>
                        </div>
                    </div>
                </div>
                <div class="buttons is-hidden-desktop is-hidden-tablet" style="margin-top: -60px;">
                    <b-button class="is-fullwidth is-uppercase" size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'Farms' }">
                        Explore Pools Now
                    </b-button>
                    <b-button class="is-fullwidth is-uppercase" size="is-extra-large" tag="a" href="https://docs.yieldyak.com/for-farmers/pool" target="_blank">
                        How Does It Work?
                    </b-button>
                </div>
                <home-kpi
                    :tvl="tvl"
                    :farms="activeFarms"
                    :revenue="rewardsPerDayUsd"
                    :staked="totalStakedYak"
                ></home-kpi>
            </div>
        </div>
    </div>
</template>
<style scoped>
.tothemoon-bg {
  background-image: url(../../assets/home/first_section.jpg);
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
</style>
<script>
import { mapGetters } from "vuex";
import { BigNumber } from "ethers";
import HomeKpi from "@/components/Home/Kpi";
import { mapNetworkName } from "@/services/utils"

export default {
    components: {
        HomeKpi,
    },
    computed: {
        ...mapGetters({
            chainId: "accountModule/selectedChainId",
            kpi: "home/kpi",
            farmList: "farms/data",
            tvl: "farms/tvl",
            stakingData: "stakingData",
            priceForSymbol: "prices/priceForSymbol",
        }),
        title: function () {
            if (this.chainId == "2000") return "much yield many wow"
            else return "Sit back and earn"
        },
        subtitle: function () {
            return `Yield Yak provides tools for DeFi users on ${ mapNetworkName(this.chainId) }. Discover a huge selection of autocompounding pools and make your life easier.`
        },
        activeFarms: function () {
            try {
                return this.farmList.filter((r) => !r.deprecated).length;
            } catch {}
        },
        rewardsPerWeek: function () {
            return this.stakingData?.rewardsPerSecond?.mul("86400").mul("7").div("1000000000000000000")
        },
        rewardsPerDayUsd: function () {
            const price = this.priceForSymbol("AVAX");
            const rewardsPerWeek = parseInt(this.rewardsPerWeek);
            if (price && rewardsPerWeek) return price * rewardsPerWeek;
        },
        totalStakedBalance: function () {
            return this.totalStaked || BigNumber.from("0");
        },
        totalStakedYak: function () {
            try {
                return this.stakingData.pools[0].totalStaked.toString().substring(0, 2);
            }
            catch {}
        }
    },
};
</script>