import { Contract, constants, utils, BigNumber } from 'ethers';
import Deployed from '@/contracts/Deployed';
import CustomBondABI from '@/abis/CustomBond';
import PairABI from '@/abis/Pair';
import Vue from 'vue';

const state = () => ({
    data: {},
    initialLoadComplete: false,
    isLoading: false,
    trueBondPrice: {},
    discount: {},
    bondInfo: {},
    pendingPayout: {}
})

const getters = {
  data: state => {
    return state.data
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  },
  bondInfoFor: state => address => {
    try {
      return state.bondInfo[address]
    }
    catch {}
  },
  pendingPayoutFor: state => address => {
    try {
      return state.pendingPayout[address]
    }
    catch {}
  },
  discountFor: state => address => {
    try {
      return state.discount[address]
    }
    catch {}
  },
  trueBondPriceFor: state => address => {
    try {
      return state.trueBondPrice[address]
    }
    catch {
      return BigNumber.from("0")
    }
  },
  maxPayout: state => address => {
    try {
      return state.data[address]
    }
    catch {
      return BigNumber.from("0")
    }
  }
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setPendingPayout(state, payload) {
      try {
        Vue.set(state.pendingPayout, payload.key, payload.value)
      }
      catch (err) {
        consolelog("bonds / setPendingPayout err", err)
      }
    },
    setBondInfo(state, payload) {
      try {
        Vue.set(state.bondInfo, payload.key, payload.value)
      }
      catch (err) {
        console.log("bonds / setBondInfo err", err)
      }
    },
    setDiscount(state, payload) {
      try {
        Vue.set(state.discount, payload.key, payload.value)
      }
      catch (err) {
        console.log("bonds / setDiscount err", err)
      }
    },
    setTrueBondPrice(state, payload) {
      try {
        Vue.set(state.trueBondPrice, payload.key, payload.value)
      }
      catch (err) {
        console.log("bonds / setTrueBondPrice err", err)
      }
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("bonds / setValue err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async payoutFor({ getters, rootGetters }, payload) {
    const { address, amount } = payload
    const provider = rootGetters['accountModule/provider']
    if (provider) {
      const contract = new Contract(address, CustomBondABI, provider)
      try {
        let result = (await contract.payoutFor(amount))._payout;
        // console.log("Actual, LP per Payout:", amount.mul("10000000").div(result).toString())
        // console.log("TrueBondPrice", getters.trueBondPriceFor(address).toString())
        // const discount = utils.parseUnits("1").sub(
        //   getters.trueBondPriceFor(address).mul(utils.parseUnits("1")).div(
        //     amount.mul("10000000").div(result)
        //   )
        // )
        // console.log("Actual:", utils.formatUnits(discount))
        return result
      }
      catch (err) { console.log(err) }
    }
  },
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async deposit({ state, commit, rootGetters }, payload) {
    const account = rootGetters['accountModule/account']
    const signer = rootGetters['accountModule/signer']
    if (account && signer) {
      const { address, depositTokenAddress, amount, maxPrice } = payload
      const depositTokenContract = new Contract(depositTokenAddress, PairABI, signer);
      const approvedBalance = await depositTokenContract.allowance(account, address);

      if (approvedBalance.lt(amount)) {
        let tx = await depositTokenContract.approve(address, constants.MaxUint256);
        await tx.wait(1);
      }

      const contract = new Contract(address, CustomBondABI, signer);
      let tx = await contract.deposit(amount, maxPrice, account);
      let txReceipt = await tx.wait();
      return txReceipt.status;
    }
    return false;
  },
  async redeem({ rootGetters }, payload) {
    const account = rootGetters['accountModule/account']
    const signer = rootGetters['accountModule/signer']
    if (account && signer) {
      const { address } = payload
      const contract = new Contract(address, CustomBondABI, signer);
      let tx = await contract.redeem(account);
      let txReceipt = await tx.wait();
      return txReceipt.status;
    }
    return false;
  },
  async loadData({ state, commit, rootGetters }, payload=null) {
    if (state.isLoading) {
      console.log('vuex/bonds/loadData -- already loading')
      return;
    }
    commit('setIsLoading', true)
    const account = rootGetters['accountModule/account']
    const chainId = rootGetters['accountModule/selectedChainId']
    const provider = rootGetters['accountModule/provider']
    if (chainId && provider) {
      try {
        const bonds = Deployed[chainId].bonds
        for (let bond of bonds) {
          const bondContract = new Contract(bond.address, CustomBondABI, provider);
          const tokenContract = new Contract(bond.rewardToken.address, PairABI, provider);
          const depositTokenContract = new Contract(bond.depositToken.address, PairABI, provider);
          try {
            let deposits, supply, trueBondPrice, payout;
            [
              deposits, 
              supply,
              trueBondPrice,
              payout
            ] = await Promise.all([
              tokenContract.balanceOf(bond.depositToken.address),
              depositTokenContract.totalSupply(),
              bondContract.trueBondPrice(),
              bondContract.payoutFor(utils.parseUnits("1"))
            ])
            
            const lpMarketPrice = deposits.mul(utils.parseUnits("2")).div(supply) // ERC per LP
            const actualPrice = payout._payout

            // console.log("trueBond", trueBondPrice.toString())
            // console.log("inv Bond", utils.parseUnits("1").div(trueBondPrice).toString())
            // console.log("actual  ", actualPrice.toString())

            if (actualPrice.gt('0')) {
              const discount = utils.parseUnits("1").sub(
                lpMarketPrice.mul(utils.parseUnits("1")).div(actualPrice)
              )
              // console.log("discount math", utils.formatUnits(lpMarketPrice), utils.formatUnits(actualPrice), discount)
              commit('setDiscount', { key: bond.address, value: discount })
            }

            if (trueBondPrice.gt('0')) {
              commit('setTrueBondPrice', { key: bond.address, value: trueBondPrice })

              // const discount = utils.parseUnits("1").sub(trueBondPrice.mul(utils.parseUnits("1").div(lpMarketPrice)))
              // console.log("discount math", lpMarketPrice.toString(), trueBondPrice.toString(), discount)
              // commit('setDiscount', { key: bond.address, value: discount })
            }

            const maxPayout = await bondContract.maxPayout();
            commit('setValue', { key: bond.address, value: maxPayout})

            if (account) {
              let bondInfo, pendingPayout;
              [
                bondInfo,
                pendingPayout
              ] = await Promise.all([
                bondContract.bondInfo(account),
                bondContract.pendingPayoutFor(account)
              ])

              commit('setBondInfo', { key: bond.address, value: bondInfo })
              commit('setPendingPayout', { key: bond.address, value: pendingPayout })
            }
          }
          catch (err) {
            console.log("could not load bond data", err)
          }
        }
        // let bondAddress = Deployed[chainId].votingPower
        // let votingPowerContract = new Contract(votingPowerAddress, VotingPowerABI, provider);
        // try {
        //   let votingPower = await votingPowerContract.balanceOf(account);
        //   commit('setValue', { key: 'votingPower', value: votingPower })
        // }
        // catch (err) {
        //   console.log("could not load token balances", err);
        // }

        // // commit('set', data)
        commit('setInitialLoadComplete', true)
      }
      catch (err) { console.log(err) }
    }
    commit('setIsLoading', false)
  },
  clearData({ commit }) {
        commit('setInitialLoadComplete', false)
        commit('set', {})
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}