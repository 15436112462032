<template>
    <section style="height: 100%;">
        <b-notification type="is-warning" class="has-text-centered" v-if="shouldSwitchNetworkDisplay" :closable="false" style="margin-bottom: 0; border-radius: 0; padding: 0.75rem;">
            {{ networkWarningMessage }}
        </b-notification>
        <div v-else class="is-hidden-mobile" style="height: 36px; padding-top: 6px;">
            <div v-if="priceDataOrderedByTicker">
                <marquee-text
                    :repeat="3"
                    :duration="75"
                    :paused="tickerIsPaused"
                    @mouseover="tickerIsPaused = true"
                    @mouseleave="tickerIsPaused = false"
                >
                    <b-tag class="is-success mr-3 ml-4" size="is-medium" style="display: inline-flex;">Yield Yak</b-tag>
                    <TickerItem
                        v-if="priceDataForSymbol('YAK')"
                        :image="priceDataForSymbol('YAK').image"
                        :name="priceDataForSymbol('YAK').symbol"
                        :price="priceDataForSymbol('YAK').price"
                        :change="priceDataForSymbol('YAK').priceChange"
                        class="mr-3"
                    />
                    <TickerItem
                        v-if="tvl"
                        :name="`TVL`"
                        :price="tvl"
                        class="mr-3"
                    />
                    <TickerItem
                        v-if="priceDataForSymbol('AVAX')"
                        :image="priceDataForSymbol('AVAX').image"
                        :name="priceDataForSymbol('AVAX').symbol"
                        :price="priceDataForSymbol('AVAX').price"
                        :change="priceDataForSymbol('AVAX').priceChange"
                        class="mr-3"
                    />
                    <TickerItem
                        v-if="priceDataForSymbol('Gas')"
                        icon="gas-station"
                        :name="priceDataForSymbol('Gas').symbol"
                        :price="priceDataForSymbol('Gas').price"
                        units="gwei"
                        class="mr-3"
                    />
                    <b-tag class="is-success mr-3 ml-4" size="is-medium" style="display: inline-flex;">Winners 24h</b-tag>
                    <TickerItem
                        v-for="item in priceDataOrderedByTicker[0]" :key="item.symbol"
                        :image="item.image"
                        :name="item.symbol"
                        :price="item.price"
                        :change="item.priceChange"
                        :url="item.url"
                        class="mr-3"
                    />
                    <b-tag class="is-danger mr-3 ml-4" size="is-medium" style="display: inline-flex;">Losers 24h</b-tag>
                    <TickerItem
                        v-for="item in priceDataOrderedByTicker[1]" :key="item.symbol"
                        :image="item.image"
                        :name="item.symbol"
                        :price="item.price"
                        :change="item.priceChange"
                        :url="item.url"
                        class="mr-3"
                    />
                </marquee-text>
            </div>
        </div>
        <hr />
        <b-navbar class="container is-fullhd">
            <template #brand>
                <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
                    <img src="/assets/logo.png" alt="Yield Yak" />
                </b-navbar-item>
                <b-navbar-item tag="div" class="is-hidden-tablet">
                    <NetworkSelector :isCompact="true" />
                </b-navbar-item>
                <b-navbar-item tag="div" class="is-hidden-tablet">
                    <AccountSelector :hideLabel="true" />
                </b-navbar-item>
            </template>
            <template #start>
                <NavItem routeName="Swap" title="Yak Swap" icon="swap-horizontal" v-if="isSwapSupported" />
                <NavItem routeName="Farms" title="Yak Pools" icon="pool" v-if="isFarmsSupported" />
                <b-navbar-dropdown v-if="isEarnSupported">
                    <template #label>
                        <p class="title is-size-5">
                            Earn
                        </p>
                    </template>
                    <b-navbar-item tag="router-link" :to="{ name: 'Stake' }">
                        <div class="content px-1 py-2">
                            <p class="title is-size-5">
                                Stake YAK
                            </p>
                            <p class="subtitle">
                                Earn from platform fees
                            </p>
                        </div>
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ name: 'ARC' }">
                        <div class="content px-1 py-2">
                            <p class="title is-size-5">
                                ARC
                            </p>
                            <p class="subtitle">
                                Platform fee distribution
                            </p>
                        </div>
                    </b-navbar-item>
                </b-navbar-dropdown>
                <NavItem routeName="LiquidStaking" title="Liquid Staking" icon="water" v-if="isLiquidStakingSupported" />
                <!-- <NavItem routeName="Bridge" title="Bridge" icon="bridge" v-if="isBridgeSupported" /> -->
            </template>
            <template #end>
                <b-navbar-item tag="div" class="is-hidden-mobile">
                    <VYak />
                </b-navbar-item>
                <b-navbar-item tag="div" class="is-hidden-mobile">
                    <NetworkSelector />
                </b-navbar-item>
                <b-navbar-item tag="div" class="is-hidden-mobile">
                    <AccountSelector />
                </b-navbar-item>
            </template>
        </b-navbar>
        <hr />

        <router-view></router-view>

        <hr />

        <div class="container is-fullhd my-6">
            <div class="columns is-mobile">
                <div class="column is-3-tablet is-5-mobile content">
                    <img src="/assets/logo.png" alt="Yield Yak" width="100" />
                    <div class="buttons">
                        <b-button tag="a" type="is-text" size="is-large" icon-left="twitter" href="https://x    .com/yieldyak_" target="_blank" />
                        <b-button tag="a" type="is-text" size="is-large" icon-left="telegram" href="https://t.me/yieldyak" target="_blank" />
                        <b-button tag="a" type="is-text" size="is-large" icon-left="discord" href="https://discord.gg/JBbPKzX4rg" target="_blank" />
                        <!-- <b-button tag="a" type="is-text" size="is-large" icon-left="post" href="https://yieldyak.medium.com/" target="_blank" /> -->
                        <!-- <b-button tag="a" type="is-text" size="is-large" icon-left="github" href="https://github.com/yieldyak" target="_blank" /> -->
                    </div>
                    <p>Tools for defi users. Yak Pools autocompound farm rewards, with support for Avalanche and Arbitrum. Yak Swap finds the best price execution for dex trades.</p>
                    <p>Use at your own risk.</p>
                </div>
                <div class="column is-offset-1">
                    <div class="columns is-multiline">
                        <div class="column is-3 content">
                            <p class="subtitle">Community</p>
                            <ul>
                                <li><a href="https://x.com/yieldyak_" target="_blank">X (Twitter)</a></li>
                                <li><a href="https://t.me/yieldyak" target="_blank">Telegram</a></li>
                                <li><a href="https://discord.gg/JBbPKzX4rg" target="_blank">Discord</a></li>
                                <li><a href="https://debank.com/official/Yield_Yak" target="_blank">DeBank</a></li>
                            </ul>
                        </div>
                        <div class="column is-3 content">
                            <p class="subtitle">External</p>
                            <ul>
                                <li><a href="https://www.coingecko.com/en/coins/yield-yak" target="_blank">CoinGecko</a></li>
                                <li><a href="https://coinmarketcap.com/currencies/yield-yak/" target="_blank">Coin Market Cap</a></li>
                                <li><a href="https://defillama.com/protocol/yield-yak" target="_blank">Defi Llama</a></li>
                                <li><a href="https://www.yieldyak-analytics.com/" target="_blank">Yield Yak Analytics</a></li>
                            </ul>
                        </div>
                        <div class="column is-3 content">
                            <p class="subtitle">Developers</p>
                            <ul>
                                <li><a href="https://docs.yieldyak.com/" target="_blank">Docs</a></li>
                                <li><a href="https://github.com/yieldyak" target="_blank">GitHub</a></li>
                                <li><a href="https://github.com/yieldyak/brand-assets" target="_blank">Brand Assets</a></li>
                                <li><a href="https://thegraph.com/hosted-service/subgraph/yieldyak/reinvest-tracker" target="_blank">The Graph: Pools</a></li>
                                <li><a href="https://thegraph.com/hosted-service/subgraph/yieldyak/yak-aggregator" target="_blank">The Graph: Yak Swap</a></li>
                            </ul>
                        </div>
                        <div class="column is-3 content">
                            <p class="subtitle">Beginners</p>
                            <ul>
                                <li><a href="https://www.youtube.com/watch?v=5kGsuKXjqcg" target="_blank">Intro to YY (YouTube)</a></li>
                                <li><a href="https://academy.binance.com/en/articles/what-is-yield-farming-in-decentralized-finance-defi" target="_blank">What is yield farming?</a></li>
                                <li><a href="https://docs.yieldyak.com/" target="_blank">Docs</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import {mapGetters} from 'vuex';
  import MarqueeText from 'vue-marquee-text-component'
  import TickerItem from '@/components/TickerItem';
  import NetworkSelector from '@/components/Nav/Network';
  import AccountSelector from '@/components/Nav/Account';
  import NavItem from '@/components/Nav/NavItem';
  import VYak from '@/components/Nav/VYak';
  import { mapNetworkName } from "@/services/utils"
  import Chains from '@/contracts/Chains';

  export default {
    components: {
        MarqueeText,
        TickerItem,
        NetworkSelector,
        AccountSelector,
        NavItem,
        VYak
    },
    computed: {
        ...mapGetters({
            chainId: 'accountModule/selectedChainId',
            connectedChainId: 'accountModule/connectedChainId',
            shouldSwitchNetworkDisplay: 'accountModule/shouldSwitchNetworkDisplay',
            priceDataOrderedByTicker: 'prices/priceDataOrderedByTicker',
            priceDataForSymbol: 'prices/priceDataForSymbol',
            tvl: "farms/tvl",
            isSwapSupported: "swap/isSupported",
            isFarmsSupported: "farms/isSupported",
            isLiquidStakingSupported: "yyAvax/isSupported",
            isBridgeSupported: "bridge/isSupported"
        }),
        networkWarningMessage: function () {
            return `Your wallet is currently connected to ${ mapNetworkName(this.connectedChainId) } instead of ${ mapNetworkName(this.chainId) }.`
        },
        isEarnSupported: function () {
            try {
                return Chains[this.chainId].supportedFeatures.earn
            }
            catch {
                return false
            }
        }
    },
    data() {
        return {
            tickerIsPaused: false,
        }
    },
    beforeMount() {
        this.$store.dispatch("handleFirstUpdate");
    },
  };
</script>

<style lang="scss">

    $snackbar-background: #383B3A; // 24dp
    $button-radius: 0.5rem;

    p:not(.with-hover p):not(button p):not(a p) {
        cursor: default;
    }

    .button:not(.field .button) {
        border-radius: $button-radius !important;
    }

    .button.is-small {
        padding: 0.75rem 1rem !important;
        font-size: 0.75rem !important;
    }

    .is-rounded-rectangle {
        border-radius: 6px !important;
    }

    .tab-content {
        padding: 0 !important;
    }

    .table td {
        vertical-align: middle !important;
    }

    $family-primary: "Rubik", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    $family-secondary: "Rubik", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    // $family-primary: "Comic Sans MS", "Rubik", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    // $family-secondary: "Comic Sans MS", "Rubik", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

    $grey: rgba(255, 255, 255, 0.38); //#7a7a7a;
    $grey-lighter: rgba(255, 255, 255, 0.6);
    $light: rgba(255, 255, 255, 0.92); // #f0f0f0;
    $dark: #121614; // #262626
    $text: rgba(255, 255, 255, 0.72); // #d0d0d0;
    $text-strong: $light;
    // $text-light: $light;

    $primary: #43B763; // 553f77
    $link: $text-strong;
    $success: #43B763; // 00c864
    $info: $primary;
    $warning: #FFDC14;
    $danger: #C80055;

    $scheme-main: $dark;
    $scheme-main-bis: #363636;
    $scheme-main-ter: #363636;
    $link-hover: $text-strong;
    $link-focus: $link-hover;

    // $column-gap: 0.125rem;

    $tabs-link-color: $text;
    $tabs-link-active-color: $link-hover;
    $tabs-link-active-border-bottom-color: $success;
    $tabs-link-hover-border-bottom-color: $link-hover;

    $input-color: $text;
    $input-arrow: $link-hover;
    $input-disabled-background-color: $dark;
    $input-disabled-border-color: $scheme-main-bis;

    $button-static-color: $text;
    $button-text-hover-background-color: $dark;
    // $button-font-size: 0.875rem;
    // $button-border-color: $text;
    $button-family: $family-secondary;
    $button-weight: 600;
    $button-color: $text-strong;
    $button-hover-color: $button-color;
    $button-focus-color: $button-color;
    $button-focus-border-color: "transparent";
    // $button-padding-horizontal: 1.5rem;
    // $button-padding-vertical: 1rem;

    $button-text-decoration: "none";
    // $select-family: $family-secondary;
    // $input-family: $family-secondary;

    $body-size: 16px;
    $body-font-size: 0.9375rem; //0.75rem;

    $size-1: 3.5rem; //2.25rem; // 3rem;
    $size-2: 2rem; //1.875rem; // 2.5rem;
    $size-3: 1.5rem; // 2rem;
    $size-4: 1.125rem; // 1.5rem;
    $size-5: 0.9375rem; // 1.25rem;
    $size-6: 0.75rem; // 1rem;
    $size-7: 0.75rem; // 0.75rem;
    $navbar-item-img-max-height: 2.25rem;
    // $navbar-item-active-color: "transparent";

    $divider-margin-size: 1rem;

    $border: #363636;
    $box-background-color: #1E2220; // Dark theme: 01dp // #303030;
    // $box-color: $border;
    $box-padding: 1.5rem 1.25rem;

    $card-background-color: $box-background-color;
    $card-content-padding: 1rem;
    $card-header-weight: none;
    $card-radius: 1rem;

    $hr-background-color: $box-background-color;
    $hr-margin: 0;

    $table-background-color: $box-background-color;
    $table-cell-padding: 0.875rem 0.5rem;

    $navbar-z: 1;
    // $navbar-item-hover-color: $link-hover;
    // $navbar-item-hover-background-color: $box-background-color;
    $navbar-item-active-background-color: $box-background-color;
    $navbar-padding-horizontal: 4rem;
    $navbar-dropdown-background-color: $box-background-color;
    $navbar-dropdown-item-hover-color: $link-hover;
    $navbar-dropdown-item-hover-background-color: rgba(255, 255, 255, 0.07);
    // $navbar-dropdown-background-color: $box-background-color;
    // $navbar-dropdown-z: 10;
    // $dropdown-content-z: 10;
    $dropdown-content-background-color: $box-background-color;
    $dropdown-divider-background-color: $border;
    $dropdown-item-hover-color: $link-hover;
    $dropdown-item-hover-background-color: rgba(255, 255, 255, 0.07);
    $dropdown-item-active-color: $text;
    $dropdown-item-active-background-color: $dark;
    // $dropdown-content-padding-top: 0.75rem;
    // $dropdown-content-padding-bottom: 0.75rem;

    // $radius-large: 1rem;

    @import "~bulma/sass/utilities/_all";
    @import '~bulma';
    @import "~buefy/src/scss/buefy";
    @import "~@creativebulma/bulma-divider";

    .snackbar {
        background: $snackbar-background !important;
        box-shadow: none !important;
    }

    .hero-body {
        @include mobile {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }

    p.is-success {
        color: $success !important;
    }
    
    p.subtitle {
        font-weight: 400 !important;
    }

    .title.is-size-1,
    .title.is-size-2 {
        font-weight: 600 !important;
    }

    .title.is-size-1 {
        @include mobile {
            font-size: 2rem !important;
        }
    }

    .subtitle.is-size-7 {
        font-size: $size-7;
    }

    .subtitle.is-size-2 {
        @include mobile {
            font-size: 1.5rem !important;
        }
    }
    
    .title:not(.is-spaced) + .subtitle {
        margin-top: -1rem;
    }

    select {
        // font-family: $family-primary !important;
        color: $text-strong !important;
        font-weight: 600 !important;
    }

    input {
        font-size: $body-font-size !important;
        // background-color: transparent !important;
        // border: 0 !important;
        // background-color: inherit !important;
        // border: 1px solid $dark !important;
        // box-shadow: none !important;
        border-radius: $button-radius !important;
        border-color: $grey !important;
    }

    .control.has-icons-left .icon,
    .control.has-icons-left input {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .control.has-icons-left .icon.is-left {
        left: 0.5rem;
        padding-left: 0.25rem;
    }

    .control.has-icons-left .input {
        padding-left: 2.5rem;
    }

    .control.has-icons-left .icon,
    input::placeholder {
        color: $text !important;
    }

    input[disabled] {
        opacity: 0.5;
    }

    input:focus {
        border-color: $text !important;
        box-shadow: none !important;
    }

    input:read-only {
        cursor: default;
    }

    input.is-medium {
        font-weight: 600 !important;
        padding: 1.5rem 0.5rem !important;
        border: 1px solid $dark !important;
    }

    input.is-large {
        font-weight: 600 !important;
        // padding-top: 0 !important;
        // padding-bottom: 0 !important;
        padding: 0.25rem !important;
        font-size: 2.25rem !important;
        color: $light !important;
        // border: 1px solid $border !important;
        border: 0;
        background-color: transparent;
        margin-bottom: 0;
        height: 4rem;
    }

    input.is-large::placeholder {
        color: $grey !important;
    }

    input.is-large:focus {
        border: 0;
        box-shadow: none;
    }

    .bn-onboard-custom.bn-onboard-modal {
        z-index: 2;
        font-family: $family-primary;
    }

    .bn-onboard-custom.bn-onboard-modal-content.bn-onboard-dark-mode {
        background-color: $card-background-color;
        border-radius: $card-radius;
    }

    button.bn-onboard-custom.bn-onboard-dark-mode-link {
        background-color: transparent;
        border-color: $border;
        color: $button-color;
        font-weight: $button-weight;
        border-radius: $button-radius;
        padding: 1.5rem 1rem;
        font-size: $size-5;
    }

    button.bn-onboard-custom.bn-onboard-dark-mode-link:hover {
        border-color: $light;
    }

    .bn-onboard-custom.bn-onboard-dark-mode-background-hover:hover {
        background-color: $dark !important;
        border-radius: $button-radius;
    }

    .level.is-mobile .level-item:not(:last-child) {
        margin-right: 0.5rem !important;
    }

    .notification.is-primary {
        background-color: $box-background-color;
        border: 2px solid $success;
    }

    .message.is-primary, 
    .message.is-success, 
    .message.is-danger, 
    .message.is-warning {
        background-color: $dark !important;
    }

    .message.is-success {
        background-color: #00c86450 !important; // $success with low opacity
        // background-blend-mode: lighten;
    }

    .message.is-danger {
        background-color: #C8005550 !important; // $danger with low opacity
        // background-blend-mode: lighten;
    }

    .message.is-warning {
        background-color: #FFDC1440 !important; // $warning with low opacity
        // background-blend-mode: lighten;
    }

    .message.is-primary .message-body,
    .message.is-success .message-body, 
    .message.is-danger .message-body, 
    .message.is-warning .message-body {
        color: $light !important;
    }

    .button.is-light {
        color: $light !important;
        background-color: $box-background-color !important;
    }

    .heading {
        color: $grey !important;
        font-size: $size-6 !important;
        font-weight: 600 !important;
        letter-spacing: 0.5px !important;
    }

    .divider {
        color: $grey !important;
        font-size: $size-6 !important;
        font-weight: 600 !important;
    }

    .image img.is-rounded {
        background: $dark !important;
        border: 2px solid $dark !important;
        object-fit: contain !important;
    }

    .image img.is-rounded.is-small {
        border-width: 1px !important;
    }

    .image img.is-large {
        border-width: 4px !important;
    }

    .title.is-size-4, .title.is-size-3 {
        // color: $text;

        @include mobile {
            font-size: 1.25rem !important;
        }
    }

    .subtitle.is-size-5 {
        @include mobile {
            font-size: 0.75rem !important;
        }
    }

    .is-size-4 {
        @include mobile {
            font-size: 0.75rem !important;
        }
    }

    .tabs a {
        border-bottom-width: 2px !important;
    }

    .tabs {
        @include mobile {
            // font-size: 0.75rem !important;
            overflow-x: hidden !important;
        }
    }

    .box.is-dark {
        background-color: #252927 !important; // 03dp
        box-shadow: none; // !important;
        padding: 0.5rem 1rem;
        transition: all .3s ease;
        min-width: 110px !important;
    }

    .box.is-dark.is-outlined {
        border: 1px solid $light;
    }

    .box.is-dark .field.has-addons {
        border-radius: 2px !important;
        border: 1px solid $border !important;
    }

    .box.is-dark p.content {
        color: $grey !important;
        overflow: hidden !important;
    }

    .box.is-dark p {
        overflow: hidden !important;
    }

    .box.has-border {
        box-shadow: inset 0 0 0 2px $success;
    }

    .box.has-border-top {
        border-style: solid;
        border-width: 2px 0 0 0;
        border-color: $primary;
        padding: 0.75rem 1rem 1.25rem 1rem !important;
    }

    .is-182x182 {
        width: 182px;
        height: 182px;

        @include mobile {
            width: 91px;
            height: 91px;
        }
    }

    .card {
        box-shadow: none !important;
    }

    .card-content-offset {
        margin-top: 48px !important;

        // @include mobile {
        //     margin-top: 29px !important;
        // }
    }

    .card-tokens-offset {
        position: relative !important;
        top: -48px !important;
        margin-bottom: -54px !important;

        // @include mobile {
        //     top: -29px !important;
        //     margin-bottom: -29px !important;
        // }
    }

    .card-image-offset {
        margin-bottom: -48px !important;

        // @include mobile {
        //     margin-bottom: -29px !important;
        // }
    }

    // .card-content {

    //     @include mobile {
    //         padding: 1rem;
    //     }
    // }

    a.with-hover > .card-content {
        background: $box-background-color;
        transition: all .3s ease;
    }
    
    a.with-hover > .card-content.is-success {
        background: $success;
        opacity: 1;
    }

    a.with-hover:hover .card-content {
        background: rgba(255,255,255, 0.07);
        transition: all .2s ease;
    }

    a.with-hover:hover .card-content.is-success {
        background: $success;
        opacity: 0.9;
        transition: all .2s ease;
    }
    
    a.with-hover:hover .box.is-dark {
        background: rgba(255,255,255, 0.07) !important;
        transition: all .2s ease;
    }

    a.with-hover > .card-image {
        opacity: 0.9;
        transition: all .3s ease;
    }

    a.with-hover:hover .card-image {
        opacity: 1.0;
        transition: all .2s ease;
    }

    a.without-hover {
        cursor: default;
    }

    .container {
        padding: 0 1rem !important;

        @include mobile {
            padding: 0 0.5rem !important;
        }
    }

    .dropdown-item {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .navbar-item img {
        max-height: 3rem !important;

        @include mobile {
            max-height: 2rem !important;
        }
    }

    .navbar-burger {
        height: 4rem;
    }

    .navbar-burger span {
        height: 2px;
    }

    .button {
        font-weight: 600 !important;
        padding: 1.5rem 1rem;
        font-size: $size-5;
        background: transparent;

        // @include mobile {
        //     padding: 1rem 0.5rem;
        // }
    }

    .button.is-normal {
        padding: 1rem;
    }

    .button.is-extra-large {
        padding: 2.25rem 2.5rem !important;
        font-size: 1.25rem !important;
        border-width: 4px !important;
    }

    button[disabled].button.is-ghost,
    .button.is-ghost:hover {
        text-decoration: none;
        background-color: $dark;
        border-color: transparent;
    }

    .button.is-ghost {
        color: $text;
    }

    .button.is-ghost.is-dark {
        color: $grey;
        background-color: transparent;
    }

    .button.is-ghost:hover {
        background-color: rgba(255,255,255, 0.07);
        color: $light;
    }

    .button.is-ghost.is-dark:hover {
        background-color: transparent;
        color: $text;
    }

    .button.is-ghost:focus {
        box-shadow: none;
    }

</style>
