import { request, gql } from 'graphql-request'

function getSubgraphUrl(chainId) {
    switch (chainId) {
        case "42161":
            return "https://api.thegraph.com/subgraphs/name/yieldyak/arbitrum-reinvest-tracker"
        case "43114":
            return "https://api.thegraph.com/subgraphs/name/yieldyak/reinvest-tracker"
        default:
            // Default to Avalanche subgraph
            return "https://api.thegraph.com/subgraphs/name/yieldyak/reinvest-tracker"
    }
}

async function getActiveDeposits(chainId, account) {
    const query = gql`
    {
        depositStatuses(
          where:{
            user:"${ account.toLowerCase() }",
            activeDeposit_gt: "0"
        }) {
          farm { id }
          activeDeposit
        }
    }
    `
    
    try {
        const subgraph = getSubgraphUrl(chainId)
        const data = await request(subgraph, query);
        return data.depositStatuses
    }
    catch (err) {
        console.log(err);
        throw("returns::getActiveDeposits Could not fetch data");
    }
}

async function getRecentFarmActivity(chainId, farmAddress) {
    let deadline = Math.floor(Date.now() / 1000) - 24 * 60 * 60 * 14; // 14 days
    let limit = 20; // Max 20 results
    let farmId = farmAddress.toLowerCase();
    const query = gql`
    {
      reinvests(
          where: {
              farm: "${farmId}",
              blockTimestamp_gt: "${deadline}"
          },
          orderBy: blockNumber,
          orderDirection: desc,
          first: ${limit}
      ) {
          farm {
              id
              name
          }
          by { id }
          blockNumber
          blockTimestamp
          transactionHash
      }
       withdraws(
          where: {
              farm: "${farmId}",
              blockTimestamp_gt: "${deadline}"
          },
          orderBy: blockNumber,
          orderDirection: desc,
          first: ${limit}
      ) {
          farm {
              id
              name
          }
          by { id }
          blockNumber
          blockTimestamp
          transactionHash
      }
      deposits(
          where: {
              farm: "${farmId}",
              blockTimestamp_gt: "${deadline}"
          },
          orderBy: blockNumber,
          orderDirection: desc,
          first: ${limit}
      ) {
          farm {
              id
              name
          }
          by { id }
          blockNumber
          blockTimestamp
          transactionHash
      }
    }
    `

    try {
        const subgraph = getSubgraphUrl(chainId)
        const data = await request(subgraph, query);
        data.reinvests.map((item) => (item.event = "Reinvest"));
        data.deposits.map((item) => (item.event = "Deposit"));
        data.withdraws.map((item) => (item.event = "Withdraw"));
        const activity = data.withdraws.concat(
            data.deposits,
            data.reinvests
        );
        return activity.sort(function (a, b) {
          return b.blockTimestamp - a.blockTimestamp;
        }).slice(0, limit);
    }
    catch (err) {
        console.log(err);
        throw("getFarmActivity: Could not fetch data");
    }


}

async function getFirehose(chainId) {
    let deadline = Math.floor(Date.now() / 1000) - 24 * 60 * 60;
    let limit = 100;
    const query = gql`
    {
        reinvests(
            where: {
                blockTimestamp_gt: "${ deadline }"
            },
            orderBy: blockNumber,
            orderDirection: desc,
            first: ${limit}
        ) {
            farm {
                id
                name
            }
            by { id }
            blockNumber
            blockTimestamp
            transactionHash
        }
    }
    `
    
    try {
        const subgraph = getSubgraphUrl(chainId)
        const data = await request(subgraph, query);
        return data.reinvests
    }
    catch (err) {
        console.log(err);
        throw("returns::getFirehose Could not fetch data");
    }
}

async function getReinvestLeaderboard(chainId, limit = 5) {
    const query = gql`
    {
        users(first: ${limit}, orderBy: reinvestCount, orderDirection: desc) {
          id
          reinvestCount
        }
    }
    `
    
    try {
        const subgraph = getSubgraphUrl(chainId)
        const data = await request(subgraph, query);
        return data.users
    }
    catch (err) {
        console.log(err);
        throw("returns::getReinvestLeaderboard Could not fetch data");
    }

}

async function getReinvests(chainId, farm, limit = 1000, skip = 0) {
    // const deadline = Math.round((new Date()).getTime() / 1000) - 60*60*24*7; // One week ago
    const deadline = Math.round((new Date()).getTime() / 1000) - 60*60*24*90; // One q ago

    const query = gql`
    {
        reinvests(
            where: {
                farm: "${ farm.toLowerCase() }",
                blockTimestamp_gte: ${deadline}
            },
            orderBy: blockNumber,
            orderDirection: desc,
            first: ${limit},
            skip: ${skip}
        ) {
            blockTimestamp
            totalDeposits
            ratio
        }
    }
    `
    
    try {
        const subgraph = getSubgraphUrl(chainId)
        const data = await request(subgraph, query);
        return data.reinvests
    }
    catch (err) {
        console.log(err);
        throw("returns::getReinvests Could not fetch data");
    }
}

export {
    getReinvests, getFirehose, getReinvestLeaderboard, getActiveDeposits, getRecentFarmActivity
}