<template>
    <b-button
        icon-left="school" :size="size" :expanded="expanded"
        tag="a" :href="href" target="_blank"
    >
        <span>
            {{ label }}
        </span>
    </b-button>
</template>

<script>
  export default {
    props: [
        'label',
        'href',
        'size',
        'expanded'
    ],
  };
</script>
