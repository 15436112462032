<template>
  <section>
    <div class="container is-fullhd mt-3" v-if="isBoostsSupported">
      <div class="box p-0" style="overflow: hidden;">
        <div class="columns p-0 p-mobile-0">
          <div class="column is-4-desktop is-6-tablet is-12-mobile">
            <b-image
                class="is-hidden-mobile"
                :src="ASSETS_URL + '/banners/' + yySymbol.toLowerCase() + '.jpeg'"
                alt="Yield Yak"
                ratio="600by400"
            ></b-image>
            <b-image
                class="is-hidden-tablet"
                :src="ASSETS_URL + '/banners/' + yySymbol.toLowerCase() + '.jpeg'"
                alt="Yield Yak"
                ratio="1200by800"
            ></b-image>
          </div>
          <div class="column has-text-centered px-4 pb-4">
            <p class="title is-uppercase pt-6 is-success">
              Boosted {{ symbol }} Pools
            </p>
            <p class="subtitle">
              Farm {{ yySymbol }} to receive rewards from boosted pools
            </p>
            <p class="heading">
              Enjoy the Boost
            </p>
            <p class="subtitle">
              Boosted pools are available to everyone
            </p>
            <p class="buttons" style="justify-content: center;">
              <ButtonMedium :href="mediumPostUrl" />
              <b-button type="is-primary" @click="scrollToBoostedFarms">
                <span>Browse Boosted Pools</span>
                <b-icon icon="arrow-right" size="is-small" />
              </b-button>
            </p>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-variable pb-6 mt-6">

        <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile">
          <p class="title">
            Yield Yak Stats
          </p>
          <yy-ptp-stats
            :symbol="symbol.toUpperCase()"
            :tokenAddress="token"
            :veTokenAddress="veToken"
            :yyTokenAddress="yyTokenAddress"
            :underlyingBalance="underlyingBalanceFor(symbol)"
          />
        </div>
        <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile">
          <p class="title has-text-centered-mobile">
              {{ yySymbol }}
          </p>
          <token-info
            :address="yyTokenAddress"
            :symbol="yySymbol"
            :totalSupply="underlyingBalanceFor(symbol)"
            :underlyingAddress="token"
            :underlyingSymbol="symbol"
          />
        </div>
        <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile">
          <p class="title has-text-centered-mobile">
              Farm
          </p>
          <section v-for="farm in compoundFarmList" :key="farm.address" >
            <FarmCard 
              :address="farm.address"
              :depositToken="farm.depositToken"
              :deprecated="farm.deprecated"
              :lastReinvest="farm.lastReinvest"
              :lpToken="farm.lpToken"
              :rewardToken="farm.rewardToken"
              :name="farm.name"
              :reinvestRewardBips="farm.reinvestRewardBips"
              :timelock="farm.timelock"
              :token0="farm.token0"
              :token1="farm.token1"
              :totalDeposits="farm.totalDeposits"
              :totalSupply="farm.totalSupply"
              :pendingRewards="farm.pendingRewards"
              :platform="farm.platform"
              :tags="farm.tags"
              :tvl="farm.tvl"
              :farm="farm"
              :hoverable="true"
              :collapsible="true"
            ></FarmCard>
          </section>
        </div>
        <!-- <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile">
          <p class="title has-text-centered-mobile">
              Convert
          </p>
          <yy-ptp-deposit
            :symbol="symbol.toUpperCase()"
            :tokenAddress="token"
            :yyTokenAddress="yyTokenAddress"
          />
        </div> -->
        <div class="column is-12 mt-6" v-if="stakingAddress">
          <div class="level">
            <div class="level-right">
              <div class="level-item">
                <p class="title">
                  Farm {{ yySymbol }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile" v-if="stakingAddress">
          <section class="card">
            <div class="card-content">
              <p class="title">Info</p>
              <p class="content">Deposit {{ yySymbol }} to earn rewards.</p>
                <div class="level is-mobile mb-1">
                    <div class="level-left">
                        <div class="level-item">
                            <p class="subtitle is-size-5">
                                <span>Stats</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="box is-dark">
                    <p class="heading">Total Deposits</p>
                    <p class="title">
                        <balance
                            :amount="stakedBalance(symbol)"
                            :symbol="yySymbol"
                            :useText="true"
                            :animationDuration="100"
                            :decimalPlaces="0"
                            :underlying="[yyTokenAddress]"
                        />
                    </p>
                </div>
                <div class="box is-dark">
                    <p class="heading">APR (7D)</p>
                    <p class="title has-text-grey">
                        tbd
                    </p>
                </div>
            </div>
          </section>
        </div>
        <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile" v-if="stakingAddress">
          <yy-ptp-farm
            :stakingAddress="stakingAddress"
            :symbol="symbol.toUpperCase()"
            :yyTokenAddress="yyTokenAddress"
          />
        </div>
        <div class="column pt-3 is-4-desktop is-6-tablet is-12-mobile" v-if="stakingAddress">
          <yy-ptp-claim
            :symbol="symbol.toUpperCase()"
            :tokenAddress="token"
            :yyTokenAddress="yyTokenAddress"
          />
        </div>

        <div class="column is-12 mt-6">
          <div class="level" ref="boosted-farms">
            <div class="level-right">
              <div class="level-item">
                <p class="title">
                  Boosted Pools
                </p>
              </div>
            </div>
          </div>
        </div>
        <section v-for="farm in boostedFarmList" :key="farm.address" class="column is-4-desktop is-6-tablet is-12-mobile my-3">
          <FarmCard 
            :address="farm.address"
            :depositToken="farm.depositToken"
            :deprecated="farm.deprecated"
            :lastReinvest="farm.lastReinvest"
            :lpToken="farm.lpToken"
            :rewardToken="farm.rewardToken"
            :name="farm.name"
            :reinvestRewardBips="farm.reinvestRewardBips"
            :timelock="farm.timelock"
            :token0="farm.token0"
            :token1="farm.token1"
            :totalDeposits="farm.totalDeposits"
            :totalSupply="farm.totalSupply"
            :pendingRewards="farm.pendingRewards"
            :platform="farm.platform"
            :tags="farm.tags"
            :tvl="farm.tvl"
            :farm="farm"
            :hoverable="true"
            :collapsible="true"
            :hidePlatformImage="true"
          ></FarmCard>
        </section>
      </div>
    </div>

    <div v-else>
      <div class="container is-fullhd mt-3 mb-6">
        <PageNotAvailable />
      </div>
    </div>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex';

  import Balance from "@/components/Balance";
  import ButtonCopy from "@/components/Buttons/Copy";
  import ButtonDocs from "@/components/Buttons/Docs";
  import ButtonMedium from "@/components/Buttons/Medium";
  import FarmCard from '@/components/Farms/Card';
  import PageNotAvailable from "@/components/PageNotAvailable";
  import YyPtpClaim from "@/components/Boosts/Claim";
  import YyPtpDeposit from "@/components/Boosts/Deposit";
  import YyPtpFarm from "@/components/Boosts/Farm";
  import YyPtpStats from "@/components/Boosts/Stats";
  import TokenInfo from "@/components/Boosts/TokenInfo";

  import Chains from '@/contracts/Chains';
  import DeployedAddresses from '@/contracts/Deployed';
  import { ASSETS_URL } from '@/settings';

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
      Balance,
      ButtonCopy,
      ButtonDocs,
      ButtonMedium,
      FarmCard,
      PageNotAvailable,
      YyPtpClaim,
      YyPtpDeposit,
      YyPtpFarm,
      TokenInfo,
      YyPtpStats
    },
    props: [
      'symbol'
    ],
    computed: {
      ...mapGetters({
        chainId: 'accountModule/selectedChainId',
        tokenBalances: 'balances/data',
        stakedBalance: 'yyPtp/stakedBalance',
        sortedFarmList: 'farms/sortedFarmList',
        underlyingBalanceFor: 'yyPtp/underlyingBalanceFor',
      }),
      isBoostsSupported: function () {
          try {
              return Chains[this.chainId].supportedFeatures.boosts
          }
          catch {
              return false
          }
      },
      token: function () {
        return DeployedAddresses[this.chainId].yy[this.symbol].token;
      },
      yySymbol: function () {
        return `yy${this.symbol.toUpperCase()}`
      },
      yyTokenAddress: function () {
        return DeployedAddresses[this.chainId].yy[this.symbol].yy;
      },
      veToken: function () {
        return DeployedAddresses[this.chainId].yy[this.symbol].ve;
      },
      veSymbol: function () {
        return `ve${this.symbol.toUpperCase()}`
      },
      stakingAddress: function () {
        return DeployedAddresses[this.chainId].yy[this.symbol].staking;
      },
      compoundFarmList: function () {
        let filterObject = {
          platformFilter: "yak",
          depositToken: this.yyTokenAddress
        }
        return this.sortedFarmList("tvl", filterObject);
      },
      boostedFarmList: function () {
        let filterObject = {
          // platformFilter: this.symbol.toLowerCase() == "joe" ? "traderjoe" : "platypus",
          farmTypeFilter: this.veSymbol.toLowerCase()
        }
        return this.sortedFarmList("tvl", filterObject);
      },
      mediumPostUrl: function () {
          if (this.symbol.toLowerCase() == "joe") return "https://yieldyak.medium.com/yield-yak-launches-yak-rush-for-yyjoe-alongside-vejoe-strategies-to-boost-trader-joe-farming-yields-d3c612672a44"
          else if (this.symbol.toLowerCase() == "ptp") return "https://yieldyak.medium.com/yield-yak-reveals-platypus-launch-strategy-including-treasury-contribution-and-double-rewards-pool-8e6e4458b12e"
      }
    },
    data() {
      return {
        polling: null,
        ASSETS_URL
      }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        const depositTokenBalances = [
          this.token,
          this.yyTokenAddress
        ]
        const payload = {
            symbol: this.symbol
        }
        await Promise.all([
          this.$store.dispatch('yyPtp/loadData', payload),
          this.$store.dispatch('yyPtp/loadStakingData', payload),
          this.$store.dispatch('balances/loadData', depositTokenBalances)
        ])
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      scrollToBoostedFarms() {
        this.scrollTo("boosted-farms")
      },
      scrollTo(refName) {
        var element = this.$refs[refName];
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  };
</script>