<template>
    <div class="card">
        <div class="card-content">
            <p class="title is-size-5">
                Pools Available for {{ mapTokenSymbol(toTokenAddress) }}
            </p>
            <p class="subtitle">
                xxx
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {},
    props: [
      'trade'
    ],
    computed: {
      ...mapGetters({
        chainId: 'accountModule/selectedChainId',
        tokenList: 'swap/tokenList'
      }),
      toTokenAddress: function() {
          return this.trade.path[this.trade.path.length - 1]
      },
    },
    methods: {
      mapTokenObject(address) {
          try {
            let tmp = this.tokenList.filter(el => el.address.toLowerCase().indexOf(address.toLowerCase()) !== -1)
            return tmp[0]
          }
          catch {
            console.log("debug::mapTokenObject token not found")
          }
      },
      mapTokenSymbol(address) {
        try {
            let token = this.mapTokenObject(address)
            return token.symbol;
        }
        catch (err) {
            console.log("debug::mapTokenSymbol", err)
            return "Unknown Token"
        }
      },
    }
};
</script>