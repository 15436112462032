<template>
  <div>
    <HomeHero></HomeHero>
    <HomePopular></HomePopular>
    <HomeDefiTools></HomeDefiTools>
    <HomeCommunity></HomeCommunity>
    <HomeUniverse></HomeUniverse>
  </div>
</template>
<script>
  import HomeCommunity from "@/components/Home/Community";
  import HomeDefiTools from "@/components/Home/DefiTools";
  import HomeHero from "@/components/Home/Hero";
  import HomePopular from "@/components/Home/Popular";
  import HomeUniverse from "@/components/Home/Universe";

  export default {
    components: {
      HomeCommunity,
      HomeDefiTools,
      HomeHero,
      HomePopular,
      HomeUniverse
    },
  };
</script>