<template>
    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" v-model="open">
        <template #trigger>
            <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3">
                <div class="card-header-title">
                    <div class="container">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-12-mobile is-4-tablet">
                                <div class="level is-mobile">
                                    <div class="level-left">
                                        <div class="level-item mr-2">
                                            <figure class="image is-48x48 mr-2">
                                                <img class="is-rounded-rectangle" src="/assets/logos/pangolin.jpg" alt="Pangolin Farm" v-if="depositToken.platform == 'pangolin'" />
                                                <img class="is-rounded-rectangle" src="/assets/logos/gondola.png" alt="Gondola Finance" v-else-if="depositToken.platform =='gondola'"/>
                                                <img class="is-rounded-rectangle" src="/assets/logos/traderjoe.jpg" alt="Trader Joe" v-else-if="depositToken.platform =='traderjoe'"/>
                                                <img class="is-rounded-rectangle" src="/assets/logos/yak.png" alt="Yield Yak" v-else />
                                            </figure>
                                        </div>
                                        <div class="level-item mr-0">
                                            <div class="content">
                                                <p class="title">{{ depositToken.symbol }}</p>
                                                <p class="subtitle">{{ depositToken.name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-half-mobile">
                                <p class="heading">My TVL</p>
                                <p class="title is-size-4 has-text-grey" v-if="!account">
                                    n/a
                                </p>
                                <p class="title is-size--4 has-text-grey" v-else-if="!userTvl">
                                    tbd
                                </p>
                                <p class="title is-size-4" v-else-if="userTvl && userTvl.gt('0')">
                                    <balance
                                        :amount="userTvl"
                                        :decimals="depositToken.decimals || 18"
                                        :animationDuration="100"
                                        label="$"
                                        :decimalPlaces="0"
                                    />
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else>
                                    {{ 0 | usd_compact }}
                                </p>
                            </div>
                            <div class="column is-half-mobile">
                                <p class="heading">Rewards Per Day</p>
                                <p class="title is-size-4 has-text-grey" v-if="!rewardsPerDayUsd">
                                    tbd
                                </p>
                                <p class="title is-size-4" v-else-if="rewardsPerDayUsd && rewardsPerDayUsd > 0">
                                    {{ rewardsPerDayUsd | fromWei | usd_compact }}
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else>
                                    {{ 0 | usd_compact }}
                                </p>
                                <p class="subtitle is-size-5 has-text-grey" v-if="rewardsPerDay && rewardsPerDay.gt('0')">
                                    {{ rewardsPerDay | fromWei | number }} AVAX
                                </p>
                            </div>
                            <div class="column is-half-mobile">
                                <p class="heading">TVL</p>
                                <p class="title is-size-4 has-text-grey" v-if="!totalStaked">
                                    tbd
                                </p>
                                <div v-else>
                                    <p class="title is-size-4">
                                        {{ tvl | fromWei | usd_compact }}
                                    </p>
                                    <p class="subtitle is-size-5 has-text-grey">
                                        <balance
                                            :amount="totalStakedBalance.toString()"
                                            :symbol="depositToken.symbol"
                                            :decimals="depositToken.decimals || 18"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div class="column is-half-mobile is-1-tablet">
                                <p class="heading is-success" v-if="apr">APR</p>
                                <p class="heading" v-else>APR</p>
                                <p class="title is-size-4 is-success" v-if="apr">
                                    {{ apr | percentage }}
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else-if="apr == 0">
                                    {{ apr | percentage }}
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else>
                                    tbd
                                </p>
                                <p class="subtitle is-size-5 has-text-grey" v-if="underlyingApr">
                                    +{{ underlyingApr | percentage }} farm
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="card-header-icon">
                    <b-icon
                        :icon="open ? 'chevron-up' : 'chevron-down'">
                    </b-icon>
                </a>
            </div>
        </template>

        <div class="card-content is-hidden-touch" v-if="account">
            <div class="columns box is-dark" v-if="account">
                <div class="column is-4">
                    <b-tabs expanded>
                        <b-tab-item label="Deposit" headerClass="has-text-weight-semibold">
                            <div class="box is-dark mt-2">
                                <p class="heading">My Wallet</p>
                                <p class="title is-size-4 has-text-grey" v-if="!account">
                                    n/a
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else-if="!depositTokenBalance">
                                    tbd
                                </p>
                                <p class="title is-size-4" v-else-if="depositTokenBalance && depositTokenBalance.gt('0')">
                                    <balance
                                        :amount="depositTokenBalance.toString()"
                                        :symbol="depositToken.symbol"
                                        :decimals="depositToken.decimals || 18"
                                        :animationDuration="300"
                                    />
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else>
                                    0 {{ this.depositToken.symbol }}
                                </p>
                                <b-field class="outlined">
                                    <template #label>
                                        <p class="heading">
                                            Amount to Deposit
                                        </p>
                                    </template>
                                    <b-input 
                                        v-model="depositAmountDisplay"
                                        size="is-medium"
                                        :disabled="isDepositLoading || isDepositDisabled"
                                        expanded
                                    ></b-input>
                                    <b-button 
                                        type="is-dark" 
                                        size="is-medium" 
                                        :disable="isDepositDisabled"
                                        @click="setDepositAmount(100)"
                                    >Max</b-button>
                                </b-field>
                                <b-field>
                                    <b-button
                                    expanded
                                    type="is-primary"
                                    :loading="isDepositLoading"
                                    :disabled="isDepositDisabled || !isValidDepositAmount"
                                    @click="deposit"
                                    >
                                        Deposit
                                    </b-button>
                                </b-field>
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
                <div class="column is-4">
                    <b-tabs expanded>
                        <b-tab-item label="Withdraw" headerClass="has-text-weight-semibold">
                            <div class="box is-dark mt-2">
                                <p class="heading">My Staked Balance</p>
                                <p class="title is-size-4 has-text-grey" v-if="!account">
                                    n/a
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else-if="!userStaked">
                                    tbd
                                </p>
                                <p class="title is-size-4" v-else-if="userStaked && userStaked.gt('0')">
                                    <balance
                                        :amount="userStaked"
                                        :symbol="depositToken.symbol"
                                        :decimals="depositToken.decimals || 18"
                                        :animationDuration="300"
                                    />
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else>
                                    0 {{ this.depositToken.symbol }}
                                </p>
                                <b-field class="outlined">
                                    <template #label>
                                        <p class="heading">
                                            Amount to Withdraw
                                        </p>
                                    </template>
                                    <b-input 
                                        v-model="withdrawAmountDisplay" 
                                        size="is-medium" 
                                        :disabled="isWithdrawLoading || isWithdrawDisabled"
                                        expanded
                                    ></b-input>
                                    <b-button 
                                        type="is-dark" 
                                        size="is-medium" 
                                        :disable="isWithdrawDisabled"
                                        @click="setWithdrawAmount(100)"
                                    >Max</b-button>
                                </b-field>
                                <b-field>
                                    <b-button
                                        expanded
                                        type="is-primary"
                                        :loading="isWithdrawLoading"
                                        :disabled="isWithdrawDisabled || !isValidWithdrawAmount"
                                        @click="withdraw"
                                    >
                                        Withdraw
                                    </b-button>
                                </b-field>
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
                <div class="column is-4">
                    <b-tabs expanded>
                        <b-tab-item label="Claim" headerClass="has-text-weight-semibold">
                            <div class="box is-dark mt-2">
                                <p class="heading">My Pending Rewards</p>
                                <p class="title is-size-4 has-text-grey" v-if="!account">
                                    n/a
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else-if="!pendingRewards">
                                    tbd
                                </p>
                                <p class="title is-size-4 is-success" v-else-if="pendingRewards && pendingRewards.gt('0')">
                                    <balance
                                        :amount="pendingRewards.toString()"
                                        symbol="AVAX"
                                        :animationDuration="300"
                                    />
                                </p>
                                <p class="title is-size-4 has-text-grey" v-else>
                                    0 AVAX
                                </p>
                                <b-button
                                    expanded
                                    type="is-primary"
                                    :loading="isClaimLoading"
                                    :disabled="isClaimDisabled"
                                    @click="claim"
                                >
                                    Claim
                                </b-button>
                                <p class="content">
                                    Rewards are claimed automatically during deposit and withdraw.
                                </p>
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        </div>

        <div class="card-content is-hidden-desktop" v-if="account">
            <div class="box is-dark">
            <b-tabs expanded>
                <b-tab-item label="Deposit" headerClass="has-text-weight-semibold">
                    <div class="box is-dark mt-2">
                        <p class="heading">My Wallet</p>
                        <p class="title is-size-4 has-text-grey" v-if="!account">
                            n/a
                        </p>
                        <p class="title is-size-4 has-text-grey" v-else-if="!depositTokenBalance">
                            tbd
                        </p>
                        <p class="title is-size-4" v-else-if="depositTokenBalance && depositTokenBalance.gt('0')">
                            <balance
                                :amount="depositTokenBalance.toString()"
                                :symbol="depositToken.symbol"
                                :decimals="depositToken.decimals || 18"
                                :animationDuration="300"
                            />
                        </p>
                        <p class="title is-size-4 has-text-grey" v-else>
                            0 {{ this.depositToken.symbol }}
                        </p>
                        <b-field class="outlined">
                            <template #label>
                                <p class="heading">
                                    Amount to Deposit
                                </p>
                            </template>
                            <b-input 
                                v-model="depositAmountDisplay" 
                                size="is-medium"
                                :disabled="isDepositLoading || isDepositDisabled"
                                expanded
                            ></b-input>
                            <b-button 
                                type="is-dark" 
                                size="is-medium" 
                                :disable="isDepositDisabled"
                                @click="setDepositAmount(100)"
                            >Max</b-button>
                        </b-field>
                        <b-field>
                            <b-button
                            expanded
                            type="is-primary"
                            :loading="isDepositLoading"
                            :disabled="isDepositDisabled || !isValidDepositAmount"
                            @click="deposit"
                            >
                                Deposit
                            </b-button>
                        </b-field>
                    </div>
                </b-tab-item>
                <b-tab-item label="Withdraw" headerClass="has-text-weight-semibold">
                    <div class="box is-dark mt-2">
                        <p class="heading">My Staked Balance</p>
                        <p class="title is-size-4 has-text-grey" v-if="!account">
                            n/a
                        </p>
                        <p class="title is-size-4 has-text-grey" v-else-if="!userStaked">
                            tbd
                        </p>
                        <p class="title is-size-4" v-else-if="userStaked && userStaked.gt('0')">
                            <balance
                                :amount="userStaked"
                                :symbol="depositToken.symbol"
                                :decimals="depositToken.decimals || 18"
                                :animationDuration="300"
                            />
                        </p>
                        <p class="title is-size-4 has-text-grey" v-else>
                            0 {{ this.depositToken.symbol }}
                        </p>
                        <b-field>
                            <template #label>
                                <p class="heading">
                                    Amount to Withdraw
                                </p>
                            </template>
                            <b-input 
                                v-model="withdrawAmountDisplay" 
                                size="is-medium"
                                :disabled="isWithdrawLoading || isWithdrawDisabled"
                                expanded
                            ></b-input>
                            <b-button 
                                type="is-dark" 
                                size="is-medium" 
                                :disable="isWithdrawDisabled"
                                @click="setWithdrawAmount(100)"
                            >Max</b-button>
                        </b-field>
                        <b-field>
                            <b-button
                                expanded
                                type="is-primary"
                                :loading="isWithdrawLoading"
                                :disabled="isWithdrawDisabled || !isValidWithdrawAmount"
                                @click="withdraw"
                            >
                                Withdraw
                            </b-button>
                        </b-field>
                    </div>
                </b-tab-item>
                <b-tab-item label="Claim" headerClass="has-text-weight-semibold">
                    <div class="box is-dark mt-2">
                        <p class="heading">My Pending Rewards</p>
                        <p class="title is-size-4 has-text-grey" v-if="!account">
                            n/a
                        </p>
                        <p class="title is-size-4 has-text-grey" v-else-if="!pendingRewards">
                            tbd
                        </p>
                        <p class="title is-size-4 is-success" v-else-if="pendingRewards && pendingRewards.gt('0')">
                            <balance
                                :amount="pendingRewards.toString()"
                                symbol="AVAX"
                                :animationDuration="300"
                            />
                        </p>
                        <p class="title is-size-4 has-text-grey" v-else>
                            0 AVAX
                        </p>
                        <b-button
                            expanded
                            type="is-primary"
                            :loading="isClaimLoading"
                            :disabled="isClaimDisabled"
                            @click="claim"
                        >
                            Claim
                        </b-button>
                        <p class="content">
                            Rewards are claimed automatically during deposit and withdraw.
                        </p>
                    </div>
                </b-tab-item>
            </b-tabs>
            </div>
        </div>

        <div class="card-content" v-else>
            <p class="content">
                Connect your wallet to manage this pool
            </p>
        </div>
    </b-collapse>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { utils, BigNumber } from "ethers";

  import Balance from "@/components/Balance";

  export default {
    components: {
      Balance,
    },
    props: [
        'depositToken',
        'allocPoint',
        'pid',
        'rewardsPerSecond',
        'totalAllocPoint',
        'totalStaked',
        'userStaked',
        'pendingRewards'
    ],
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            aprForFarm: 'apy/aprForFarm',
            tokenBalances: 'balances/balanceOfToken',
            farmForAddress: 'farms/dataForFarm',
            farmForDepositToken: 'farms/dataForDepositToken',
            priceForSymbol: 'prices/priceForSymbol',
        }),
        apr: function () {
            try {
                let avaxPerYear = this.rewardsPerDay.mul("365")
                let avaxPrice = parseInt(this.priceForSymbol("AVAX") * 10000)
                let usdPerYear = avaxPerYear.mul(avaxPrice).div("10000")
                let apr = usdPerYear.mul(utils.parseUnits("1", 20)).div(this.tvl)
                return parseInt(utils.formatUnits(apr))
            }
            catch (err) { console.log(err) }

        },
        underlyingApr: function () {
            try {
                let farm = this.farmForAddress(this.depositToken.address);
                return this.aprForFarm(farm.address);
            } catch {}
        },
        userTvl: function () {
            try {
                return this.tvl.mul(this.userStaked).div(this.totalStaked)
            }
            catch {}
            return BigNumber.from("0");
        },
        totalStakedBalance: function () {
            return this.totalStaked || BigNumber.from("0");
        },
        tvl: function () {
            try {
                let farm = this.farmForAddress(this.depositToken.address);
                return this.totalStaked.mul(parseInt(farm.tvl)).div(parseInt(farm.totalSupply))
            } catch {}

            try {
                let farm = this.farmForDepositToken(this.depositToken.address)
                return this.totalStaked.mul(parseInt(farm.tvl)).div(parseInt(farm.totalDeposits))
            } catch {}

            try {
                let price = this.priceForSymbol(this.depositToken.symbol)
                return this.totalStaked.mul(parseInt(price))
            } catch {}

            return BigNumber.from("0");
        },
        depositAmountDisplay: {
            get () {
                if (this.depositAmount) {
                    return utils.formatUnits(this.depositAmount);
                }
                return "0";
            },
            set (value) {
                this.depositAmount = utils.parseUnits(value);
            }
        },
        withdrawAmountDisplay: {
            get () {
                if (this.withdrawAmount) {
                    return utils.formatUnits(this.withdrawAmount);
                }
                return "0";
            },
            set (value) {
                try {
                    this.withdrawAmount = utils.parseUnits(value);
                }
                catch {
                    this.withdrawAmount = utils.parseUnits("0");
                }
            }
        },
        rewardsPerDay: function () {
            return this.rewardsPerSecond.mul("86400").mul(this.allocPoint).div(this.totalAllocPoint);
        },
        rewardsPerDayUsd: function () {
            const price = this.priceForSymbol("AVAX")
            const rewardsPerDay = parseInt(this.rewardsPerDay)
            if (price && rewardsPerDay) return price * rewardsPerDay
        },
        depositTokenBalance: function () {
            return this.tokenBalances(this.depositToken.address);
        },
        isClaimDisabled: function () {
            return !this.account || !this.pendingRewards || this.pendingRewards.eq("0");
        },
        isDepositDisabled: function () {
            return !this.account || !this.depositTokenBalance || this.depositTokenBalance.eq("0");
        },
        isWithdrawDisabled: function () {
            return !this.account || !this.userStaked || this.userStaked.eq("0");
        },
        isValidWithdrawAmount: function () {
            return this.account 
                && this.withdrawAmount 
                && this.withdrawAmount.gt("0") 
                && this.withdrawAmount.lte(this.userStaked);
        },
        isValidDepositAmount: function () {
            return this.account 
                && this.depositAmount 
                && this.depositAmount.gt("0") 
                && this.depositAmount.lte(this.depositTokenBalance);
        }
    },
    data() {
      return {
        depositAmount: null,
        withdrawAmount: null,
        isClaimLoading: false,
        isDepositLoading: false,
        isWithdrawLoading: false,
        open: false
      };
    },
    methods: {
        setDepositAmount(percentOfBalance) {
            this.depositAmount = this.depositTokenBalance.mul(percentOfBalance).div("100");
        },
        setWithdrawAmount(percentOfBalance) {
            this.withdrawAmount = this.userStaked.mul(percentOfBalance).div("100");
        },
        async claim() {
            this.isClaimLoading = true;
            let claimResult = false;
            try {
                const payload = {
                    pid: this.pid,
                    depositTokenAddress: this.depositToken.address,
                    amount: BigNumber.from("0")
                }
                claimResult = await this.$store.dispatch('depositToMasterYak', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (claimResult) {
                // update balances
                await this.$store.dispatch('fetchStakingData');
                await this.$store.dispatch('balances/loadData', [this.depositToken.address])
            }
            this.isClaimLoading = false;
        },
        async deposit() {
            this.isDepositLoading = true;
            let depositResult = false;
            try {
                const payload = {
                    pid: this.pid,
                    depositTokenAddress: this.depositToken.address,
                    amount: this.depositAmount
                }
                depositResult = await this.$store.dispatch('depositToMasterYak', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (depositResult) {
                // update balances
                await this.$store.dispatch('fetchStakingData');
                await this.$store.dispatch('balances/loadData', [this.depositToken.address])
                this.depositAmount = null;
            }
            this.isDepositLoading = false;
        },
        async withdraw() {
            this.isWithdrawLoading = true;
            let withdrawResult = false;
            try {
                const payload = {
                    pid: this.pid,
                    amount: this.withdrawAmount
                }
                withdrawResult = await this.$store.dispatch('withdrawFromMasterYak', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (withdrawResult) {
                // update balances
                await this.$store.dispatch('fetchStakingData');
                await this.$store.dispatch('balances/loadData', [this.depositToken.address])
                this.withdrawAmount = null;
            }
            this.isWithdrawLoading = false;
        }
    },
  };
</script>
