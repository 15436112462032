<template>
    <b-button
        :type="type"
        :size="size"
        :disabled="disabled"
        @click="callback"
    >Max</b-button>
</template>

<script>
  export default {
    props: [
        'size',
        'type',
        'disabled',
        'callback'
    ],
  };
</script>
