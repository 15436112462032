<template>
<div>
    <div v-if="account && !shouldSwitchNetworkDisplay">
        <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{ active }">
                <b-button
                    :loading="isLoading"
                >
                    <div class="level is-mobile">
                        <div class="level-item">
                            <figure class="image is-24x24" v-if="!isLoading && walletName && walletName.toLowerCase() !== 'wallet'">
                                <img width="24" height="24" :src="ASSETS_URL + '/wallets/' + walletName.toLowerCase() + '.png'" :alt="walletName" />
                            </figure>
                        </div>
                        <div class="level-item" v-if="!hideLabel">
                            <p>
                                {{ account | shortEth }}
                            </p>
                        </div>
                        <div class="level-item">
                            <b-icon icon='chevron-up' size="is-normal" class="px-4" v-if="active" />
                            <b-icon icon='chevron-down' size="is-normal" class="px-4" v-else />
                        </div>
                    </div>
                </b-button>
            </template>

            <b-dropdown-item has-link aria-role="menuitem">
                <a href="https://vote.yieldyak.com" target="_blank">
                    <div class="level is-mobile">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="media">
                                    <div class="media-left">
                                        <figure class="image is-32x32">
                                            <img width="32" height="32" class="is-rounded" :src="ASSETS_URL + '/logos/snapshot.jpg'" alt="Snapshot" />
                                        </figure>
                                    </div>
                                    <div class="media-content">
                                        <p class="title is-size-5">Snapshot</p>
                                        <small>Yield Yak Governance&nbsp;&nearr;</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item has-link aria-role="menuitem">
                <a :href="explorerUrl" target="_blank">
                    <div class="level is-mobile">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="media">
                                    <div class="media-left">
                                        <figure class="image is-32x32">
                                            <img width="32" height="32" class="is-rounded" :src="explorerSrc" :alt="explorerName" />
                                        </figure>
                                    </div>
                                    <div class="media-content">
                                        <p class="title is-size-5">Explorer</p>
                                        <small>My account on {{ explorerName }}&nbsp;&nearr;</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </b-dropdown-item>
            <b-dropdown-item has-link aria-role="menuitem">
                <a :href="account | portfolioTrackerAccount" target="_blank">
                    <div class="level is-mobile">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="media">
                                    <div class="media-left">
                                        <figure class="image is-32x32">
                                            <img width="32" height="32" class="is-rounded" :src="ASSETS_URL + '/logos/debank.png'" alt="DeBank" />
                                        </figure>
                                    </div>
                                    <div class="media-content">
                                        <p class="title is-size-5">Portfolio Tracker</p>
                                        <small>My account on DeBank&nbsp;&nearr;</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item aria-role="listitem" @click="disconnect">
                <p class="title is-size-5">
                    Disconnect my wallet
                </p>
            </b-dropdown-item>
        </b-dropdown>
    </div>
    <div v-else-if="account && shouldSwitchNetworkDisplay">
        <b-button
            v-if="shouldSwitchNetworkDisplay"
            class="ml-2"
            type="is-warning"
            :loading="isLoading"
            @click="switchNetwork"
        >
            <div class="level is-mobile">
                <div class="level-item">
                    <figure class="image is-24x24" v-if="!isLoading && walletName && walletName.toLowerCase() !== 'wallet'">
                        <img width="24" height="24" :src="ASSETS_URL + '/wallets/' + walletName.toLowerCase() + '.png'" :alt="walletName" />
                    </figure>
                </div>
                <div class="level-item" v-if="!hideLabel">
                    <p>Switch Network</p>
                </div>
            </div>
        </b-button>
    </div>
    <div v-else>
        <ConnectButton :hideLabel="hideLabel" :expanded="false" />
    </div>
</div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ASSETS_URL } from '@/settings';
  import { connectToChain, getExplorerDetails, getWalletName } from '@/services/NetworkHelpers'

  import ConnectButton from "@/components/Buttons/Connect";

  export default {
    components: { 
        ConnectButton
    },
    props: [
        'hideLabel'
    ],
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            selectedChainId: 'accountModule/selectedChainId',
            shouldSwitchNetworkDisplay: 'accountModule/shouldSwitchNetworkDisplay',
            initialLoading: 'accountModule/initialLoading',
        }),
        walletName: function () {
            return getWalletName();
        },
        explorerUrl: function() {
            const baseUrl = getExplorerDetails(this.selectedChainId).url
            return `${baseUrl}address/${this.account}`
        },
        explorerName: function() {
            return getExplorerDetails(this.selectedChainId).name
        },
        explorerSrc: function() {
            return `${ASSETS_URL}/logos/${this.explorerName.toLowerCase()}.png`
        }
    },
    data() {
      return {
        isLoading: false,
        ASSETS_URL
      };
    },
    methods: {
        async switchNetwork() {
            this.isLoading = true;
            await connectToChain(this.selectedChainId, this.account);
            this.isLoading = false;
        },
        async disconnect() {
            this.isLoading = true;
            await this.$store.dispatch('accountModule/disconnect');
            this.isLoading = false;
        },
    },
  };
</script>
