<template>
    <div>
        <div class="level is-mobile" v-if="underlying">
            <div :class="align == 'is-right' ? 'level-right' : 'level-left'">
                <div class="level-item">
                    <Tokens
                        :chainId="chainId"
                        :tokens="underlying" 
                        alignment="is-left"
                    />
                </div>
                <div class="level-item">
                    <span v-if="label">{{ label }}</span>
                    <span v-if="animationDuration && amount">
                        <AnimatedNumber
                            :value="amount.toString()"
                            :duration="animationDuration"
                            :formatValue="toFormatted"
                        />
                    </span>
                    <span v-else>
                        {{ toFormatted(amount) }}
                    </span>
                    <span v-if="symbol" class="has-text-weight-light">&nbsp;{{ symbol }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <span v-if="label">{{ label }}</span>
            <span v-if="animationDuration && amount">
                <AnimatedNumber
                    :value="amount.toString()"
                    :duration="animationDuration"
                    :formatValue="toFormatted"
                />
            </span>
            <span v-else>
                {{ toFormatted(amount) }}
            </span>
            <span v-if="symbol">&nbsp;{{ symbol }}</span>
        </div>
    </div>
</template>

<script>
  import AnimatedNumber from "animated-number-vue";
  import Tokens from "@/components/Tokens";

  export default {
    components: {
      AnimatedNumber, Tokens
    },
    props: [
        'align',
        'amount',
        'label',
        'symbol',
        'chainId',
        'underlying',
        'animationDuration',
        'decimalPlaces',
        'decimals',
        'isCompact'
    ],
    methods: {
        toFormatted(value) {
            if (!value || value == "0") return "0";

            const decimalsToUse = this.decimals === undefined ? 18 : this.decimals;
            let decimalValue = parseFloat(value) / 10 ** decimalsToUse;
            return this.isCompact ? this.$options.filters.compact(decimalValue) :  this.$options.filters.number(decimalValue);
        },
    }
  };
</script>
