<template>
    <b-button
        tag="a"
        :expanded="expanded"
        :href="href"
        target="_blank"
    >
        <div class="level is-mobile">
            <div class="level-item">
                <figure class="image is-24x24 mx-0">
                    <img class="is-rounded" width="24" height="24" :src="ASSETS_URL + '/logos/medium.png'" alt="Medium" />
                </figure>
            </div>
            <div class="level-item">
                <span>
                    Read more on Medium
                </span>
            </div>
        </div>
    </b-button>
</template>

<script>
  import { ASSETS_URL } from '@/settings';

  export default {
    props: [
        'href',
        'expanded'
    ],
    data() {
      return {
        ASSETS_URL
      };
    },
  };
</script>
