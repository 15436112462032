<template>
    <div class="box">
        <!-- <div class="columns is-multiline"> -->
            <!-- <div class="column is-12"> -->
                <!-- <p class="title">
                    Convert
                </p> -->
                <b-field>
                    <template #label>
                        <div class="columns is-mobile">
                            <div class="column">
                                <p class="heading">Send</p>
                            </div>
                            <div class="column">
                                <p v-if="account && tokenBalance" class="has-text-right">
                                    <balance
                                        :amount="tokenBalance"
                                        :symbol="symbol"
                                        :useText="true"
                                        :animationDuration="100"
                                    />
                                </p>
                                <p v-else-if="account" class="has-text-right">
                                    Loading...
                                </p>
                            </div>
                        </div>
                    </template>
                    <p class="control">
                        <b-button
                            type="is-dark" 
                            :disabled="depositing"
                        >
                            <Tokens 
                                :tokens="[tokenAddress]" 
                                size="is-small" 
                                alignment="is-left"
                            />
                        </b-button>
                    </p>
                    <b-input 
                        v-model="depositAmount"
                        type="is-dark"
                        size="is-medium"
                        :disabled="depositing"
                        :placeholder="`0 ${symbol}`"
                        expanded
                    ></b-input>
                    <p class="control">
                        <b-button type="is-dark" size="is-medium" @click="setMaxDeposit" :disabled="isMaxDisabled" expanded>Max</b-button>
                    </p>
                </b-field>
                <b-field>
                    <template #label>
                        <div class="columns is-mobile">
                            <div class="column">
                                <p class="heading">Receive</p>
                            </div>
                            <div class="column">
                                <p v-if="account && yyTokenBalance" class="has-text-right">
                                    <balance
                                        :amount="yyTokenBalance" 
                                        :symbol="`yy${symbol}`"
                                        :useText="true"
                                        :animationDuration="100"
                                    />
                                </p>
                                <p v-else-if="account" class="has-text-right">
                                    Loading...
                                </p>
                            </div>
                        </div>
                    </template>
                    <p class="control">
                        <b-button
                            type="is-dark"
                        >
                            <Tokens 
                                :tokens="[yyTokenAddress]" 
                                size="is-small" 
                                alignment="is-left"
                            />
                        </b-button>
                    </p>
                    <b-input 
                        readonly 
                        v-model="amountToReceive" 
                        size="is-medium" 
                        type="is-dark" 
                        :placeholder="`0 yy${symbol}`"
                        expanded
                    ></b-input>
                </b-field>
                <b-field>
                    <b-button
                        expanded
                        size="is-large"
                        type="is-warning"
                        :loading="depositing"
                        :disabled="isDepositDisabled"
                        @click="deposit"
                    >
                        Convert
                    </b-button>
                </b-field>
                <p class="content">
                    <b-icon
                        icon="alert"
                        type="is-warning"
                        size="is-small">
                    </b-icon>
                    Before you convert. {{ symbol }} conversions are irreversible. You will receive yy{{ symbol }} at a 1:1 ratio.
                </p>
            <!-- </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { utils } from 'ethers';

  import Balance from "@/components/Balance";
  import Tokens from "@/components/Tokens";

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
        Balance, Tokens
    },
    props: [
        'symbol',
        'tokenAddress',
        'yyTokenAddress'
    ],
    data() {
        return {
            depositAmount: null,
            amountToReceive: null,
            depositing: false,
            polling: null
        }
    },
    watch: {
        depositAmount: async function() {
            this.handlePolling()
        }
    },
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            chainId: 'accountModule/selectedChainId',
            tokenBalances: 'balances/data',
        }),
        tokenBalance: function () {
            try {
                return this.tokenBalances[this.tokenAddress];
            }
            catch {}
        },
        yyTokenBalance: function () {
            try {
                return this.tokenBalances[this.yyTokenAddress];
            }
            catch {}
        },
        isDepositDisabled: function () {
            return !this.account 
                || !this.depositAmount 
                || utils.parseUnits(this.depositAmount).eq("0")
                || utils.parseUnits(this.depositAmount).gt(this.tokenBalance)
        },
        isMaxDisabled: function () {
            return this.depositing || !this.account
        },
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
        async handlePolling() {
            this.updateAmountToReceive()
            this.polling = setTimeout(this.handlePolling, POLL_RATE);
        },
        updateAmountToReceive () {
            this.amountToReceive = this.depositAmount
        },
        setMaxDeposit() {
            this.depositAmount = utils.formatUnits(this.tokenBalance)
        },
        async deposit() {
            this.depositing = true;
            let depositResult = false;
            try {
                let payload = {
                    address: this.yyTokenAddress,
                    depositTokenAddress: this.tokenAddress,
                    amount: utils.parseUnits(this.depositAmount),
                }
                depositResult = await this.$store.dispatch('yyPtp/deposit', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (depositResult) {
                this.depositAmount = null;
                let payload = {
                    symbol: this.symbol
                }
                await Promise.all([
                    this.$store.dispatch('yyPtp/loadData', payload),
                    // this.$store.dispatch('yyPtp/loadStakingData', payload)
                ])
            }

            this.depositing = false;
        },
    }
  };
</script>
