<template>
    <div class="content mb-4">
        <p class="heading">About {{ platformName }}</p>
        <p>
            {{ this.platformDescription }}
        </p>
        <div class="buttons">
            <b-button 
                v-if="farmUrl" 
                icon-left="web" 
                tag="a" :href="farmUrl" target="_blank"
            >View on Web</b-button>
            <b-button 
                v-if="twitterUrl" 
                icon-left="twitter" 
                tag="a" :href="twitterUrl" target="_blank"
            >Twitter</b-button>
            <b-button 
                icon-right="arrow-right" 
                tag="router-link" :to="{name: 'Farms', query: { tab: 'allFarms', platform }}"
            >All {{ platformName }} Pools</b-button>
        </div>
    </div>
</template>

<script>
  import { mapPlatformName, mapPlatformUrl, mapPlatformTwitter, mapPlatformDescription } from '@/services/utils'

  export default {
    props: [
        'platform'
    ],
    computed: {
        platformName: function () {
            return  mapPlatformName(this.platform)
        },
        platformDescription: function () {
            return mapPlatformDescription(this.platform)
        },
        farmUrl: function () {
            return mapPlatformUrl(this.platform)
        },
        twitterUrl: function () {
            return mapPlatformTwitter(this.platform)
        }
    }
  };
</script>
