<template>
    <b-button
        tag="a"
        href="https://vote.yieldyak.com"
        target="_blank"
        v-if="account && votingPower && votingPower.gt('0')"
    >
        <div class="level is-mobile">
            <div class="level-item">
                <figure class="image is-24x24">
                    <img class="is-rounded" width="24" height="24" :src="ASSETS_URL + '/logos/snapshot.jpg'" alt="Snapshot" />
                </figure>
            </div>
            <div class="level-item is-hidden-mobile">
                <p>
                    {{ votingPower | fromWei | number }} vYAK
                </p>
            </div>
        </div>
    </b-button>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ASSETS_URL } from '@/settings';

  export default {
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            votingPower: 'voting/votingPower'
        }),
    },
    data() {
      return {
        ASSETS_URL
      };
    },
  };
</script>
