import { ethers, utils, BigNumber } from 'ethers';
import YyAvaxABI from '@/abis/yyAvax';
import WpABI from '@/abis/WithdrawalPool'
import Vue from 'vue';
import DeployedAddresses from '@/contracts/Deployed';
import Chains from '@/contracts/Chains';

const BASE_URL = process.env.VUE_APP_FARM_API

const state = () => ({
    data: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  data: state => {
    return state.data
  },
  poolBalances: state => {
    try {
      return state.data.poolBalances
    }
    catch {}
  },
  apr: state => {
    try {
      return state.data.apr
    }
    catch {}
  },
  lastReinvest: state => {
    try {
      return state.data.lastReinvest
    }
    catch {}
  },
  pricePerShare: state => {
    try {
      return state.data.pricePerShare
    }
    catch {}
  },
  totalSupply: state => {
    try {
      return state.data.totalSupply
    }
    catch {}
  },
  tvl: (state, getters, rootState, rootGetters) => {
    try {
      const avaxPrice = parseInt(rootGetters['prices/priceForSymbol']("AVAX") * 10000)
      const pricePerShare = state.data.pricePerShare
      const totalSupply = state.data.totalSupply
      return pricePerShare.mul(totalSupply).mul(avaxPrice).div("10000")
        .div(ethers.utils.parseUnits("1")).div(ethers.utils.parseUnits("1"))
    }
    catch (err) {
      console.log(err)
    }
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  },
  isSupported: (state, getters, rootState, rootGetters) => {
    let chainId = rootGetters['accountModule/selectedChainId']
    if (chainId) {
      try {
          return Chains[chainId].supportedFeatures.bridge
      }
      catch {
          return false
      }
    }
    return false
  },
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("bonds / setValue err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async loadData({ state, commit, rootGetters }, payload) {
    // console.log("debug:loadData", payload)
    if (state.isLoading) {
      console.log('vuex/yyAvax/loadData -- already loading')
      return;
    }
    commit('setIsLoading', true)
    const chainId = rootGetters['accountModule/selectedChainId']
    const provider = rootGetters['accountModule/provider']
    if (chainId && provider) {
      try {
        const {
          address, portal, id, withdrawalPool
        } = DeployedAddresses[chainId].liquidStaking;
        
        const yyAvaxContract = new ethers.Contract(address, YyAvaxABI, provider);
        // const portalContract = new ethers.Contract(portal, PortalABI, provider);
        const wpContract = new ethers.Contract(withdrawalPool, WpABI, provider);

        let pricePerShare, totalSupply, balance0, balance1, result;
        [
          pricePerShare, totalSupply, balance0, balance1, result
        ] = await Promise.all([
          yyAvaxContract.pricePerShare(),
          yyAvaxContract.totalSupply(),
          wpContract.getTokenBalance(0),
          wpContract.getTokenBalance(1),
          fetch(BASE_URL + '/yyavax')
        ])

        let data = await result.json()

        commit('setValue', { key: "pricePerShare", value: pricePerShare })
        commit('setValue', { key: "totalSupply", value: totalSupply })
        commit('setValue', { key: "poolBalances", value: [balance0, balance1]});
        commit('setValue', { key: "apr", value: data.yyAVAX.apr })
        commit('setValue', { key: "lastReinvest", value: data.yyAVAX.lastReinvest.timestamp })
      }
      catch (error) {
        console.log("yyAvax/loadData/error", error)
      }
      commit('setInitialLoadComplete', true)
    }
    commit('setIsLoading', false)
  },
  clearData({ commit }) {
        commit('setInitialLoadComplete', false)
        commit('set', {})
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}