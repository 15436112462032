import Onboard from "bnc-onboard";
import { getChainName, getChainRPC } from "@/services/NetworkHelpers"

import {
  SUPPORTED_CHAIN_ID,
  BLOCKNATIVE_DAPP_ID
} from "@/settings";

export function initOnboard(subscriptions, chainId = SUPPORTED_CHAIN_ID) {
  // console.log("initOnboard", chainId)
  
  const networkId = parseInt(chainId)
  const networkName = getChainName(chainId)
  const RPC_URL = getChainRPC(chainId)

  return Onboard({
    dappId: BLOCKNATIVE_DAPP_ID,
    hideBranding: true,
    networkId,
    networkName,
    darkMode: true,
    subscriptions,
    walletSelect: {
      wallets: [
        { walletName: "detectedwallet", preferred: true },
        { walletName: "metamask", preferred: true },
        { walletName: "coinbase", preferred: true },
        { walletName: "trust", preferred: true, rpcUrl: RPC_URL },
        { walletName: "tokenpocket", rpcUrl: RPC_URL },
        { walletName: 'walletConnect', rpc: { [chainId]: RPC_URL }},
        { walletName: "gnosis" },
        { walletName: "xdefi" },
        { walletName: "huobiwallet", rpcUrl: RPC_URL },
      ],
    },
    walletCheck: [
      {checkName: "derivationPath"},
      {checkName: "connect"},
      {checkName: "accounts"},
      {checkName: "network"},
    ],
  });
}
