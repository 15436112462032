import { BigNumber, utils } from 'ethers';
import Platforms from '@/contracts/Platforms';
import Chains from '@/contracts/Chains';
import { ASSETS_URL } from '@/settings';

function srcForToken(chainId, address) {
    return `${ASSETS_URL}/tokens/${chainId}/${address}.png`
}

function mapNetworkName(chainId) {
    try {
        return Chains[chainId].name
    }
    catch {
        return "Unknown Network"
    }
}

function mapPlatformName(platform) {
    try {
        return Platforms[platform].alt
    }
    catch {
        return platform
    }
}

function mapPlatformSource(platformId) {
    if (platformId == "All Platforms") platformId = "yak"
    return `${ASSETS_URL}/platforms/${platformId}/square.jpeg`
}

function mapPlatformUrl(platform) {
    try {
        return Platforms[platform].website
    }
    catch {}
    return null
}

function mapPlatformTwitter(platform) {
    try {
        let id = Platforms[platform].twitter
        if (id) {
            return `https://x.com/${id}`
        }
    }
    catch {}

    return null
}

function mapPlatformDescription(platform) {
    try {
        let description = Platforms[platform].longDescription
        if (description) {
            return description
        }
    }
    catch {}

    return mapPlatformName(platform)
}

function getMoreDepositUrl(chainId, platform, symbol, depositTokenAddress, underlying) {
    let url;

    try {
        let WAVAX = utils.getAddress("0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
        let token0 = utils.getAddress(underlying[0]) == WAVAX ? "AVAX" : utils.getAddress(underlying[0]);
        let token1 = utils.getAddress(underlying[1]) == WAVAX ? "AVAX" : utils.getAddress(underlying[1]);
        let depositTokenSymbol = symbol;
        const chainName = chainId === "43114" ? "avalanche" : "arbitrum"
        if (depositTokenSymbol == "PGL") url = `https://legacy.pangolin.exchange/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "JLP") url = `https://traderjoexyz.com/${chainName}/pool/v1/${token0}/${token1}`;
        else if (depositTokenSymbol == "APT") url = `https://traderjoexyz.com/${chainName}/vault/${depositTokenAddress}`;
        else if (depositTokenSymbol == "BPT") url = `https://app.balancer.fi/#/${chainName}`;
        else if (depositTokenSymbol == "Lydia-LP") url = `https://exchange.lydia.finance/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "HAKU-LP") url = `https://exchange.hakuswap.com/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "CRL") url = `https://app.canary.exchange/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "BGL") url = `https://app.baguette.exchange/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "Olive-LP") url = `https://swap.olive.cash/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "YSL") url = `https://exchange.yetiswap.app/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "ELP") url = `https://avax.elk.finance/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "ZERO-LP") url = `https://app.0.exchange/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "PLP") url = `https://app.pandaswap.exchange/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol == "xPARTY") url = `https://app.partyswap.io/#/add/${token0}/${token1}`;
        else if (depositTokenSymbol.startsWith("gondola")) url = `https://app.gondola.finance/pools`;
        else if (depositTokenSymbol == "av3CRV") url = `https://avax.curve.fi/aave/deposit`;
        else if (depositTokenSymbol == "MONEY3CRV-f") url = `https://avax.curve.fi/factory/39/deposit`;
        else if (depositTokenSymbol == "YUSDCRV-f") url = `https://avax.curve.fi/factory/69/deposit`;
        else if (depositTokenSymbol == "AC4D") url = `https://app.axial.exchange/#/pools/cs4d/deposit`;
        else if (depositTokenSymbol == "AS4D") url = `https://app.axial.exchange/#/pools/as4d/deposit`;
        else if (depositTokenSymbol == "AM3D") url = `https://app.axial.exchange/#/pools/am3d/deposit`;
        else if (depositTokenSymbol == "AA3D") url = `https://app.axial.exchange/#/pools/aa3d/deposit`;
        else if (depositTokenSymbol == "USDC-AM3D") url = `https://app.axial.exchange/#/pools/usdc/deposit`;
        else if (depositTokenSymbol == "nUSD-LP") url = `https://synapseprotocol.com/pools/avalanchenusd#addLiquidity`;
        else if (depositTokenSymbol == "svAMM") url = `https://www.glacier.exchange/liquidity?pair=${depositTokenAddress}`
        else if (depositTokenSymbol == "vAMM") url = `https://www.glacier.exchange/liquidity?pair=${depositTokenAddress}`
    } 
    catch (err) {
        if (symbol == "aHYPE") url = `https://kassandra.finance/explore/ahype`;
        else if (symbol == "K3C") url = `https://kassandra.finance/explore/k3c`;
        else if (symbol == "S*USDC") url = `https://stargate.finance/pool/USDC-AVAX/add`;
        else if (symbol == "S*USDT") url = `https://stargate.finance/pool/USDT-AVAX/add`;
        else if (symbol == "fsGLP") url = `https://app.gmx.io/#/buy_glp/?ref=yak`;
        else if (symbol == "BLP") url = `https://www.baymax.fi/#/buy_blp/?ref=yak`;
        else if (symbol == "yyJOE") url = `https://yieldyak.com/yy/joe`;
        else if (symbol == "yyPTP") url = `https://yieldyak.com/yy/ptp`;
        else if (symbol == "jrLLP") url = `https://app.level.finance/liquidity`;
        else if (symbol == "mzeLLP") url = `https://app.level.finance/liquidity`;
        else if (symbol == "srLLP") url = `https://app.level.finance/liquidity`;
    }
    return url;
}

function getRemoveLiquidityUrl(platform, symbol, underlying) {
    let url;

    try {
        let WAVAX = utils.getAddress("0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7");
        let token0 = utils.getAddress(underlying[0]) == WAVAX ? "AVAX" : utils.getAddress(underlying[0]);
        let token1 = utils.getAddress(underlying[1]) == WAVAX ? "AVAX" : utils.getAddress(underlying[1]);
        let depositTokenSymbol = symbol;
        const chainName = chainId === "43114" ? "avalanche" : "arbitrum"
        if (depositTokenSymbol == "PGL") url = `https://legacy.pangolin.exchange/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "JLP") url = `https://traderjoexyz.com/${chainName}/pool/v1/${token0}/${token1}`;
        else if (depositTokenSymbol == "APT") url = `https://traderjoexyz.com/${chainName}/vault/${depositTokenAddress}`;
        else if (depositTokenSymbol == "Lydia-LP") url = `https://exchange.lydia.finance/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "HAKU-LP") url = `https://exchange.hakuswap.com/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "CRL") url = `https://app.canary.exchange/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "BGL") url = `https://app.baguette.exchange/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "Olive-LP") url = `https://swap.olive.cash/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "YSL") url = `https://exchange.yetiswap.app/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "ELP") url = `https://avax.elk.finance/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "ZERO-LP") url = `https://app.0.exchange/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "PLP") url = `https://app.pandaswap.exchange/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol == "xPARTY") url = `https://app.partyswap.io/#/remove/${token0}/${token1}`;
        else if (depositTokenSymbol.startsWith("gondola")) url = `https://app.gondola.finance/pools`;
        else if (depositTokenSymbol == "av3CRV") url = `https://avax.curve.fi/aave/withdraw`;
        else if (depositTokenSymbol == "MONEY3CRV-f") url = `https://avax.curve.fi/factory/39/withdraw`;
        else if (depositTokenSymbol == "YUSDCRV-f") url = `https://avax.curve.fi/factory/69/withdraw`;
        else if (depositTokenSymbol == "AC4D") url = `https://app.axial.exchange/#/pools/cs4d/withdraw`;
        else if (depositTokenSymbol == "AS4D") url = `https://app.axial.exchange/#/pools/as4d/withdraw`;
        else if (depositTokenSymbol == "AM3D") url = `https://app.axial.exchange/#/pools/am3d/withdraw`;
        else if (depositTokenSymbol == "AA3D") url = `https://app.axial.exchange/#/pools/aa3d/withdraw`;
        else if (depositTokenSymbol == "USDC-AM3D") url = `https://app.axial.exchange/#/pools/usdc/withdraw`;
        else if (depositTokenSymbol == "nUSD-LP") url = `https://synapseprotocol.com/pools/avalanchenusd#removeLiquidity`;
        else if (depositTokenSymbol == "sAMM") url = `https://www.glacier.exchange/liquidity?pair=${depositTokenAddress}`
        else if (depositTokenSymbol == "vAMM") url = `https://www.glacier.exchange/liquidity?pair=${depositTokenAddress}`
    } 
    catch (err) {
        if (symbol == "aHYPE") url = `https://kassandra.finance/explore/ahype`;
        else if (symbol == "K3C") url = `https://kassandra.finance/explore/k3c`;
        else if (symbol == "S*USDC") url = `https://stargate.finance/pool/USDC-AVAX/remove`;
        else if (symbol == "S*USDT") url = `https://stargate.finance/pool/USDT-AVAX/remove`;
        else if (symbol == "fsGLP") url = `https://app.gmx.io/#/buy_glp/?ref=yak`;
        else if (symbol == "BLP") url = `https://www.baymax.fi/#/buy_blp/?ref=yak`;
        else if (symbol == "yyJOE") url = `https://yieldyak.com/yy/joe`;
        else if (symbol == "yyPTP") url = `https://yieldyak.com/yy/ptp`;
    }
    return url;
}

/**
 * Adapter for handling bridge assets and other exceptions
 * @dev returned symbol should match symbol from price data
 * @param {String} symbol 
 * @returns symbol
 */
function mapTokenSymbol(symbol) {
    if (symbol.toLowerCase() == "zeth") return "eth";
    else if (symbol.toLowerCase() == "wavax") return "avax";
    else if (symbol.toLowerCase() == "zusdc") return "usdc";
    else if (symbol.toLowerCase() == "zusdt") return "usdt";
    else if (symbol.toLowerCase() == "zdai") return "dai";
    else if (symbol.toLowerCase() == "zeth") return "eth";
    else if (symbol.toLowerCase() == "zsushi") return "sushi";
    else if (symbol.toLowerCase() == "zuni") return "uni";
    else if (symbol.toLowerCase() == "zbtc") return "btc";
    else if (symbol.toLowerCase() == "wbtc") return "btc";
    else if (symbol.toLowerCase() == "dai.e") return "dai";
    else if (symbol.toLowerCase() == "link.e") return "link";
    else if (symbol.toLowerCase() == "wbtc.e") return "btc";
    else if (symbol.toLowerCase() == "btc.b") return "btc";
    else if (symbol.toLowerCase() == "weth") return "eth";
    else if (symbol.toLowerCase() == "weth.e") return "eth";
    else if (symbol.toLowerCase() == "usdt.e") return "usdt";
    else if (symbol.toLowerCase() == "usdc.e") return "usdc";
    else if (symbol.toLowerCase() == "aave.e") return "aave";
    else if (symbol.toLowerCase() == "bamboo-v2") return "bamboo";
    else if (symbol.toLowerCase() == "gondoladaipool") return "dai";
    else if (symbol.toLowerCase() == "gondolaethpool") return "eth";
    else if (symbol.toLowerCase() == "gondolausdtpool") return "usdt";
    else if (symbol.toLowerCase() == "gondoladaieusdte") return "usdt";
    else if (symbol.toLowerCase() == "gondolabtcpool") return "btc";
    else if (symbol.toLowerCase() == "gondolausdce") return "usdt";
    else if (symbol.toLowerCase() == "gondolatsd") return "usdt";
    else if (symbol.toLowerCase() == "gondolayakpool") return "yak";
    else if (symbol.toLowerCase() == "av3crv") return "usdt";
    else if (symbol.toLowerCase() == "money3crv-f") return "usdt";
    else if (symbol.toLowerCase() == "yusdcrv-f") return "usdt";
    else if (symbol.toLowerCase() == "3poolv2-f") return "usdt";
    else if (symbol.toLowerCase() == "as4d") return "usdt";
    else if (symbol.toLowerCase() == "ac4d") return "usdt";
    else if (symbol.toLowerCase() == "am3d") return "usdt";
    else if (symbol.toLowerCase() == "aa3d") return "usdt";
    else if (symbol.toLowerCase() == "usdc-am3d") return "usdt";
    else if (symbol.toLowerCase() == "s3d") return "usdt";
    else if (symbol.toLowerCase() == "s3f") return "usdt";
    else if (symbol.toLowerCase() == "fsglp") return "glp";
    else if (symbol.toLowerCase() == "s*usdt") return "usdt";
    else if (symbol.toLowerCase() == "s*usdc") return "usdc";
    else if (symbol.toLowerCase() == "axlluna") return "luna";
    else if (symbol.toLowerCase() == "axlust") return "ust";
    else if (symbol.toLowerCase() == "nusd-lp") return "usdc";
    else if (symbol.toLowerCase() == "neth-lp") return "eth";
    else if (symbol.toLowerCase() == "yyjoe") return "joe";
    else if (symbol.toLowerCase() == "yyptp") return "ptp";
    else if (symbol.toLowerCase() == "usp") return "usdt";
    else return symbol;
}

function calculateTvlForSingleAssetPool(
    priceData,
    tokenBalance,
    tokenSymbol,
    tokenDecimals = 18
) {
    let tokenPrice;
    try {
        for (let row of priceData) {
            if (row.symbol.toLowerCase() == mapTokenSymbol(tokenSymbol).toLowerCase()) {
                tokenPrice = row.price;
                let tvl = tokenBalance
                    .mul("1000000000000000000")
                    .div(BigNumber.from("10").pow(tokenDecimals))
                    .mul(String(parseInt(tokenPrice * 100000)))
                    .div("100000");
                return tvl;
            }
        }
    }
    catch (err) {
        console.log("could not calculate tvl", err);
    }

    return BigNumber.from("0");
}

function calculateTvl(
    priceData,
    lpTokenBalance,
    lpTokenSupply,
    token0Reserves,
    token1Reserves,
    token0Symbol,
    token1Symbol,
    token0Decimals,
    token1Decimals
) {
    let token0Balance = lpTokenBalance.mul(token0Reserves).div(lpTokenSupply);
    let token1Balance = lpTokenBalance.mul(token1Reserves).div(lpTokenSupply);

    let token0Price;
    let token1Price;
    try {
        for (let row of priceData) {
            if (row.symbol.toLowerCase() ==  mapTokenSymbol(token0Symbol).toLowerCase()) {
                token0Price = row.price;
                let tvl = token0Balance
                    .mul("1000000000000000000")
                    .div(BigNumber.from("10").pow(token0Decimals))
                    .mul("2")
                    .mul(String(parseInt(token0Price * 100000)))
                    .div("100000");
                return tvl;
            }
            else if (row.symbol.toLowerCase() == mapTokenSymbol(token1Symbol).toLowerCase()) {
                token1Price = row.price;
                let tvl = token1Balance
                    .mul("1000000000000000000")
                    .div(BigNumber.from("10").pow(token1Decimals))
                    .mul("2").mul(String(parseInt(token1Price * 100000)))
                    .div("100000");
                return tvl;
            }
        }
    }
    catch (err) {
        console.log("could not calculate tvl", err);
    }

    return BigNumber.from("0");
}

export { 
    calculateTvl, calculateTvlForSingleAssetPool, 
    getMoreDepositUrl, getRemoveLiquidityUrl,
    mapTokenSymbol, 
    mapPlatformName,
    mapPlatformUrl,
    mapPlatformSource,
    mapPlatformTwitter,
    mapPlatformDescription,
    srcForToken,
    mapNetworkName
}