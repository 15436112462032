<template>
    <div class="content mb-4">
        <p class="heading">
            About this Strategy
        </p>
        <p>
            Deposit <strong>{{ this.depositToken.symbol }}</strong> to earn <strong>{{ this.depositToken.symbol }}</strong>.
        </p>
        <p v-if="tags && tags.includes('leverage')">
            This strategy repeatedly lends and borrows <strong>{{ this.depositToken.symbol }}</strong> on <strong>{{ this.platformName }}</strong> to optimize rewards using low-risk leverage.
        </p>
        <p v-else-if="tags && tags.includes('marginswap')">
            This strategy lends <strong>{{ this.depositToken.symbol }}</strong> to margin traders.
        </p>
        <p v-else-if="tags && tags.includes('elk')">
            This strategy autocompounds both rewards and impermanent loss protection.
        </p>
        <p v-else-if="tags && tags.includes('xjoe')">
            This strategy converts JOE to xJOE to both earn yield from <strong>{{ this.platformName }}</strong> farm rewards and revenue.
        </p>
        <p v-else-if="tags && tags.includes('veptp')">
            This strategy autocompounds <strong>{{ this.platformName }}</strong> boosted farm rewards using Yield Yak's <router-link :to="{ name: 'YyPtp' }"><strong>vePTP balance</strong></router-link>.
        </p>
        <p v-else-if="tags && tags.includes('esgmx')">
            This strategy autocompounds <strong>{{ this.platformName }}</strong> boosted farm rewards using Yield Yak's esGMX balance.
        </p>
        <p v-else>
            This strategy autocompounds <strong>{{ this.platformName }}</strong> farm rewards.
        </p>
        <p class="has-text-warning" v-if="isLpToken">
            {{ this.depositToken.symbol }} is an LP token that earns swap fees and is subject to impermanent loss.
        </p>
        <div class="buttons">
            <ButtonMedium 
                v-if="tags && tags.includes('aave')" 
                expanded
                href="https://yieldyak.medium.com/aave-strategies-d36d95b15b61"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('axial')" 
                expanded
                href="https://yieldyak.medium.com/earn-big-on-stables-introducing-axial-farms-on-yield-yak-db7d2218e199"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('benqi')" 
                expanded
                href="https://yieldyak.medium.com/benqi-strategies-cbb9d37e6578"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('blizz')" 
                expanded
                icon-right="open-in-new"
                tag="a" href="https://yieldyak.medium.com/blizz-finance-new-single-asset-strategies-on-yield-yak-5fc5b42795ca"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('marginswap')" 
                expanded
                icon-right="open-in-new"
                tag="a" href="https://yieldyak.medium.com/marginswap-strategies-52cfd232f896"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('elk')" 
                expanded
                href="https://yieldyak.medium.com/elk-strategies-fb6c864691cc"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('bankerjoe')" 
                expanded
                href="https://yieldyak.medium.com/first-edition-of-yak-rush-airdrop-for-banker-joe-deposits-8241ae4ce658"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('moneycrv')" 
                expanded
                href="https://yieldyak.medium.com/introducing-moneycrv-a-new-stablecoin-pool-with-vesting-rewards-a9a24f84e1e1"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('veptp')" 
                expanded
                icon-right="open-in-new"
                tag="a" href="https://yieldyak.medium.com/yield-yak-reveals-platypus-launch-strategy-including-treasury-contribution-and-double-rewards-pool-8e6e4458b12e"
            />
            <ButtonMedium 
                v-if="tags && tags.includes('vejoe')" 
                expanded
                href="https://yieldyak.medium.com/yield-yak-launches-yak-rush-for-yyjoe-alongside-vejoe-strategies-to-boost-trader-joe-farming-yields-d3c612672a44"
            />
            <ButtonMedium
                v-if="tags && tags.includes('esgmx')" 
                expanded
                href="https://medium.com/@yieldyak/avalanche-rush-comes-to-yield-yak-with-up-to-100k-in-rewards-as-part-of-collaboration-with-gmx-350d8cb13893"
            />
            <ButtonExplorer :chainId="chainId" :address="address" expanded />
        </div>
    </div>
</template>

<script>
  import { mapPlatformName } from '@/services/utils'
  import ButtonDocs from "@/components/Buttons/Docs";
  import ButtonExplorer from "@/components/Buttons/Explorer";
  import ButtonMedium from "@/components/Buttons/Medium";
  import PlatformInfo from "@/components/Detail/PlatformInfo";

  export default {
    components: {
        ButtonDocs,
        ButtonMedium,
        ButtonExplorer,
        PlatformInfo
    },
    props: [
        'chainId',
        'address',
        'depositToken',
        'platform',
        'tags'
    ],
    computed: {
        platformName: function () {
            return  mapPlatformName(this.platform)
        },
        isLpToken: function () {
            return this.depositToken.underlying.length > 1
        }
    }
  };
</script>
