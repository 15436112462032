import Vue from 'vue';

const BASE_URL = process.env.VUE_APP_FARM_API

const state = () => ({
    swapFees: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  swapFeesForFarm: state => farmAddress => {
    try {
      return state.swapFees[farmAddress]
    }
    catch {}
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  }
}
  
const mutations = {
    setSwapFee(state, payload) {
      try {
        Vue.set(state.swapFees, payload.key, payload.value)
      }
      catch (err) {
        console.log("swapFees / setSwapFee err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async loadData({ commit }) {
    commit('setIsLoading', true)
    try {
      let result = await fetch(BASE_URL + '/swapfees')
      let data = await result.json()

      for (const [key, value] of Object.entries(data)) {
        commit('setSwapFee', { key: key, value: value.swapFee })
      }

      commit('setInitialLoadComplete', true)
    }
    catch {}
    commit('setIsLoading', false)
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}