<template>
    <div class="card">
        <router-link :to="{ name: 'Detail', params: { address } }" class="with-hover">
            <div class="card-content px-4 py-2">
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="level is-mobile">
                            <div class="level-left">
                                <div class="level-item">
                                    <Tokens 
                                        v-if="depositToken.underlying"
                                        :tokens="depositToken.underlying"
                                        alignment="is-left"
                                        size="is-small"
                                    />
                                </div>
                                <div class="level-item">
                                    <div class="ml-2">
                                        <p class="heading">{{ platformName }}</p>
                                        <p class="title is-size-4">{{ name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-2">
                        <div v-if="account && accountStakedDepositTokens && accountStakedDepositTokens.gt('100')">
                            <p class="heading">My TVL</p>
                            <p class="title is-size-4">
                                {{ accountTvl | usd }}
                            </p>
                        </div>
                    </div>
                    <div class="column is-2">
                        <p class="heading">TVL</p>
                        <p class="title is-size-4" v-if="tvl">
                            {{ tvl | usd_compact }}
                        </p>
                    </div>
                    <div class="column is-2" v-if="apy">
                        <p :class="`heading ${ !deprecated && !statsOutdated && `is-success` }`">
                            <span>APY</span>
                        </p>
                        <p class="title is-size-4 is-success" v-if="apy && apy > 99999">
                            {{ 99999 | percentageMax }}
                            <span v-if="statsOutdated">*</span>
                        </p>
                        <p :class="`title is-size-4 ${ !statsOutdated && `is-success` }`" v-else-if="apyCompound">
                            {{ apyCompound | percentage_1 }}
                            <span v-if="statsOutdated">*</span>
                        </p>
                    </div>
                    <div class="column is-2" v-else>
                        <p class="heading">APY</p>
                        <p class="title is-size-4 has-text-grey">
                            tbd
                        </p>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { BigNumber } from "ethers";
  import { mapPlatformName } from '@/services/utils'

  import Tokens from "@/components/Tokens";

  export default {
    components: {
      Tokens,
    },
    props: [
        'address',
        'depositToken',
        'deprecated',
        'name', 
        'platform',
        'tvl'
    ],
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            apyForFarm: 'apy/apyForFarm',
            lastReinvestForFarm: 'apy/lastReinvestForFarm',
            swapFeesForFarm: 'swapfees/swapFeesForFarm',
            accountFarmBalances: 'balances/farmBalances',
        }),
        statsOutdated: function () {
            try {
                let lastReinvest = this.lastReinvestForFarm(this.address).timestamp
                let threshold = 60 * 60 * 24 * 2
                let now = parseInt(new Date() / 1000)
                return lastReinvest + threshold < now
            }
            catch {}
        },
        apyCompound: function () {
            let swapFees = this.swapFeesForFarm(this.address) || 0
            let rewards = this.apyForFarm(this.address) || 0
            return swapFees + rewards
        },
        apy: function () {
            return this.apyForFarm(this.address)
        },
        platformName: function () {
            return mapPlatformName(this.platform)
        },
        accountStakedDepositTokens: function () {
            try {
                return this.accountFarmBalances(this.address).stakedDepositTokens
            }
            catch {
                return BigNumber.from("0")
            }
        },
        accountTvl: function () {
            try {
                return this.accountFarmBalances(this.address).tvl
            }
            catch {
                return BigNumber.from("0")
            }
        },
    },
  };
</script>
