import { BigNumber, Contract, constants } from 'ethers';
import Chains from '@/contracts/Chains';
import PairABI from '@/abis/Pair';
import YakRouterABI from '@/abis/YakRouter';

import DeployedAddresses from '@/contracts/Deployed';
import TokenList from '@/contracts/TokenList';
import { 
  showSnackbarSuccess,
  showSnackbarDanger
} from '@/services/Snackbar';

const state = () => ({})

const getters = {
  tokenList: (state, getters, rootState, rootGetters) => {
    let chainId = rootGetters['accountModule/selectedChainId']
    try {
      return TokenList[chainId]
    }
    catch {
      return []
    }
  },
  isSupported: (state, getters, rootState, rootGetters) => {
    let chainId = rootGetters['accountModule/selectedChainId']
    if (chainId) {
      try {
          return Chains[chainId].supportedFeatures.swap
      }
      catch {
          return false
      }
    }
    return false
  },
}
  
const mutations = {}

const actions = {
  async swap ({ rootGetters }, payload) {
    console.log("debug::swap", payload)
    let chainId = rootGetters['accountModule/selectedChainId']
    let account = rootGetters['accountModule/account']
    let signer = rootGetters['accountModule/signer']

    if (chainId && account && signer) {
      const { trade, fromNative, toNative } = payload;
      const yakRouterAddress = DeployedAddresses[chainId].yakSwap.router;
      const yakRouterContract = new Contract(yakRouterAddress, YakRouterABI, signer);
      const fee = 0;
      const gasBuffer = chainId == "42161" ? BigNumber.from("2000000") : BigNumber.from("100000")
      const gasLimit = trade.gasEstimate.mul("12").div("10").add(gasBuffer) // 120% + 1,000,000
      try {
        if (fromNative) {
          let tx = await yakRouterContract.swapNoSplitFromAVAX({
              amountIn: trade.amounts[0],
              amountOut: trade.amounts[trade.amounts.length - 1],
              path: trade.path,
              adapters: trade.adapters
            }, 
            account, 
            fee, 
            { 
              value: trade.amounts[0], 
              gasLimit
            });
          showSnackbarSuccess("Swap Pending", "Transaction sent");
          let txReceipt = await tx.wait(1);
          console.log(txReceipt)
          if (txReceipt.status) {
            showSnackbarSuccess("Swap Success", "Transaction confirmed");
          }
          else {
            showSnackbarDanger("Swap Failed", "Transaction failed");
          }
          return txReceipt.status;
        }
        else {
          // handle approval
          const fromTokenAddress = trade.path[0];
          const tokenContract = new Contract(fromTokenAddress, PairABI, signer);
          const approvedBalance = await tokenContract.allowance(account, yakRouterAddress);
          
          // Use on-chain approval method
          try {
            if (approvedBalance.lt(trade.amounts[0])) {
              let tx = await tokenContract.approve(yakRouterAddress, constants.MaxUint256);
              showSnackbarSuccess("Approval Pending", "Transaction sent");
              let txReceipt = await tx.wait(1);
              // console.log("debug::txReceipt", txReceipt)
              if (txReceipt.status) {
                showSnackbarSuccess("Approval Success", "Transaction confirmed");
              }
              else {
                showSnackbarDanger("Approval Failed", "Transaction failed");
                return txReceipt.status
              }
            }
            
            if (toNative) {
              let tx = await yakRouterContract.swapNoSplitToAVAX({
                  amountIn: trade.amounts[0],
                  amountOut: trade.amounts[trade.amounts.length - 1],
                  path: trade.path,
                  adapters: trade.adapters
                },
                account, 
                fee,
                { 
                  gasLimit
                }
              );
              showSnackbarSuccess("Swap Pending", "Transaction sent");
              let txReceipt = await tx.wait(1);
              if (txReceipt.status) {
                showSnackbarSuccess("Swap Success", "Transaction confirmed");
              }
              else {
                showSnackbarDanger("Swap Failed", "Transaction failed");
              }
              return txReceipt.status;
            }
            else {
              let tx = await yakRouterContract.swapNoSplit({
                  amountIn: trade.amounts[0],
                  amountOut: trade.amounts[trade.amounts.length - 1],
                  path: trade.path,
                  adapters: trade.adapters
                },
                account, 
                fee,
                { 
                  gasLimit
                }
              );
              showSnackbarSuccess("Swap Pending", "Transaction sent");
              let txReceipt = await tx.wait(1);
              if (txReceipt.status) {
                showSnackbarSuccess("Swap Success", "Transaction confirmed");
              }
              else {
                showSnackbarDanger("Swap Failed", "Transaction failed");
              }
              return txReceipt.status;
            }
          }
          catch (err) {
            console.log("swap::err", err);
            if (err?.code == 4001) {
              showSnackbarDanger("Swap Cancelled", "Transaction rejected");
              return false
            }
            return false;
          }
        }
      }
      catch (err) {
        console.log("swap::err", err);
        if (err?.code == 4001) {
          showSnackbarDanger("Swap Cancelled", "Transaction rejected");
          return false
        }
        showSnackbarDanger("Swap Failed", "Transaction failed");
        return false;
      }
    }
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}