<template>
    <section>
      <div v-if="isFarmsSupported">
        <div class="container is-fullhd">
          <tool
            class="my-3"
            title="Yak Pools"
            subtitle="Explore Yield Yak's huge selection of autocompounding pools and make your life easier."
            img="farms3"
            :flipped="true"
          ></tool>
        </div>
      
        <div v-if="farmList">
          <nav class="tabs" ref="farms-tabs">
            <div class="container is-fullhd">
              <b-tabs class="has-text-weight-semibold" position="is-centered" size="is-large" :animated="false" v-model="activeTab">
                <b-tab-item :value="t.value" v-for="t of tabs" :key="t.value">
                  <template #header>
                    <span class="is-capitalized">
                      {{ t.name }}
                    </span>
                  </template>
                </b-tab-item>
              </b-tabs>
            </div>
          </nav>
          <div class="container is-fullhd">
            <div class="columns is-multiline is-variable is-3 pb-6">
              <div class="column is-6-desktop is-12-tablet" v-if="activeTab !== 'explore'">
                <b-field grouped>
                  <b-dropdown :scrollable="true" max-height="400px" size="is-medium" v-model="sortBySelected">
                    <template #trigger>
                      <b-button 
                        icon-left="sort-variant" 
                        icon-right="chevron-down"
                        :type="isSortByActive ? 'is-primary' : ''"
                      >
                        {{ sortBySelected.name }}
                      </b-button>
                    </template>
                    <b-dropdown-item
                      v-for="filter of sortByOptions"
                      :key="filter.value" 
                      :value="filter"
                    >
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <div class="media">
                                <b-icon class="media-left" :icon="filter.icon"></b-icon>
                                <div class="media-content">
                                    <h3>{{ filter.name }}</h3>
                                    <small>{{ filter.description }}</small>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown :scrollable="true" max-height="400px" size="is-medium" v-model="platformFilter">
                    <template #trigger>
                      <b-button
                        icon-left="filter"
                        icon-right="chevron-down"
                        :type="isPlatformFilterActive ? 'is-primary' : ''"
                      >
                        <div class="level is-mobile">
                          <div class="level-left">
                            <div class="level-item">
                              {{ platformName(platformFilter) }}
                            </div>
                          </div>
                        </div>
                      </b-button>
                    </template>
                    <b-dropdown-item :custom="true">
                        <b-input
                            v-model="platformSearchTerm"
                            type="search"
                            icon="magnify"
                            placeholder="Search..."
                            autofocus
                            expanded
                        ></b-input>
                    </b-dropdown-item>
                    <b-dropdown-item :separator="true" />
                    <b-dropdown-item
                      v-for="filter of platformFilters"
                      :key="filter" 
                      :value="filter"
                    >
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <div class="media">
                              <div class="media-left">
                                <figure class="image is-32x32">
                                  <img class="is-rounded" :src="platformSource(filter)" :alt="platformName(filter)" />
                                </figure>
                              </div>
                                <div class="media-content">
                                    <h3>{{ platformName(filter) }}</h3>
                                    <small>{{ mapPlatformDescription(filter) }}</small>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown :scrollable="true" max-height="400px" size="is-medium" v-model="farmTypeFilter">
                    <template #trigger>
                      <b-button icon-left="filter" icon-right="chevron-down" :type="isFarmTypeFilterActive ? 'is-primary' : ''">
                        {{ farmTypeFilter.name }}
                      </b-button>
                    </template>
                    <b-dropdown-item v-for="farmTypeFilter of farmTypeFilters" :value="farmTypeFilter" :key="farmTypeFilter.value">
                      <div class="media">
                          <b-icon class="media-left" :icon="farmTypeFilter.icon"></b-icon>
                          <div class="media-content">
                              <h3>{{ farmTypeFilter.name }}</h3>
                              <small>{{ farmTypeFilter.description }}</small>
                          </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>
              <div class="column" v-if="activeTab !== 'explore'">
                <b-field grouped>
                  <b-input
                    v-model="searchTerm"
                    type="search"
                    icon="magnify"
                    placeholder="Search..."
                    expanded
                  ></b-input>
                  <b-field class="is-hidden-mobile">
                    <b-radio-button v-model="listMode"
                        :native-value="false"
                        type="is-light is-outlined">
                        <b-icon icon="view-module"></b-icon>
                    </b-radio-button>
                    <b-radio-button v-model="listMode"
                        :native-value="true"
                        type="is-light is-outlined">
                        <b-icon icon="view-sequential"></b-icon>
                    </b-radio-button>
                  </b-field>
                </b-field>
              </div>
              <div v-if="accountHasDeprecatedFarms && activeTab == 'myFarms' && !isFarmTypeFilterActive" class="column is-12">
                <b-notification type="is-danger" :closable="false" class="mt-2 has-text-centered">
                  <p><strong>Health check!</strong> One or more of your farms are no longer earning rewards. Please withdraw or upgrade to continue earning rewards.</p>
                  <p class="buttons is-centered mt-4">
                    <b-button has-icon icon-left="stethoscope" @click="deprecatedFarms">Review Farms</b-button>
                  </p>
                </b-notification>
              </div>
              <div v-if="!listMode" class="column is-12">
                <div class="columns is-multiline is-variable" v-if="activeTab == 'explore'">
                  <!-- <div class="column is-12 mt-2">
                    <p class="title has-text-centered-mobile">
                        Yield Yak's GLP Strategy
                    </p>
                  </div>
                  <section v-for="farm in glpFarmList" :key="'glp-' + farm.address" class="column is-4-desktop is-6-tablet is-12-mobile mt-3">
                    <FarmCard 
                      :address="farm.address"
                      :depositToken="farm.depositToken"
                      :deprecated="farm.deprecated"
                      :lpToken="farm.lpToken"
                      :rewardToken="farm.rewardToken"
                      :name="farm.name"
                      :reinvestRewardBips="farm.reinvestRewardBips"
                      :timelock="farm.timelock"
                      :token0="farm.token0"
                      :token1="farm.token1"
                      :totalDeposits="farm.totalDeposits"
                      :totalSupply="farm.totalSupply"
                      :pendingRewards="farm.pendingRewards"
                      :platform="farm.platform"
                      :tags="farm.tags"
                      :tvl="farm.tvl"
                      :farm="farm"
                      :hoverable="true"
                      :collapsible="true"
                    ></FarmCard>
                  </section>
                  <div class="column is-6-desktop mt-3">
                    <div class="content is-size-4">
                      <p class="has-text-centered-mobile">
                          Avalanche's most popular GLP strategy. Yield Yak continuously stakes esGMX in order to maximize GLP rewards for depositors.
                      </p>
                      <ul>
                        <li>Stakes esGMX and compounds all AVAX rewards</li>
                        <li>Boosted with YAK x esGMX treasury swap.  <a href="https://debank.com/profile/0x565d72a63d3dc4a2f3dd7609fd85c4fd394523b2" target="_blank">Track our esGMX on DeBank &nearr;</a></li>
                        <li>No deposit or withdrawal fees. 10% on performance</li>
                        <li>Audited by CoinFabrik</li>
                      </ul>
                      <div class="buttons">
                        <b-button 
                          expanded
                          type="is-success"
                          icon-right="arrow-top-right"
                          tag="a" href="https://app.gmx.io/#/buy_glp/?ref=yak" target="_blank">
                          Buy GLP
                        </b-button>
                        <ButtonMedium 
                          expanded
                          href="https://medium.com/@yieldyak/avalanche-rush-comes-to-yield-yak-with-up-to-100k-in-rewards-as-part-of-collaboration-with-gmx-350d8cb13893"
                        />
                      </div>
                    </div>
                  </div> -->
                  <div class="column is-12 mt-6">
                    <p class="title has-text-centered-mobile">
                        Most Popular on Yield Yak
                    </p>
                    <p class="subtitle has-text-centered-mobile">
                        Explore the most popular autocompounding pools on Yield Yak.
                    </p>
                  </div>
                  <section v-for="farm in popularFarmList" :key="'popular-' + farm.address" class="column is-3-desktop is-6-tablet is-12-mobile my-3">
                    <FarmCard 
                      :address="farm.address"
                      :depositToken="farm.depositToken"
                      :deprecated="farm.deprecated"
                      :lpToken="farm.lpToken"
                      :rewardToken="farm.rewardToken"
                      :name="farm.name"
                      :reinvestRewardBips="farm.reinvestRewardBips"
                      :timelock="farm.timelock"
                      :token0="farm.token0"
                      :token1="farm.token1"
                      :totalDeposits="farm.totalDeposits"
                      :totalSupply="farm.totalSupply"
                      :pendingRewards="farm.pendingRewards"
                      :platform="farm.platform"
                      :tags="farm.tags"
                      :tvl="farm.tvl"
                      :farm="farm"
                      :hoverable="true"
                      :collapsible="true"
                      :hidePlatformImage="true"
                    ></FarmCard>
                  </section>
                  <div class="column is-12 is-hidden-mobile">
                      <div class="buttons mt-4 is-justify-content-right">
                          <b-button size="is-extra-large" type="is-success" icon-left="filter" @click="moreLikePopular">Most Popular</b-button>
                      </div>
                  </div>
                  <div class="column is-12 is-hidden-tablet is-hidden-desktop">
                      <div class="buttons mt-4">
                          <b-button expanded type="is-success" icon-left="filter" @click="moreLikePopular">Most Popular</b-button>
                      </div>
                  </div>
                  <div class="column is-12 mt-6">
                    <div class="content">
                      <p class="title has-text-centered-mobile">
                          Most Popular for Stablecoins
                      </p>
                      <p class="subtitle has-text-centered-mobile">
                          Earn on your stablecoins with Yield Yak's time-tested autocompounders.
                      </p>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="columns is-multiline is-variable is-3 pb-6">
                      <section v-for="farm in stableFarmList" :key="'stable-' + farm.address" class="column is-3-desktop is-6-tablet is-12-mobile">
                        <FarmCard 
                          :address="farm.address"
                          :depositToken="farm.depositToken"
                          :deprecated="farm.deprecated"
                          :lpToken="farm.lpToken"
                          :rewardToken="farm.rewardToken"
                          :name="farm.name"
                          :reinvestRewardBips="farm.reinvestRewardBips"
                          :timelock="farm.timelock"
                          :token0="farm.token0"
                          :token1="farm.token1"
                          :totalDeposits="farm.totalDeposits"
                          :totalSupply="farm.totalSupply"
                          :pendingRewards="farm.pendingRewards"
                          :platform="farm.platform"
                          :tags="farm.tags"
                          :tvl="farm.tvl"
                          :farm="farm"
                          :hoverable="true"
                          :collapsible="true"
                          :hidePlatformImage="true"
                        ></FarmCard>
                      </section>
                      <div class="column is-3-desktop is-6-tablet is-12-mobile">
                        <MoreCard title="More stablecoin pools" :click="moreLikeStable" />
                      </div>
                    </div>
                </div>
                  <div class="column is-12 mt-6" v-if="selectedChainId == 43114">
                    <p class="title has-text-centered-mobile">
                        Most Popular for Native AVAX
                    </p>
                    <p class="subtitle has-text-centered-mobile">
                        Put your AVAX to work with these popular autocompounders.
                    </p>
                  </div>
                  <section v-for="farm in avaxFarmList" :key="'avax-' + farm.address" class="column is-4-desktop is-6-tablet is-12-mobile my-3">
                    <FarmCard 
                      :address="farm.address"
                      :depositToken="farm.depositToken"
                      :deprecated="farm.deprecated"
                      :lpToken="farm.lpToken"
                      :rewardToken="farm.rewardToken"
                      :name="farm.name"
                      :reinvestRewardBips="farm.reinvestRewardBips"
                      :timelock="farm.timelock"
                      :token0="farm.token0"
                      :token1="farm.token1"
                      :totalDeposits="farm.totalDeposits"
                      :totalSupply="farm.totalSupply"
                      :pendingRewards="farm.pendingRewards"
                      :platform="farm.platform"
                      :tags="farm.tags"
                      :tvl="farm.tvl"
                      :farm="farm"
                      :hoverable="true"
                      :collapsible="true"
                      :hidePlatformImage="true"
                    ></FarmCard>
                  </section>
                  <div class="column is-12 is-hidden-mobile mb-6" v-if="selectedChainId == 43114">
                      <div class="buttons mt-6 is-justify-content-right">
                          <b-button size="is-extra-large" type="is-success" icon-left="filter" @click="moreLikeAvax">More like this</b-button>
                      </div>
                  </div>
                  <div class="column is-12 is-hidden-tablet is-hidden-desktop" v-if="selectedChainId == 43114">
                      <div class="buttons mt-6">
                          <b-button expanded type="is-success" icon-left="filter" @click="moreLikeAvax">More like this</b-button>
                      </div>
                  </div>
                  <div class="column is-12 mt-6">
                    <p class="title has-text-centered-mobile">
                        New on Yield Yak
                    </p>
                    <p class="subtitle has-text-centered-mobile">
                        Explore recently added pools on Yield Yak.
                    </p>
                  </div>
                  <section v-for="farm in newFarmList" :key="'recent-' + farm.address" class="column is-4-desktop is-6-tablet is-12-mobile my-3">
                    <FarmCard 
                      :address="farm.address"
                      :depositToken="farm.depositToken"
                      :deprecated="farm.deprecated"
                      :lpToken="farm.lpToken"
                      :rewardToken="farm.rewardToken"
                      :name="farm.name"
                      :reinvestRewardBips="farm.reinvestRewardBips"
                      :timelock="farm.timelock"
                      :token0="farm.token0"
                      :token1="farm.token1"
                      :totalDeposits="farm.totalDeposits"
                      :totalSupply="farm.totalSupply"
                      :pendingRewards="farm.pendingRewards"
                      :platform="farm.platform"
                      :tags="farm.tags"
                      :tvl="farm.tvl"
                      :farm="farm"
                      :hoverable="true"
                      :collapsible="true"
                      :hidePlatformImage="true"
                    ></FarmCard>
                  </section>
                  <div class="column is-12 is-hidden-mobile">
                      <div class="buttons mt-6 is-justify-content-right">
                          <b-button size="is-extra-large" type="is-success" icon-left="filter" @click="moreLikeRecent">Recently Added</b-button>
                      </div>
                  </div>
                  <div class="column is-12 is-hidden-tablet is-hidden-desktop">
                      <div class="buttons mt-6">
                          <b-button expanded type="is-success" icon-left="filter" @click="moreLikeRecent">Recently Added</b-button>
                      </div>
                  </div>
                </div>
                <div class="columns is-multiline is-variable" v-else>
                  <div class="column is-12">
                    <div class="level" v-if="isFiltered">
                      <div class="level-right">
                        <div class="level-item">
                          <p class="title">
                            Search Results ({{ filteredFarmList.length }})
                          </p>
                        </div>
                        <div class="level-item">
                          <b-button @click="clearFilters" v-if="isFiltered">Clear Filters</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section v-for="farm in filteredFarmList" :key="farm.address" class="column is-4-desktop is-6-tablet is-12-mobile my-3">
                    <FarmCard 
                      :address="farm.address"
                      :depositToken="farm.depositToken"
                      :deprecated="farm.deprecated"
                      :lpToken="farm.lpToken"
                      :rewardToken="farm.rewardToken"
                      :name="farm.name"
                      :reinvestRewardBips="farm.reinvestRewardBips"
                      :timelock="farm.timelock"
                      :token0="farm.token0"
                      :token1="farm.token1"
                      :totalDeposits="farm.totalDeposits"
                      :totalSupply="farm.totalSupply"
                      :pendingRewards="farm.pendingRewards"
                      :platform="farm.platform"
                      :tags="farm.tags"
                      :tvl="farm.tvl"
                      :farm="farm"
                      :hoverable="true"
                      :collapsible="true"
                      :hidePlatformImage="true"
                    ></FarmCard>
                  </section>
                </div>
              </div>
              <div v-else class="column is-12">
                <div class="columns is-multiline is-variable">
                  <section v-for="farm in filteredFarmList" :key="farm.address" class="column is-12">
                      <farm-list 
                        :address="farm.address"
                        :depositToken="farm.depositToken"
                        :deprecated="farm.deprecated"
                        :name="farm.name"
                        :platform="farm.platform"
                        :tvl="farm.tvl"
                      ></farm-list>
                  </section>
                </div>
              </div>
              <section v-if="activeTab !== 'explore' && !isSearchEmpty && !account" class="column is-12 my-2">
                <div class="card">
                  <div class="card-content">
                    <p class="title">Connect your account</p>
                    <p class="subtitle">Get started with Yield Yak now</p>
                    <ConnectButton :hideLabel="false" :expanded="false" />
                  </div>
                </div>
              </section>
              <section v-else-if="account && isLoadingMessageDisplayed" class="column is-12 my-2">
                <div class="card">
                  <div class="card-content">
                    <p class="title">Loading..</p>
                    <p class="subtitle">Need help? Try our community support channels</p>
                    <div class="buttons">
                        <b-button tag="a" icon-left="telegram" href="https://t.me/yieldyak" target="_blank">Telegram</b-button>
                        <b-button tag="a" icon-left="discord" href="https://discord.gg/JBbPKzX4rg" target="_blank">Discord</b-button>
                    </div>
                  </div>
                </div>
              </section>
              <section v-else-if="isSearchEmpty" class="column is-12 my-2">
                <div class="card">
                  <div class="card-content">
                    <p class="title">No matches found..</p>
                    <p class="subtitle">Need help? Try our community support channels</p>
                    <div class="buttons">
                        <b-button tag="a" icon-left="telegram" href="https://t.me/yieldyak" target="_blank">Telegram</b-button>
                        <b-button tag="a" icon-left="discord" href="https://discord.gg/JBbPKzX4rg" target="_blank">Discord</b-button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="container is-fullhd mt-3 mb-6">
          <PageNotAvailable />
        </div>
      </div>
    </section>
</template>

<script>
  import {mapGetters} from 'vuex';
  import Platforms from '@/contracts/Platforms';
  import { mapPlatformName, mapPlatformSource } from '@/services/utils';

  import Balance from "@/components/Balance";
  import ButtonDocs from "@/components/Buttons/Docs";
  import ConnectButton from "@/components/Buttons/Connect";
  import PageNotAvailable from "@/components/PageNotAvailable";
  import FarmCard from '@/components/Farms/Card';
  import FarmList from '@/components/Farms/List';
  import MoreCard from '@/components/Farms/MoreCard';
  import Tool from "@/components/Home/Tool";
  import ButtonMedium from "@/components/Buttons/Medium";

  const ALL_PLATFORM_FILTER = "All Platforms"

  const tabs = [{
    name: "Explore Pools",
    value: "explore"
  },{
    name: "All Pools",
    value: "allFarms"
  },{
    name: "My Pools",
    value: "myFarms"
  }/**,{
    name: "My Wallet",
    value: "myWallet"
  }*/]

  const sortByOptions =[{
    name: "My Farms",
    description: "Sort by my deposits",
    value: "yourTvl",
    icon: "piggy-bank"
  },{
    name: "Most Popular",
    description: "Sort by total deposits",
    value: "tvl",
    icon: "fire"
  },{
    name: "APY",
    description: "Sort by estimated returns",
    value: "apy",
    icon: "percent"
  },{
    name: "New Farms",
    description: "Sort by recently added",
    value: "recent",
    icon: "new-box"
  }]

  const farmTypeFilters = [{
    name: "All Farm Types",
    description: "Show me everything",
    value: "ALL_TYPES",
    icon: "tractor"
  },{
    name: "Single Assets",
    description: "Farms without impermanent loss",
    value: "single",
    icon: "bitcoin"
  },{
    name: "LP Tokens",
    description: "Farms with impermanent loss",
    value: "lp",
    icon: "scale-balance"
  },{
    name: "Stablecoins",
    description: "Farms with dollar-pegged assets",
    value: "stable",
    icon: "currency-usd"
  },{
    name: "Boosted",
    description: "Farms with a boost",
    value: "boosted",
    icon: "rocket"
  },{
    name: "Deprecated Farms",
    description: "Farms where rewards ended",
    value: "deprecated",
    icon: "skull"
  }]

  const DEFAULT_POLL_RATE = 3 * 60 * 1000;
  const CONNECTED_POLL_RATE = 20 * 1000;

  export default {
    components: {
      ConnectButton,
      ButtonDocs,
      FarmCard, 
      FarmList,
      PageNotAvailable,
      Balance,
      Tool,
      MoreCard,
      ButtonMedium
    },
    props: [
      'sortBy',
      'farmType',
      'platform',
      'tab',
      'type'
    ],
    data() {
      return {
        listMode: false,
        sortByOptions,
        searchTerm: "",
        platformSearchTerm: "",
        farmTypeFilters,
        tabs,
        polling: null,
        pollRate: DEFAULT_POLL_RATE
      };
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        selectedChainId: 'accountModule/selectedChainId',
        farmList: 'farms/data',
        accountHasDeprecatedFarms: 'farms/accountHasDeprecatedFarms',
        // isInitialLoadComplete: 'farms/initialLoadComplete',
        balancesInitialLoading: 'balances/initialLoading',
        sortedFarmList: 'farms/sortedFarmList',
        isFarmsSupported: "farms/isSupported"
      }),
      isSearchTermActive: function () {
        return this.searchTerm !== ""
      },
      isSortByActive: function () {
        return this.sortBySelected != sortByOptions[1]
      },
      isPlatformFilterActive: function () {
        return this.platformFilter != ALL_PLATFORM_FILTER
      },
      isFarmTypeFilterActive: function () {
        return this.farmTypeFilter != farmTypeFilters[0]
      },
      isFiltered: function() {
        return this.isSearchTermActive 
          || this.isPlatformFilterActive 
          || this.isFarmTypeFilterActive
          || this.isSortByActive
      },
      activeTab: {
        get: function () {
          let found = this.tabs.find(row => row.value == this.tab)
          if (found) {
            return found.value
          }
          return this.tabs[0].value
        },
        set: function (val) {
          if (val.toLowerCase() !== this.activeTab.toLowerCase()) {
            let query = Object.assign({}, this.$route.query);

            if (val.toLowerCase() == this.tabs[0].value.toLowerCase()) {
              delete query.tab
            }
            else {
              query.tab = val;
            }
            this.$router.replace({ name: 'Farms', query });
          }
        }
      },
      sortBySelected: {
        get: function () {
          let found = this.sortByOptions.find(row => row.value == this.sortBy)
          if (found) {
            return found
          }
          return this.sortByOptions[1]
        },
        set: function (val) {
          if (val.value.toLowerCase() !== this.sortBySelected.value.toLowerCase()) {
            let query = Object.assign({}, this.$route.query);

            if (val.value.toLowerCase() == this.sortByOptions[1].value.toLowerCase()) {
              delete query.sortBy
            }
            else {
              query.sortBy = val.value;
            }
            this.$router.replace({ name: 'Farms', query });
          }
        }
      },
      farmTypeFilter: {
        get: function () {
          let found = this.farmTypeFilters.find(row => row.value == this.farmType)
          if (found) {
            return found
          }
          return this.farmTypeFilters[0]
        },
        set: function (val) {
          if (val.value.toLowerCase() !== this.farmTypeFilter.value.toLowerCase()) {
            let query = Object.assign({}, this.$route.query);

            if (val.value.toLowerCase() == this.farmTypeFilters[0].value.toLowerCase()) {
              delete query.farmType
            }
            else {
              query.farmType = val.value;
            }
            this.$router.replace({ name: 'Farms', query });
          }
        }
      },
      platformFilter: {
        get: function () {
          return this.platform && this.platformFilters?.includes(this.platform) ? this.platform : ALL_PLATFORM_FILTER
        },
        set: function (val) {
          if (val !== this.platform) {
            let query = Object.assign({}, this.$route.query);
            if (val == ALL_PLATFORM_FILTER) {
              delete query.platform
            }
            else {
              query.platform = val;
            }
            this.$router.replace({ name: 'Farms', query });
          }
        }
      },
      platformFilters: function () {
        let filters = [ALL_PLATFORM_FILTER];
        if (this.farmList) {
          filters.push(...this.farmList.map(farm => farm.platform));
        }
        filters = filters.filter(platform => !!platform);
        if (this.platformSearchTerm) {
          filters = filters.filter(platform => {
              return this.platformName(platform).toLowerCase().includes(this.platformSearchTerm.toLowerCase())
          })
        }
        filters = [...new Set(filters)];
        return filters.sort()
      },
      isSearchEmpty: function () {
        return this.isFiltered ? this.filteredFarmList.length == 0 : false
      },
      avaxFarmList: function () {
        let filterObject = {
          tab: "explore",
          platformFilter: ALL_PLATFORM_FILTER,
          farmTypeFilter: "single",
          searchTerm: "AVAX"
        }
        return this.sortedFarmList("tvl", filterObject).slice(0,3);
      },
      glpFarmList: function () {
        let filterObject = {
          tab: "explore",
          platformFilter: "gmx",
          farmTypeFilter: "single",
          searchTerm: "GLP"
        }
        return this.sortedFarmList("tvl", filterObject).slice(0,3);
      },
      yakFarmList: function () {
        let filterObject = {
          tab: "explore",
          platformFilter: "yak",
          farmTypeFilter: "single",
          searchTerm: ""
        }
        return this.sortedFarmList("tvl", filterObject).slice(0,3);
      },
      newFarmList: function () {
        let filterObject = {
          tab: "explore",
          platformFilter: ALL_PLATFORM_FILTER,
          farmTypeFilter: "ALL_TYPES",
          searchTerm: ""
        }
        return this.sortedFarmList("recent", filterObject).slice(0,6);
      },
      stableFarmList: function () {
        let filterObject = {
          tab: "explore",
          platformFilter: ALL_PLATFORM_FILTER,
          farmTypeFilter: "stable",
          searchTerm: ""
        }
        return this.sortedFarmList("tvl", filterObject).slice(0,3);
      },
      popularFarmList: function () {
        let filterObject = {
          tab: "explore",
          platformFilter: ALL_PLATFORM_FILTER,
          farmTypeFilter: "ALL_TYPES",
          searchTerm: ""
        }
        return this.sortedFarmList("tvl", filterObject).slice(0,4);
      },
      filteredFarmList: function () {
        let filterObject = {
          tab: this.activeTab,
          platformFilter: this.platformFilter,
          farmTypeFilter: this.farmTypeFilter.value,
          searchTerm: this.searchTerm
        }
        return this.sortedFarmList(this.sortBySelected.value, filterObject);
      },
      isLoadingMessageDisplayed: function () {
        return this.balancesInitialLoading && ['myFarms', 'myWallet'].findIndex(el => el == this.activeTab) > -1
      },
    },
    watch: {
      account: function(val) {
        if (val) {
          this.pollRate = CONNECTED_POLL_RATE;
          clearTimeout(this.polling);
          this.handlePolling();
        }
        else {
          this.pollRate = DEFAULT_POLL_RATE;
        }
      },
    },
    mounted() {
      if (this.account) this.pollRate = CONNECTED_POLL_RATE;
      this.polling = setTimeout(this.handlePolling, this.pollRate)
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      platformName: function(platformId) {
        return mapPlatformName(platformId)
      },
      platformSource: function(platformId) {
        return mapPlatformSource(platformId)
      },
      async handlePolling() {
        let payload = this.filteredFarmList.flatMap(farm => [farm.depositToken.address, farm.address])
        await this.$store.dispatch('balances/loadData', payload)
        this.$store.dispatch('deposits/loadData')
        await this.$store.dispatch('farms/loadData')
        this.polling = setTimeout(this.handlePolling, this.pollRate)
      },
      mapPlatformDescription(platformId) {
        if (platformId == ALL_PLATFORM_FILTER) return "Show me everything"
        try {
          return Platforms[platformId].description
        }
        catch {}
        return null
      },
      goToYyJoe() {
        return this.$router.push({name: 'YyJoe'});
      },
      deprecatedFarms() {
        this.farmTypeFilter = farmTypeFilters[5] // deprecated
        this.searchTerm = ""
        this.platformFilter = ALL_PLATFORM_FILTER
      },
      moreLikeAvax() {
        this.searchTerm = "AVAX"
        this.scrollTo("farms-tabs")
        return this.$router.push({name: 'Farms', query: { tab: 'allFarms', farmType: "single" }});
      },
      moreLikeStable() {
        this.scrollTo("farms-tabs")
        return this.$router.push({name: 'Farms', query: { tab: 'allFarms', farmType: "stable", sortBy: "apy" }});
      },
      moreLikeRecent() {
        this.scrollTo("farms-tabs")
        return this.$router.push({name: 'Farms', query: { tab: 'allFarms', sortBy: "recent" }});
      },
      moreLikePopular() {
        this.scrollTo("farms-tabs")
        return this.$router.push({name: 'Farms', query: { tab: 'allFarms', sortBy: "tvl" }});
      },
      clearFilters() {
        this.sortBySelected = sortByOptions[1] // Most Popular
        this.farmTypeFilter = farmTypeFilters[0] // all
        this.searchTerm = ""
        this.platformFilter = ALL_PLATFORM_FILTER
      },
      scrollTo(refName) {
        var element = this.$refs[refName];
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
</script>
