import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Farms from '@/views/Farms.vue';
import Activity from '@/views/Activity.vue';
import Swap from '@/views/Swap.vue';
import Claim from '@/views/Claim.vue';
import Bonds from '@/views/Bonds.vue';
import Stake from '@/views/Stake.vue';
import ARC from '@/views/ARC.vue';
import Detail from '@/views/Detail.vue';
import LiquidStaking from '@/views/LiquidStaking.vue';
import YyPTP from '@/views/YyPTP.vue';
// import YyJOE from '@/views/YyJOE.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/farms',
        name: 'Farms',
        component: Farms,
        props: (route) => ({
            sortBy: route.query.sortBy,
            platform: route.query.platform,
            farmType: route.query.farmType,
            tab: route.query.tab
        })
    },
    {
        path: '/farms/detail/:address',
        name: 'Detail',
        component: Detail,
        props: (route) => ({
            address: route.params.address,
        })
    },
    {
        path: '/claim',
        name: 'Claim',
        component: Claim
    },
    {
        path: '/activity',
        name: 'Activity',
        component: Activity
    },
    {
        path: '/swap',
        name: 'Swap',
        component: Swap,
        props: (route) => ({
            inputCurrency: route.query.inputCurrency,
            outputCurrency: route.query.outputCurrency 
        })
    },
    {
        path: '/stake',
        name: 'Stake',
        component: Stake
    },
    {
        path: '/arc',
        name: 'ARC',
        component: ARC
    },
    {
        path: '/bonds',
        name: 'Bonds',
        component: Bonds
    },
    {
        path: '/liquid-staking',
        name: 'LiquidStaking',
        component: LiquidStaking
    },
    // {
    //     path: '/bridge',
    //     name: 'Bridge',
    //     component: LiquidStaking
    // },
    {
        path: '/yy/ptp',
        name: 'YyPtp',
        component: YyPTP,
        props: { symbol: 'ptp' }
    },
    {
        path: '/yy/joe',
        name: 'YyJoe',
        component: YyPTP,
        props: { symbol: 'joe' }
    },
    {
        path: '/yy/more',
        name: 'YyMore',
        component: YyPTP,
        props: { symbol: 'more' }
    },
    {
        path: '*',
        name: 'NotFound',
        component: Home
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.name == from.name) return
        if (savedPosition) return savedPosition
        return { x: 0, y: 0 }
    }      
});

export default router;
