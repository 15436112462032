import Vue from 'vue';

const BASE_URL = process.env.VUE_APP_FARM_API

const state = () => ({
    apr: {},
    apy: {},
    lastReinvest: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  aprForFarm: state => farmAddress => {
    try {
      return state.apr[farmAddress]
    }
    catch {}
  },
  apyForFarm: state => farmAddress => {
    try {
      return state.apy[farmAddress]
    }
    catch {}
  },
  lastReinvestForFarm: state => farmAddress => {
    try {
      return state.lastReinvest[farmAddress]
    }
    catch {}
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  }
}
  
const mutations = {
    setApr(state, payload) {
      try {
        Vue.set(state.apr, payload.key, payload.value)
      }
      catch (err) {
        console.log("apy / setApr err", err)
      }
    },
    setApy(state, payload) {
      try {
        Vue.set(state.apy, payload.key, payload.value)
      }
      catch (err) {
        console.log("apy / setApy err", err)
      }
    },
    setLastReinvest(state, payload) {
      try {
        Vue.set(state.lastReinvest, payload.key, payload.value)
      }
      catch (err) {
        console.log("apy / setApy err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async loadData({ commit, rootGetters }) {
    commit('setIsLoading', true)
    const chainId = rootGetters['accountModule/selectedChainId']
    try {
      let result = await fetch(`${BASE_URL}/${chainId}/apys`)
      let data = await result.json()

      for (const [key, value] of Object.entries(data)) {
        commit('setApr', { key: key, value: value.apr })
        commit('setApy', { key: key, value: value.apy })
        commit('setLastReinvest', { key: key, value: value.lastReinvest })
      }

      commit('setInitialLoadComplete', true)
    }
    catch {}
    commit('setIsLoading', false)
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}