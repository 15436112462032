import { providers } from 'ethers';
import { initOnboard } from '@/services/BlocknativeServices';
import { SUPPORTED_CHAIN_ID } from '@/settings';
import { getPublicProvider } from '@/services/NetworkHelpers';

const DEFAULT_CHAIN_ID = localStorage.getItem('selectedChainId') || SUPPORTED_CHAIN_ID

const state = () => ({
  account: null,
  provider: getPublicProvider(DEFAULT_CHAIN_ID),
  signer: null,
  onboard: null,
  initialLoadComplete: false,
  isLoading: false,
  connectedChainId: null,
  selectedChainId: DEFAULT_CHAIN_ID
})

const getters = {
  account: state => {
    return state.account
  },
  provider: state => {
    return state.provider
  },
  signer: state => {
    return state.signer
  },
  onboard: state => {
    return state.onboard
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  },
  isLoading: state => {
    return state.isLoading
  },
  connectedChainId: state => {
    return state.connectedChainId
  },
  selectedChainId: state => {
    return state.selectedChainId
  },
  shouldSwitchNetworkDisplay: state => {
    return state.connectedChainId && state.selectedChainId !== state.connectedChainId
  }
}

const mutations = {
    setAccount(state, account) {
      state.account = account
    },
    setProvider(state, provider) {
      state.provider = provider
    },
    setSigner(state, signer) {
      state.signer = signer
    },
    setOnboard(state, onboard) {
      state.onboard = onboard
    },
    setInitialLoadComplete(state, payload) {
      state.initialLoadComplete = payload
    },
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setConnectedChainId: (state, connectedChainId) => {
      return state.connectedChainId = connectedChainId
    },
    setSelectedChainId: (state, selectedChainId) => {
      return state.selectedChainId = selectedChainId
    }
}

const actions = {
  changeConnectedChainId({ commit }, value) {
    console.log("changeConnectedChainId", value)
    commit('setConnectedChainId', value)
    commit('setProvider', getPublicProvider(value))
  },
  changeSelectedChainId({ commit, dispatch, state }, value) {
    if (value !== state.selectedChainId) {
      commit('setSelectedChainId', value)
      commit('setProvider', getPublicProvider(value))
      window.localStorage.setItem('selectedChainId', value)
      dispatch('farms/clearData', null, { root: true })
      dispatch('farms/loadData', null, { root: true })
      dispatch('apy/loadData', null, { root: true }),
      dispatch('connect', false)
    }
  },
  async connect({ commit, dispatch, state }, withWalletSelect=true) {
    commit('setIsLoading', true)
    await dispatch('initOnboard', state.selectedChainId)

    const previouslySelectedWallet = window.localStorage.getItem('selectedWallet')
    if (previouslySelectedWallet) {
      const walletSelected = await state.onboard.walletSelect(previouslySelectedWallet)
      if (walletSelected) {
        const readyToTransact = await state.onboard.walletCheck()
        if (!readyToTransact) {
          window.localStorage.removeItem('selectedWallet')
        }
      }
    }
    else if (withWalletSelect) {
      const walletSelected = await state.onboard.walletSelect()
      if (walletSelected) {
        const readyToTransact = await state.onboard.walletCheck()
        if (!readyToTransact) {
          window.localStorage.removeItem('selectedWallet')
        }
      }
    }
    commit('setIsLoading', false)
    commit('setInitialLoadComplete', true)
  },
  disconnect({ commit, state }) {
    window.localStorage.removeItem('selectedWallet')
    if (state.onboard) {
      state.onboard.walletReset()
      console.log(state.onboard)
    }
    else console.log("account / disconnect:: without onboard")
  },
  async initOnboard ({ commit, dispatch, state }, payload) {
    if (state.onboard) {
      return
    }
    let onboard = initOnboard({
      address: (address) => {
        // console.log('vuex/onboard/address', address, new Date())
        dispatch('balances/clearData', null, { root: true })
        dispatch('deposits/clearData', null, { root: true })
        dispatch('voting/clearData', null, { root: true })
        if (address) {
          commit('setAccount', address)
          dispatch('balances/loadData', null, { root: true })
          dispatch('deposits/loadData', null, { root: true })
          // dispatch('voting/loadData', null, { root: true })
        }
        else {
          commit('setAccount', null)
        }
      },
      network: (network) => {
        if (network) {
          commit('setConnectedChainId', network.toString())
          // todo: validate chain is supported
        }
        else {
          commit('setConnectedChainId', null)
        }
      },
      wallet: (wallet) => {
        // console.log('vuex/onboard/wallet', wallet, new Date())
        if (wallet.provider) {
          let provider = new providers.Web3Provider(wallet.provider)
          commit('setProvider', provider)
          commit('setSigner', provider.getSigner())
          window.localStorage.setItem('selectedWallet', wallet.name)
        }
        else {
          commit('setProvider', getPublicProvider(payload))
          commit('setSigner', null)
          window.localStorage.removeItem('selectedWallet')
        }
      },
    }, payload)
    commit('setOnboard', onboard)
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}