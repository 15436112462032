<template>
    <b-button
        :disabled="initialLoading"
        :loading="isLoading"
        @click="connect"
        :size="size"
        :type="type"
        :expanded="expanded"
    >
        <div class="level is-mobile">
            <div class="level-item" v-if="!hideIcon">
                <figure class="image is-24x24" v-if="!isLoading && walletName && walletName.toLowerCase() !== 'wallet'">
                    <img width="24" height="24" :src="ASSETS_URL + '/wallets/' + walletName.toLowerCase() + '.png'" :alt="walletName" />
                </figure>
            </div>
            <div class="level-item" v-if="!hideLabel">
                <!-- <span class="icon" v-if="walletName.toLowerCase() !== 'metamask'">
                    <i class="mdi mdi-wallet"></i>
                </span> -->
                <span>
                    Connect {{ hideLabel ? "" : walletName }} {{ customVerb }}
                </span>
            </div>
        </div>
    </b-button>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { ASSETS_URL } from '@/settings';
  import { getWalletName } from '@/services/NetworkHelpers'

  export default {
    props: [
        'customVerb',
        'hideIcon',
        'hideLabel',
        'size',
        'type',
        'expanded'
    ],
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            initialLoading: 'accountModule/initialLoading',
            isLoading: 'accountModule/isLoading'
        }),
        walletName: function () {
            return getWalletName();
        },
    },
    data() {
      return {
        ASSETS_URL
      };
    },
    methods: {
        async connect() {
            await this.$store.dispatch("accountModule/connect");
        },
    },
  };
</script>
