<template>
  <div>
    <section class="section mt-3">
      <div class="container">
        <p class="title">
          Recent Activity
        </p>
        <div class="card">
          <div class="card-content p-0">
            <b-table :data="activity">
              <b-table-column field="blockTimestamp" label="Time" v-slot="props">
                {{ props.row.blockTimestamp | relativeTime }}
              </b-table-column>
              <b-table-column field="farm" label="Farm" v-slot="props">
                {{ props.row.farm.name }}
              </b-table-column>
              <b-table-column field="args" label="Account" v-slot="props">
                {{ props.row.by.id | shortEth }}
              </b-table-column>
              <b-table-column field="event" label="Action" v-slot="props">
                {{ props.row.event || "Reinvest" }}
              </b-table-column>
              <b-table-column field="transactionHash" label="Tx" v-slot="props">
                <a :href="props.row.transactionHash | explorerTx" target="_blank">{{ props.row.transactionHash | veryShortEth }}&nbsp;&nearr;</a>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </section>
    <section class="section mt-3">
      <div class="container">
        <p class="title">
          Reinvest Leaderboard
        </p>
        <p class="subtitle">
          All-time
        </p>
        <div class="card">
          <div class="card-content p-0">
            <b-table :data="leaderboard">
              <b-table-column field="id" label="Rank" v-slot="props" width="50" centered>
                <span v-if="props.index == 0">👑</span>
                <span v-else-if="props.index == 1">🥈</span>
                <span v-else-if="props.index == 2">🥉</span>
                <span v-else>{{ props.index + 1 }}</span>
              </b-table-column>
              <b-table-column field="id" label="Account" v-slot="props">
                <span>{{ props.row.id }}</span>
              </b-table-column>
              <b-table-column field="reinvestCount" label="Count" v-slot="props" numeric>
                {{ props.row.reinvestCount | number }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';

  const POLL_RATE = 60 * 1000;

  export default {
    components: {},
    computed: {
      ...mapGetters({
        activity: 'activity/activity',
        leaderboard: 'activity/leaderboard'
      }),
    },
    data() {
      return {
        loading: false,
        polling: null
      }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        await this.$store.dispatch('activity/updateActivity');
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
    }
  };
</script>