<template>
  <section>
    <div class="container is-fullhd mt-4" v-if="isEarnSupported">
      <div>
        <div class="box p-0" style="overflow: hidden;">
          <div class="columns p-0 p-mobile-0">
            <div class="column is-4-desktop is-6-tablet is-12-mobile">
              <b-image
                  class="is-hidden-mobile"
                  src="/assets/stake.jpg"
                  alt="Yield Yak"
                  ratio="600by400"
              ></b-image>
              <b-image
                  class="is-hidden-tablet"
                  src="/assets/stake.jpg"
                  alt="Yield Yak"
                  ratio="1200by800"
              ></b-image>
            </div>
            <div class="column has-text-centered px-4 pb-4">
              <p class="title pt-6 is-success">Stake YAK</p>
              <p class="subtitle" v-if="rewardsPerDayUsd">Weekly Rewards: {{ rewardsPerWeekUsd | usd_compact }} ({{ rewardsPerWeek }} AVAX)</p>
              <p class="subtitle" v-else>Earn Rewards</p>
              <p class="buttons" style="justify-content: center;">
                <ButtonDocs label="Using YAK" href="https://docs.yieldyak.com/token/using-yak" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="stakingData">
        <div class="columns is-multiline is-centered pb-3">
          <div class="column is-12">
            <p class="title">
              Stake YAK for AVAX
            </p>
          </div>
          <div v-for="pool in stakingData.pools" :key="pool.pid" class="column is-12 my-3">
            <stake-list
              :pid="pool.pid"
              :allocPoint="pool.allocPoint"
              :depositToken="pool.token"
              :rewardsPerSecond="stakingData.rewardsPerSecond"
              :totalAllocPoint="stakingData.totalAllocPoint"
              :totalStaked="pool.totalStaked"
              :userStaked="pool.userStaked"
              :pendingRewards="pool.pendingRewards"
            />
          </div>
        </div>
          <div class="column is-12">
            <p class="title">
              Stake YAK for more YAK
            </p>
            <div class="columns is-multiline is-variable">
              <section v-for="farm in filteredFarmList" :key="farm.address" class="column is-4-desktop is-6-tablet is-12-mobile my-3">
                <FarmCard 
                  :address="farm.address"
                  :depositToken="farm.depositToken"
                  :deprecated="farm.deprecated"
                  :lastReinvest="farm.lastReinvest"
                  :lpToken="farm.lpToken"
                  :rewardToken="farm.rewardToken"
                  :name="farm.name"
                  :reinvestRewardBips="farm.reinvestRewardBips"
                  :timelock="farm.timelock"
                  :token0="farm.token0"
                  :token1="farm.token1"
                  :totalDeposits="farm.totalDeposits"
                  :totalSupply="farm.totalSupply"
                  :pendingRewards="farm.pendingRewards"
                  :platform="farm.platform"
                  :tags="farm.tags"
                  :tvl="farm.tvl"
                  :farm="farm"
                  :hoverable="true"
                  :collapsible="true"
                ></FarmCard>
              </section>
            </div>
          </div>
      </div>

      <spinner v-else></spinner>

    </div>

    <div v-else>
      <div class="container is-fullhd mt-3 mb-6">
        <PageNotAvailable />
      </div>
    </div>
  </section>
</template>
<script>
  import {mapGetters} from 'vuex';
  import { BigNumber } from 'ethers';

  import Balance from "@/components/Balance";
  import ButtonDocs from "@/components/Buttons/Docs";
  import FarmCard from '@/components/Farms/Card';
  import Spinner from "@/components/Spinner";
  import StakeList from "@/components/StakeList";
  import PageNotAvailable from "@/components/PageNotAvailable";

  import Chains from '@/contracts/Chains';

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
      FarmCard,
      Spinner,
      StakeList,
      Balance,
      PageNotAvailable,
      ButtonDocs
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        chainId: 'accountModule/selectedChainId',
        stakingData: 'stakingData',
        farmForAddress: 'farms/dataForFarm',
        farmForDepositToken: 'farms/dataForDepositToken',
        priceForSymbol: 'prices/priceForSymbol',
        sortedFarmList: 'farms/sortedFarmList',
      }),
      isEarnSupported: function () {
          try {
              return Chains[this.chainId].supportedFeatures.earn
          }
          catch {
              return false
          }
      },
      filteredFarmList: function () {
        let filterObject = {
          platformFilter: "yak",
          depositToken: "0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7"
        }
        return this.sortedFarmList("tvl", filterObject);
      },
      rewardsPerDay: function() {
        return this.stakingData?.rewardsPerSecond?.mul("86400").div("1000000000000000000") || null
      },
      rewardsPerWeek: function() {
        return this.stakingData?.rewardsPerSecond?.mul("86400").mul("7").div("1000000000000000000") || null
      },
      rewardsPerDayUsd: function () {
        const price = this.priceForSymbol("AVAX")
        const rewardsPerDay = parseInt(this.rewardsPerDay)
        if (price && rewardsPerDay) return price * rewardsPerDay
      },
      rewardsPerWeekUsd: function () {
        const price = this.priceForSymbol("AVAX")
        const rewardsPerWeek = parseInt(this.rewardsPerWeek)
        if (price && rewardsPerWeek) return price * rewardsPerWeek
      },
      tvl: function() {
        let total = BigNumber.from("0");
        for (let pool of this.stakingData.pools) {
          try {
            let farm = this.farmForAddress(pool.token.address)
            let farmTvl = pool.totalStaked.mul(parseInt(farm.tvl)).div(parseInt(farm.totalSupply))
            total = total.add(farmTvl)
            continue;
          }
          catch {}

          try {
            let farm = this.farmForDepositToken(pool.token.address)
            let farmTvl = pool.totalStaked.mul(parseInt(farm.tvl)).div(parseInt(farm.totalDeposits))
            total = total.add(farmTvl)
            continue;
          }
          catch {}
        }
        return total;
      }
    },
    data() {
      return {
        polling: null
      };
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        // console.log("Stake/handlePolling", new Date())
        await this.$store.dispatch('voting/loadData');
        await this.$store.dispatch('fetchStakingData');
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      }
    },
  };
</script>