import { getActiveDeposits } from '@/services/ReinvestSubgraph';
import Vue from 'vue';

const state = () => ({
    data: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  data: state => {
    return state.data
  },
  activeDeposit: state => farmAddress => {
    try {
      return state.data[farmAddress.toLowerCase()]
    }
    catch {
      return "0"
    }
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  }
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("balances / setIndex err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async loadData({ commit, rootGetters }, payload=null) {
    // console.log('vuex/deposits/loadData', new Date())
    commit('setIsLoading', true)
    const account = rootGetters['accountModule/account']
    const chainId = rootGetters['accountModule/selectedChainId']
    if (account && chainId) {
        try {
          let activeDeposits = await getActiveDeposits(chainId, account);
          for (let row of activeDeposits) {
            commit('setValue', { key: row.farm.id, value: row.activeDeposit })
          }
        } catch {}
        commit('setInitialLoadComplete', true)
    }
    commit('setIsLoading', false)
  },
  clearData({ commit }) {
      commit('set', {})
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}