import { mapTokenSymbol } from '@/services/utils'
import { utils } from 'ethers'

const BASE_URL = process.env.VUE_APP_FARM_API

const state = () => ({
    data: [],
    initialLoadComplete: false
})

const getters = {
  data: state => {
    return state.data
  },
  initialLoadComplete: state => {
    return state.initialLoadComplete
  },
  priceForSymbol: state => (symbol) => {
    try {
      let found = state.data.find(row => row.symbol.toLowerCase() == symbol.toLowerCase())
      if (found) {
        return found.price
      }
    }
    catch {}
  },
  usdValue: (state, getters) => (amount, token) => {
    const symbol = mapTokenSymbol(token.symbol)
    const price = getters.priceForSymbol(symbol)
    if (price) {
      if (amount && amount.gt('0')) {
        const mantissa = utils.parseUnits('1', 6)
        const priceScaled = parseInt(parseFloat(price) * parseInt(mantissa))
        const valueBn = amount.mul(priceScaled).div(mantissa)
        return utils.formatUnits(valueBn, token.decimals)
      }
    }
  },
  priceDataForSymbol: state => (symbol) => {
    try {
      let found = state.data.find(row => row.symbol.toLowerCase() == symbol.toLowerCase())
      if (found) {
        return found
      }
    }
    catch {}
  },
  filteredData: (state) => {
    return state.data.filter((row) => row.display == true)
  },
  priceDataOrderedByTicker: (state, getters) => {
    try {
      let winners = getters.filteredData.sort((a, b) => a.priceChange > b.priceChange ? -1 : 1).slice(0,5)
      let losers = getters.filteredData.sort((a, b) => a.priceChange > b.priceChange ? 1 : -1).slice(0,5)
      return [winners, losers]
    }
    catch (err) { 
      console.log(err)
    }
  },
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setInitialLoadComplete(state, payload) {
      state.initialLoadComplete = payload
    }
}

const actions = {
  async loadData({ commit }) {
    try {
      let result = await fetch(BASE_URL + '/prices')
      let data = await result.json()
      commit('set', data)
      commit('setInitialLoadComplete', true)
    }
    catch {}
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}