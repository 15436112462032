import { constants, Contract, BigNumber } from 'ethers';
import YyPtpABI from '@/abis/yyPTP';
import PairABI from '@/abis/Pair';
import Vue from 'vue';
import DeployedAddresses from '@/contracts/Deployed';

const state = () => ({
    data: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  data: state => {
    return state.data
  },
  stakedBalance: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].stakedBalance
    }
    catch {}
  },
  accountStakedBalance: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].accountStakedBalance
    }
    catch {}
  },
  pendingRewards: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].pendingRewards
    }
    catch {}
    return []
  },
  veBalanceFor: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].veBalance
    }
    catch {}
  },
  underlyingBalanceFor: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].underlyingBalance
    }
    catch {}
  },
  veClaimableBalanceFor: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].veClaimable
    }
    catch {}
  },
  boostEndTimestampFor: state => symbol => {
    try {
      return state.data[symbol.toLowerCase()].boostEndTimestamp
    }
    catch {}
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  },
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("bonds / setValue err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async deposit({ state, commit, rootGetters }, payload) {
    const account = rootGetters['accountModule/account']
    const signer = rootGetters['accountModule/signer']
    if (account && signer) {
      const { address, depositTokenAddress, amount } = payload
      const depositTokenContract = new Contract(depositTokenAddress, PairABI, signer);
      const approvedBalance = await depositTokenContract.allowance(account, address);

      if (approvedBalance.lt(amount)) {
        let tx = await depositTokenContract.approve(address, constants.MaxUint256);
        await tx.wait(1);
      }

      const contract = new Contract(address, YyPtpABI, signer);
      let tx = await contract.deposit(amount);
      let txReceipt = await tx.wait();
      return txReceipt.status;
    }
    return false;
  },
  async claim({ rootGetters }, payload) {
    const account = rootGetters['accountModule/account']
    const signer = rootGetters['accountModule/signer']
    if (account && signer) {
      const { address, symbol } = payload
      try {
        const YY_ABI = require(`@/abis/yy${symbol}.json`)
        const contract = new Contract(address, YY_ABI, signer);
        let tx = await contract[`claimVe${symbol}()`]()
        let txReceipt = await tx.wait();
        return txReceipt.status;
      }
      catch {}
    }
    return false;
  },
  async claimRewards({ rootGetters }, payload) {
    const chainId = rootGetters['accountModule/selectedChainId']
    const signer = rootGetters['accountModule/signer']
    if (chainId && signer) {
      const { symbol } = payload
      try {
        const STAKING_ABI = require('@/abis/YyStaking.json')
        const stakingAddress = DeployedAddresses[chainId].yy[symbol.toLowerCase()].staking;

        const stakingContract = new Contract(stakingAddress, STAKING_ABI, signer);
        let tx = await stakingContract.withdraw("0");
        let txReceipt = await tx.wait();
        return txReceipt.status;
      }
      catch (err) { console.log(err) }
    }
    return false;
  },
  async loadStakingData({ state, commit, rootGetters }, payload) {
    // console.log("debug:loadStakingData", payload)
    const chainId = rootGetters['accountModule/selectedChainId']
    const provider = rootGetters['accountModule/provider']
    const account = rootGetters['accountModule/account']
    if (chainId && provider) {
      const { symbol } = payload
      try {
        const STAKING_ABI = require(`@/abis/YyStaking.json`)
        const PAIR_ABI = require(`@/abis/Pair.json`)

        const tokenAddress = DeployedAddresses[chainId].yy[symbol.toLowerCase()].token;
        const stakingAddress = DeployedAddresses[chainId].yy[symbol.toLowerCase()].staking;
        
        const stakingContract = new Contract(stakingAddress, STAKING_ABI, provider);

        const stakedBalance = await stakingContract.internalBalance();

        let accountStakedBalance;
        let pendingRewards = [];
        let rewardTokensLength = await stakingContract.rewardTokensLength();
        let i = BigNumber.from("0")
        while (i.lt(rewardTokensLength)) {
          let rewardToken = await stakingContract.rewardTokens(i);
          const tokenContract = new Contract(rewardToken, PAIR_ABI, provider)
          const symbol = await tokenContract.symbol()
          pendingRewards.push({
            address: rewardToken,
            amount: BigNumber.from("0"),
            symbol
          })
          i = i.add("1")
        }

        if (account) {
          const userInfo = await stakingContract.getUserInfo(
            account, tokenAddress
          );

          for (let i in pendingRewards) {
            const rewardAmount = await stakingContract.pendingReward(
              account, pendingRewards[i].address
            )
            pendingRewards[i].amount = rewardAmount
          }
          
          accountStakedBalance = userInfo[0]
        }

        commit('setValue', { key: symbol.toLowerCase(), value: {
          ...state.data[symbol.toLowerCase()],
          stakedBalance,
          accountStakedBalance,
          pendingRewards
        }})

      }
      catch {}
    }
  },
  async loadData({ state, commit, rootGetters }, payload) {
    // console.log("debug:loadData", payload)
    if (state.isLoading) {
      console.log('vuex/yyPtp/loadData -- already loading')
      return;
    }
    commit('setIsLoading', true)
    const chainId = rootGetters['accountModule/selectedChainId']
    const provider = rootGetters['accountModule/provider']
    if (chainId && provider) {
      const { symbol } = payload
      try {
        let veBalance, underlyingBalance, veClaimable, boostEndTimestamp, maxVeBalance

        if (symbol.toLowerCase() == "joe") {
          const voterAbi = require('@/abis/yyJOE.json')
          const veAbi = require('@/abis/veJOE.json')

          const voterAddress = DeployedAddresses[chainId].yy.joe.yy
          const veAddress = DeployedAddresses[chainId].yy.joe.ve

          let voterContract = new Contract(voterAddress, voterAbi, provider)
          let veContract = new Contract(veAddress, veAbi, provider)

          let userInfo;
          [
            veBalance, veClaimable, userInfo
          ] = await Promise.all([
            voterContract.veJOEBalance(),
            veContract.getPendingVeJoe(voterAddress),
            veContract.userInfos(voterAddress)
          ])
          underlyingBalance = userInfo.balance
          boostEndTimestamp = userInfo.speedUpEndTimestamp
          maxVeBalance = underlyingBalance.mul("100")
        }
        else if (symbol.toLowerCase() == "ptp") {
          const voterAbi = require('@/abis/yyPTP.json')
          const veAbi = require('@/abis/vePTP.json')

          const voterAddress = DeployedAddresses[chainId].yy.ptp.yy
          const vePtpAddress = DeployedAddresses[chainId].yy.ptp.ve

          let voterContract = new Contract(voterAddress, voterAbi, provider)
          let veContract = new Contract(vePtpAddress, veAbi, provider)

          veBalance = await voterContract.vePTPBalance();

          [
            underlyingBalance, veClaimable
          ] = await Promise.all([
            veContract.getStakedPtp(voterAddress),
            veContract.claimable(voterAddress),
          ]);
          
          maxVeBalance = underlyingBalance.mul("100")
        }
        else if (symbol.toLowerCase() == "more") {
          const voterAbi = require('@/abis/yyMORE.json')
          const veStakingAbi = require('@/abis/veMOREStaking.json')

          const voterAddress = DeployedAddresses[chainId].yy.more.yy
          const veStakingAddress = DeployedAddresses[chainId].yy.more.veStaking

          let voterContract = new Contract(voterAddress, voterAbi, provider)
          let veStakingContract = new Contract(veStakingAddress, veStakingAbi, provider)

          [
            veBalance, underlyingBalance, veClaimable
          ] = await Promise.all([
            voterContract.veMOREBalance(),
            veStakingContract.getStakedMore(voterAddress),
            veStakingContract.claimable(voterAddress)
          ])
          maxVeBalance = underlyingBalance.mul("100")
        }

        commit('setValue', { key: symbol.toLowerCase(), value: {
          ...state.data[symbol.toLowerCase()],
          veBalance,
          underlyingBalance,
          veClaimable,
          boostEndTimestamp,
          maxVeBalance
        }})
      }
      catch (error) {
        console.log("yyPtp/loadData/error", symbol, error)
      }
      commit('setInitialLoadComplete', true)
    }
    commit('setIsLoading', false)
  },
  clearData({ commit }) {
        commit('setInitialLoadComplete', false)
        commit('set', {})
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}