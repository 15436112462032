<template>
    <div class="box has-border-top">
        <div class="box is-dark">
            <p class="heading">Total Amount</p>
            <p class="title" v-if="payout && payout.gt('0')">
                <balance
                    :amount="payout.toString()"
                    :symbol="bond.rewardToken.symbol"
                    :decimals="bond.rewardToken.decimals || 18"
                    :underlying="[bond.rewardToken.address]"
                    :animationDuration="300"
                />
            </p>
            <p class="title has-text-grey" v-else>
                0 {{ bond.rewardToken.symbol }}
            </p>
            <p class="heading">Fully Vests</p>
            <p class="title" v-if="vesting && vesting.gt('0')">
                {{ new Date(parseInt(vesting)) | relativeTime }}
            </p>
            <p class="title has-text-grey" v-else>
                n/a
            </p>
            <p class="heading">Ready to Redeem</p>
            <p class="title is-success" v-if="pending && pending.gt('0')">
                <balance
                    :amount="pending.toString()"
                    :symbol="bond.rewardToken.symbol"
                    :decimals="bond.rewardToken.decimals || 18"
                    :underlying="[bond.rewardToken.address]"
                    :animationDuration="300"
                />
            </p>
            <p class="title has-text-grey" v-else>
                n/a
            </p>
            <b-button
            expanded
            size="is-large"
            type="is-primary"
            :loading="redeeming"
            :disabled="isRedeemDisabled"
            @click="redeem"
            >
                Redeem
            </b-button>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { utils } from 'ethers';

  import Balance from "@/components/Balance";
  import Tokens from "@/components/Tokens";

  export default {
    components: {
        Balance, Tokens
    },
    props: [
        'bond',
    ],
    data() {
        return {
            redeeming: false
        }
    },
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            bondInfoFor: 'bonds/bondInfoFor',
            pendingPayoutFor: 'bonds/pendingPayoutFor',
        }),
        isRedeemDisabled: function () {
            return !this.account || !this.pending || this.pending.eq("0");
        },
        bondInfo: function () {
            return this.bondInfoFor(this.bond.address)
        },
        pending: function () {
            return this.pendingPayoutFor(this.bond.address)
        },
        payout: function () {
            return this.bondInfo?.payout || utils.parseUnits("0")
        },
        vesting: function () {
            return this.bondInfo?.lastBlockTimestamp.add(this.bondInfo.vesting)
        },
    },
    methods: {
        async updateAmountToReceive () {
            if (!this.depositAmount) {
                this.amountToReceive = null;
                return;
            }

            try {
                let payload = {
                    address: this.bond.address,
                    amount: utils.parseUnits(this.depositAmount, this.bond.depositToken.decimals || 18),
                }
                let result = await this.$store.dispatch('bonds/payoutFor', payload);
                this.amountToReceive = result
            }
            catch (err) { console.log(err) }
        },
        async redeem() {
            this.redeeming = true;
            let redeemResult = false
            try {
                let payload = {
                    address: this.bond.address
                }
                redeemResult = await this.$store.dispatch('bonds/redeem', payload);
            }
            catch (err) { console.log(err) }
            if (redeemResult) {
                await this.$store.dispatch('bonds/loadData')
            }
            this.redeeming = false;
        },
    }
  };
</script>
