<template>
    <div class="columns is-mobile">
        <div class="column is-narrow" v-if="img && !flipped">
            <figure class="image is-182x182">
                <img width="182" height="182" :src="srcForImg" :alt="title" />
            </figure>
        </div>
        <div class="column" style="margin: auto">
            <p class="title">{{ title }}</p>
            <p class="subtitle">{{ subtitle }}</p>
        </div>
        <div class="column is-narrow" v-if="img && flipped">
            <figure class="image is-182x182">
                <img width="182" height="182" :src="srcForImg" :alt="title" />
            </figure>
        </div>
    </div>
</template>
<script>
import { ASSETS_URL } from '@/settings';

export default {
    props: [
        'img',
        'title',
        'subtitle',
        'flipped'
    ],
    data() {
      return {
        ASSETS_URL
      }
    },
    computed: {
        srcForImg: function() {
            return `${this.ASSETS_URL}/emoji/square/${this.img}.png`
        }
    }
};
</script>