<template>
    <div class="card">
        <a
            :href="getMoreDepositUrl"
            target="_blank"
            class="with-hover"
        >
            <div class="card-content">
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="level is-mobile title is-size-5">
                            <div class="level-left">
                                <div class="level-item">
                                    <span>
                                        Get
                                    </span>
                                </div>
                                <div class="level-item">
                                    <Tokens
                                        alignment="is-left"
                                        :chainId="chainId"
                                        :tokens="underlying" 
                                    />
                                </div>
                                <div class="level-item">
                                    <span>
                                        {{symbol }} on {{ platformName }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p class="subtitle">
                            Add liquidity on the underlying platform
                        </p>
                    </div>
                    <div class="column is-narrow">
                        <b-icon icon="arrow-top-right" size="is-large" class="has-text-grey" />
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<style scoped>
.step {
    margin-bottom: -1rem;
    margin-left: -1rem;
}
</style>

<script>
  import Tokens from '@/components/Tokens';
  import { mapPlatformName, mapPlatformSource, getMoreDepositUrl } from '@/services/utils';
import { ASSETS_URL } from '@/settings';

  export default {
    components: {
      Tokens
    },
    data() {
      return {
        ASSETS_URL
      }
    },
    props: [
        'chainId',
        'platform',
        'depositTokenAddress',
        'underlying',
        'symbol',
    ],
    computed: {
        platformName: function () {
            return  mapPlatformName(this.platform)
        },
      platformSource: function() {
        return mapPlatformSource(this.platform)
      },
        address: function() {
            return this.underlying[0]
        },
        getMoreDepositUrl: function() {
            return getMoreDepositUrl(this.chainId, this.platform, this.symbol, this.depositTokenAddress, this.underlying);
        },
    },
  };
</script>
