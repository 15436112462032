<template>
  <section>
    <div class="container is-fullhd my-3" v-if="isEarnSupported">
      <div class="box p-0" style="overflow: hidden;">
        <div class="columns p-0 p-mobile-0">
          <div class="column is-6-desktop is-6-tablet is-12-mobile">
            <b-image
                class="is-hidden-mobile"
                :src="ASSETS_URL + '/banners/arc.jpeg'"
                alt="Yield Yak"
                ratio="600by400"
            ></b-image>
            <b-image
                class="is-hidden-tablet"
                :src="ASSETS_URL + '/banners/arc.jpeg'"
                alt="Yield Yak"
                ratio="1200by400"
            ></b-image>
          </div>
          <div class="column has-text-centered px-4">
            <p class="title pt-6 is-success">Be a Yak Hero</p>
            <p class="subtitle">Help distribute platform fees</p>
            <!-- <p class="buttons" style="justify-content: center;">
              <ButtonDocs label="Yak ARC" href="https://docs.yieldyak.com/token/yak-arc" />
              <b-button
                size="is-large"
                type="is-primary"
                :disabled="isDistributeDisabled"
                :loading="isLoading"
                @click="distribute"
              >
                Distribute
              </b-button>
            </p> -->
            <div class="box is-dark has-border-top has-text-left m-4">
              <p class="heading">Next Distribution</p>
              <p class="title has-text-grey" v-if="!balance">
                <!-- tbd -->
                <b-skeleton width="60%" size="is-large" />
              </p>
              <p class="title" v-else-if="balance && balance.gt('0')">
                <balance
                  :amount="balance"
                  decimals="18"
                  symbol="AVAX"
                  :useText="true"
                  :underlying="['0x0000000000000000000000000000000000000000']"
                />
              </p>
              <p class="title has-text-grey" v-else>
                0 AVAX
              </p>
              <p class="subtitle has-text-grey" v-if="!nextEpoch">
                <!-- tbd -->
                <b-skeleton width="40%" size="is-medium" />
              </p>
              <p class="subtitle is-success" v-else-if="isNextEpochAvailable">
                Ready now
              </p>
              <p class="subtitle has-text-grey" v-else-if="nextEpoch">
                Ready {{ nextEpoch | relativeTime }}
              </p>
              <p class="buttons">
                <b-button
                  expanded
                  size="is-large"
                  type="is-primary"
                  :disabled="isDistributeDisabled"
                  :loading="isLoading"
                  @click="distribute"
                >
                  Distribute
                </b-button>
                <ButtonDocs expanded label="Learn more" href="https://docs.yieldyak.com/token/yak-arc" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 class="title">Yak Heroes</h3>
        <div class="box has-text-centered">
          <div class="columns is-mobile">
            <div class="column content" v-for="result in leaderboard" :key="result.id">
              <figure class="image is-128x128" style="margin: 0 auto;">
                <img height="128" width="128" class="is-square" :src="ASSETS_URL + '/emoji/hero.png'" alt="Yield Yak" />
              </figure>
              <p class="title">{{ result.id | shortEth }}</p>
              <p class="subtitle">
                Distributed {{ result.amount | fromWei | number }} AVAX
                <br />
                {{ result.count }}x
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="columns">
          <div class="column is-8">
            <div class="box content">
              <div class="columns is-mobile">
                <div class="column">
                  <p class="title">Past Distributions</p>
                </div>
                <div class="column has-text-right">
                  <b-field>
                      <b-switch v-model="isUsd" :left-label="true" :rounded="true">
                          {{ isUsd ? "USD Value" : "AVAX" }}
                      </b-switch>
                  </b-field>
                </div>
              </div>
              <apexchart width="100%" type="line" :options="options" :series="series"></apexchart>
            </div>
          </div>
          <div class="column">
            <div class="box content">
              <p class="title">Recent Distributions</p>
              <div v-for="distribution in recentDistributions" :key="distribution.id" class="mb-6">
                <article class="media">
                  <figure class="media-left">
                    <figure class="image is-96x96 mx-0">
                      <img class="is-square" :src="ASSETS_URL + '/emoji/coin.png'" alt="Yield Yak" />
                    </figure>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        {{ distribution.blockTimestamp | relativeTime }} &bull; {{ distribution.by.id | shortEth }}
                        <br>
                        <strong><a :href="distribution.txHash | explorerTx" target="blank">Distributed {{ distribution.amount | fromWei | number }} AVAX &nearr;</a></strong>
                        <ul>
                          <li v-for="payment in distribution.payments" :key="payment.to.id">
                            {{ payment.amount | fromWei | number }} AVAX to {{ payment.to.id | contractName }}
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="container is-fullhd mt-3 mb-6">
        <PageNotAvailable />
      </div>
    </div>
  </section>
</template>
<script>
  import {mapGetters} from 'vuex';
  import VueApexCharts from 'vue-apexcharts';

  import Balance from "@/components/Balance";
  import ButtonDocs from '@/components/Buttons/Docs';
  import PageNotAvailable from "@/components/PageNotAvailable";

  import { ASSETS_URL } from '@/settings';
  import Chains from '@/contracts/Chains';

  import { utils } from 'ethers';

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
      Balance,
      ButtonDocs,
      PageNotAvailable,
      'apexchart': VueApexCharts,
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        chainId: 'accountModule/selectedChainId',
        balance: 'arc/balance',
        nextEpoch: 'arc/nextEpoch',
        distributions: 'arc/distributions',
        leaderboard: 'arc/leaderboard',
        isFarmsSupported: "farms/isSupported"
      }),
      isEarnSupported: function () {
          try {
              return Chains[this.chainId].supportedFeatures.earn
          }
          catch {
              return false
          }
      },
      isDistributeDisabled: function () {
        let now = parseInt(new Date() / 1000)
        return !this.account || !this.balance || !this.nextEpoch || (this.nextEpoch && this.nextEpoch.gt(now));
      },
      recentDistributions: function () {
        let data = []
        try {
          data = this.distributions.slice(0, 7)
        }
        catch {}
        return data
      },
      isNextEpochAvailable: function () {
        let now = parseInt(new Date() / 1000)
        return this.nextEpoch && this.nextEpoch.lte(now);
      },
      series: function () {
        const type = "column"
        const name = this.isUsd ? "USD Value" : "AVAX"
        const curve = "smooth"
        let data = []
        try {
          data = this.distributions.map(row => {
            return {
              x: parseInt(row.blockTimestamp) * 1000,
              y: this.isUsd ? parseFloat(row.usdValue) : parseFloat(utils.formatUnits(row.amount))
            }
          }) || []
        }
        catch {}

        return [{ name, type, data }]
      },
    },
    data() {
      return {
        isLoading: false,
        isUsd: true,
        polling: null,
        options: {
          chart: {
            id: 'vuechart-arc',
            toolbar: {
              show: false
            },
          },
          colors: ["#00C864"],
          grid: { borderColor: "#7A7A7A" },
          lagend: { show: true },
          tooltip: {
            theme: 'dark',
            x: {
              format: 'd MMM HH:mm'
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              style: { colors: '#F0F0F0' },
            },
          },
          yaxis: {
            // decimalsInFloat: 0,
            forceNiceScale: true,
            min: 0,
            labels: {
              style: { colors: '#F0F0F0' },
              formatter: val => this.isUsd ? this.$options.filters.usd(val) : this.$options.filters.number(val)
            },
          },
        },
        ASSETS_URL,
      }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        // console.log("ARC/handlePolling", new Date())
        await Promise.all([
          this.$store.dispatch('arc/loadData')
        ])
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      async distribute() {
        this.isLoading = true;
        let result = await this.$store.dispatch('arc/distribute');
        this.isLoading = false;
        if (result) {
          await Promise.all([
            this.$store.dispatch('arc/loadData')
          ])
        }
      },
    },
  };
</script>