<template>
  <div class="container pb-6">
    <atom-spinner
        :animation-duration="1000"
        :size="80"
        :color="'#553F77'"
        class="m-auto"
    />
    <p class="has-text-centered has-text-primary">
         Loading...
    </p>
  </div>
</template>

<script>
  // To use minified css and js files instead of .vue single file components:
  // import 'epic-spinners/dist/lib/epic-spinners.min.css'
  // import {AtomSpinner} from 'epic-spinners/dist/lib/epic-spinners.min.js'
  
  import {AtomSpinner} from 'epic-spinners'
  export default {
    components: {
      AtomSpinner
    }
  }
</script>