import { utils, BigNumber } from 'ethers';
import { mapTokenSymbol } from '@/services/utils'
import { getReinvests } from '@/services/ReinvestSubgraph'
import Chains from '@/contracts/Chains';
import Vue from 'vue';

const BASE_URL = process.env.VUE_APP_FARM_API

const state = () => ({
    data: [],
    initialLoadComplete: false,
    isLoading: false,
    reinvestData: {},
    depositsData: {},
})

const getters = {
  data: state => {
    return state.data
  },
  dataForFarm: state => address => {
    let found = state.data.find(row => row.address.toLowerCase() == address.toLowerCase())
    if (found) {
      return found
    }
  },
  tvl: state => {
    try {
      return state.data.map((farm) => parseFloat(farm.tvl || 0)).reduce((acc, v) => v + acc)
    }
    catch {}
  },
  similarFarms: (state) => address => {
    console.log("similarFarms")
    try {
      let farm = dataForFarm(address)
      let found = state.data.filter(row => {
        return row.address.toLowerCase() != address.toLowerCase() // not the same farm
          && !row.deprecated // not deprecated
          && row.depositToken.address.toLowerCase() == farm.depositToken.address.toLowerCase() // same deposit token
      })
      console.log(found)
      return found
    }
    catch (err) {
      console.log(err)
    }
  },
  reinvestDataForFarm: state => address => {
    try {
      return state.reinvestData[address]
    }
    catch {
      return []
    }
  },
  depositsDataForFarm: state => address => {
    try {
      return state.depositsData[address]
    }
    catch {
      return []
    }
  },
  dataForDepositToken: (state) => (address) => {
    let found = state.data.find(row => row.depositToken.address.toLowerCase() == address.toLowerCase())
    if (found) {
      return found
    }
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoadComplete: state => {
    return state.initialLoadComplete
  },
  isSupported: (state, getters, rootState, rootGetters) => {
    let chainId = rootGetters['accountModule/selectedChainId']
    if (chainId) {
      try {
          return Chains[chainId].supportedFeatures.farms
      }
      catch {
          return false
      }
    }
    return false
  },
  accountHasDeprecatedFarms: (state, getters, rootState, rootGetters) => {
    let account = rootGetters['accountModule/account']
    if (account) {
      let found = state.data.filter(farm => {
        let stakedDepositTokens = rootGetters['balances/farmBalances'](farm.address)?.stakedDepositTokens
        return farm.deprecated && stakedDepositTokens && stakedDepositTokens.gt("100")
      })
      // console.log("accountHasDeprecatedFarms", found)
      if (found.length > 0) {
        return true
      }
    }
    return false
  },
  filteredFarmList: (state, getters, rootState, rootGetters) => filterObject => {
    return state.data.filter(farm => {
      let stakedDepositTokens = rootGetters['balances/farmBalances'](farm.address)?.stakedDepositTokens
      let walletDepositTokens = rootGetters['balances/balanceOfToken'](farm.depositToken.address)
      const { tab, platformFilter, farmTypeFilter, searchTerm, depositToken } = filterObject

      // handle tabs
      if (tab == "myFarms" && !stakedDepositTokens) return false;
      else if (tab == "myFarms" && stakedDepositTokens?.lt("100")) return false;
      else if (tab == "myWallet" && !walletDepositTokens) return false;
      else if (tab == "myWallet" && walletDepositTokens?.lt("100")) return false;

      // ignore deprecated and dust balances
      // if ((farm.upgrade || farm.deprecated) && stakedDepositTokens?.lt("100")) return false;
      // else if ((farm.upgrade || farm.deprecated) && !stakedDepositTokens) return false;

      if (depositToken && depositToken.toLowerCase() !== farm.depositToken.address.toLowerCase()) return false

      // handle filters
      if (platformFilter && platformFilter !== "All Platforms" && farm.platform !== platformFilter) return false;
      
      if (farmTypeFilter == "single" && farm.depositToken.underlying?.length > 1) return false;
      else if (farmTypeFilter == "stable" && !farm.depositToken.stablecoin) return false;
      else if (farmTypeFilter == "lp" && farm.depositToken.underlying?.length < 2) return false;
      else if (farmTypeFilter == "boosted" && ((farm.tags && (farm.tags.indexOf("veptp") == -1 && farm.tags.indexOf("esgmx") == -1)) || !farm.tags)) return false
      else if (farmTypeFilter == "vejoe" && ((farm.tags && farm.tags.indexOf("vejoe") == -1) || !farm.tags)) return false
      else if (farmTypeFilter == "veptp" && ((farm.tags && farm.tags.indexOf("veptp") == -1) || !farm.tags)) return false
      else if (farmTypeFilter !== "deprecated" && farm.deprecated) return false
      else if (farmTypeFilter == "deprecated" && !farm.deprecated) return false

      // check searchTerm last
      if (searchTerm) {
        return farm.name.toLowerCase().includes(searchTerm.toLowerCase())
          || farm.platform.toLowerCase().includes(searchTerm.toLowerCase());
      }

      return true
    });
  },
  sortedFarmList: (state, getters, rootState, rootGetters) => (sortBy, filterObject) => {
    if (sortBy == "yourTvl" && !rootGetters['accountModule/account']) sortBy = "tvl"
    switch(sortBy) {
      case "yourTvl":
        return getters.filteredFarmList(filterObject).sort((a, b) => {
          try {
            let fa = rootGetters['balances/farmBalances'](a.address).tvl
            let fb = rootGetters['balances/farmBalances'](b.address).tvl
    
            if (fa.gt(fb)) return -1
            else if (fa.lt(fb)) return 1
          }
          catch {}
          return 0
        })
      case "apy":
        return getters.filteredFarmList(filterObject).sort((a, b) => {
          try {
            let fa = rootGetters['apy/apyForFarm'](a.address) || 0
            let ga = rootGetters['swapfees/swapFeesForFarm'](a.address) || 0
            let ha = fa + ga
            // if (fa === undefined) fa = 0
            let fb = rootGetters['apy/apyForFarm'](b.address) || 0
            let gb = rootGetters['swapfees/swapFeesForFarm'](b.address) || 0
            let hb = fb + gb
            // if (fb === undefined) fb = 0
            if (ha > hb) return -1
            else if (ha < hb) return 1
            else return 0
          }
          catch (err) { console.log(err) }
          return 0
        })
      case "recent":
        return getters.filteredFarmList(filterObject).sort((a, b) => {
          let fa = a.deployed === undefined ? 0 : parseFloat(a.deployed)
          let fb = b.deployed === undefined ? 0 : parseFloat(b.deployed)
          if (fa > fb) return -1
          else if (fa < fb) return 1
          else return 0
        })
      case "tvl":
      default:
        return getters.filteredFarmList(filterObject).sort((a, b) => {
          let fa = (a.tvl === undefined || a.tvl === null) ? 0 : parseFloat(a.tvl)
          let fb = (b.tvl === undefined || b.tvl === null) ? 0 : parseFloat(b.tvl)
          if (fa > fb) return -1
          else if (fa < fb) return 1
          else return 0
        })
    }
  }
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setInitialLoadComplete(state, payload) {
      state.initialLoadComplete = payload
    },
    setIsLoading(state, payload) {
      try {
        state.isLoading = payload
      }
      catch (err) {
        console.log("farms / setIsLoading err", err)
      }
    },
    setReinvestData(state, payload) {
      try {
        Vue.set(state.reinvestData, payload.key, payload.value)
      }
      catch (err) {
        console.log("farms / setReinvestData err", err)
      }
    },
    setDepositsData(state, payload) {
      try {
        Vue.set(state.depositsData, payload.key, payload.value)
      }
      catch (err) {
        console.log("farms / setDepositsData err", err)
      }
    },
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  clearData({ commit }) {
    commit('set', [])
    commit('setInitialLoadComplete', false)
  },
  async loadData({ commit, dispatch, rootGetters }) {
    // console.log("farms/loadData::start", new Date())
    commit('setIsLoading', true)
    const chainId = rootGetters['accountModule/selectedChainId']
    try {
      let result = await fetch(`${BASE_URL}/${chainId}/farms`)
      let data = await result.json()
      // console.log("farms/loadData::json", new Date())
      data = await Promise.all(data.map((farm) => dispatch('loadBalances', farm)))
      data = await Promise.all(data.map((farm) => dispatch('loadTvl', farm)))
      // console.log("farms/loadData::analysis", new Date())
      commit('set', data)
      // console.log("farms/loadData::set", new Date())
      commit('setInitialLoadComplete', true)
    }
    catch (err) {
      // console.log("farms / loadData::err", err)
    }
    commit('setIsLoading', false)
  },
  loadBalances({}, farm) {
    try {
      const totalDeposits = utils.parseUnits(farm.totalDeposits, farm.depositToken.decimals || 18)
      const lpTokenSupply = farm.lpToken.supply
      const lpTokenDecimals = farm.lpToken.decimals || 18
      farm.token0.balance = totalDeposits
          .mul(utils.parseUnits(farm.token0.reserves, farm.token0.decimals))
          .div(utils.parseUnits(lpTokenSupply, lpTokenDecimals))
      farm.token1.balance = totalDeposits
          .mul(utils.parseUnits(farm.token1.reserves, farm.token1.decimals))
          .div(utils.parseUnits(lpTokenSupply, lpTokenDecimals))
    }
    catch (err) {
      // console.log("farms/loadBalances", farm.name, err, farm)
    }
    return farm
  },
  loadTvl({ rootGetters }, farm) {
    try {
        const token0Price = rootGetters['prices/priceForSymbol'](mapTokenSymbol(farm.token0.symbol))
        const token1Price = rootGetters['prices/priceForSymbol'](mapTokenSymbol(farm.token1.symbol))
        console.log(farm.name, token0Price, token1Price)

        let token0Tvl = BigNumber.from("0")
        let token1Tvl = BigNumber.from("0")

        let tvl = BigNumber.from("0")

        if (token0Price && token1Price) {
          token0Tvl = farm.token0.balance
            .mul("1000000000000000000")
            .div(BigNumber.from("10").pow(farm.token0.decimals))
            .mul(String(parseInt(token0Price * 100000)))
            .div("100000")

          token1Tvl = farm.token1.balance
            .mul("1000000000000000000")
            .div(BigNumber.from("10").pow(farm.token1.decimals))
            .mul(String(parseInt(token1Price * 100000)))
            .div("100000")

          tvl = token0Tvl.add(token1Tvl)
        }
        else if (token0Price) {
          token0Tvl = farm.token0.balance
            .mul("1000000000000000000")
            .div(BigNumber.from("10").pow(farm.token0.decimals))
            .mul(String(parseInt(token0Price * 100000)))
            .div("100000")
          tvl = token0Tvl.mul("2")
        }
        else if (token1Price) {
          token1Tvl = farm.token1.balance
            .mul("1000000000000000000")
            .div(BigNumber.from("10").pow(farm.token1.decimals))
            .mul(String(parseInt(token1Price * 100000)))
            .div("100000")
          tvl = token1Tvl.mul("2")
        }

        farm.tvl = utils.formatUnits(tvl)
        return farm
    }
    catch {
        try {
          const totalDeposits = utils.parseUnits(farm.totalDeposits, farm.depositToken.decimals || 18)
          const depositTokenPrice = rootGetters['prices/priceForSymbol'](mapTokenSymbol(farm.depositToken.symbol))
          if (depositTokenPrice) {
            let tvl = totalDeposits
                .mul("1000000000000000000")
                .div(BigNumber.from("10").pow(farm.depositToken.decimals || 18))
                .mul(String(parseInt(depositTokenPrice * 100000)))
                .div("100000")
            farm.tvl = utils.formatUnits(tvl)
            return farm
          }
        }
        catch (err) {
          // console.log("farms/loadTvl", farm.name, err)
        }
    }
    farm.tvl = null
    return farm
  },
  loadUserAssets({ rootGetters }, farm) {
    try {
      let receiptTokens = rootGetters['balances/balanceOfToken'](farm.address);
      let stakedDepositTokens, tvl;
      
      let totalDeposits = utils.parseUnits(farm.totalDeposits, farm.depositToken.decimals || 18)
      let totalSupply = utils.parseUnits(farm.totalSupply, 18);
      if (totalDeposits.mul(totalSupply).eq("0")) {
        stakedDepositTokens = receiptTokens;
        tvl = BigNumber.from("0");
      }
      else {
        stakedDepositTokens = receiptTokens.mul(totalDeposits).div(totalSupply);
        tvl = receiptTokens.mul(parseInt(farm.tvl)).div(totalSupply);
      }

      return {
        ...farm,
        accountBalances: {
          receiptTokens,
          stakedDepositTokens,
          tvl
        }
      }
    }
    catch (err) {
      console.log(err)
      return farm
    }
  },
  async loadFarmReinvests({ state, commit, getters, rootGetters }, address) {
    try {
      const chainId = rootGetters['accountModule/selectedChainId']
      const farm = getters.dataForFarm(address)
      const result = await getReinvests(chainId, address)

      const reinvests = result.map(row => {
        return {
          x: parseInt(row.blockTimestamp) * 1000,
          y: parseFloat(row.ratio)
        }
      })

      if (JSON.stringify(reinvests) != JSON.stringify(state.reinvestData[address])) {
        commit('setReinvestData', { key: address, value: reinvests }) // Ignore duplicates
      }

      const deposits = result.map(row => {
        return {
          x: parseInt(row.blockTimestamp) * 1000,
          y: parseFloat(utils.formatUnits(row.totalDeposits, farm.depositToken.decimals || 18))
        }
      })

      if (JSON.stringify(deposits) != JSON.stringify(state.depositsData[address])) {
        commit('setDepositsData', { key: address, value: deposits})
      }
    }
    catch {}
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}