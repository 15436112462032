<template>
  <section>
    <div v-if="isLiquidStakingSupported">
      <div class="container is-fullhd">
        <tool
          class="mt-3"
          title="Liquid Staking"
          subtitle="Earn native yield for securing the Avalanche network without lockups."
          img="liquidstaking3"
          :flipped="false"
        ></tool>
        <div class="columns is-multiline is-centered pb-6 mt-6">
          <div class="column pt-6 is-7-desktop is-6-tablet is-12-mobile">
            <p class="title has-text-centered-mobile">
              Yield Yak Stats
            </p>
            <div class="card">
              <div class="card-content">
                <p class="heading">APR</p>
                <p class="title is-size-3">
                    <span v-if="apr">{{ apr | percentage_2 }}</span>
                    <b-skeleton width="20%" size="is-large" v-else />
                </p>
                <p class="heading">Price per Share</p>
                <div class="title is-size-3">
                  <a @click="toggleInvertPrice" tag="a" v-if="pricePerShare">
                    <div class="columns is-mobile">
                      <div class="column is-narrow">
                        <balance
                            v-if="invertPrice"
                            :amount="one.toString()" 
                            :decimals="18"
                            :underlying="[zeroAddress]"
                            symbol="AVAX"
                        />
                        <balance
                            v-else
                            :amount="one.toString()" 
                            :decimals="18"
                            :underlying="[address]"
                            symbol="yyAVAX"
                        />
                      </div>
                      <div class="column is-narrow">
                        =
                      </div>
                      <div class="column is-narrow">
                        <balance
                            v-if="invertPrice"
                            :amount="pricePerShareInverted.toString()" 
                            :decimals="18"
                            :underlying="[address]"
                            symbol="yyAVAX"
                        />
                        <balance
                            v-else
                            :amount="pricePerShare.toString()" 
                            :decimals="18"
                            :underlying="[zeroAddress]"
                            symbol="AVAX"
                        />
                      </div>
                    </div>
                  </a>
                  <b-skeleton width="40%" size="is-large" v-else />
                </div>
                <p class="subtitle">
                  <span v-if="lastReinvest">Updated {{ lastReinvest | relativeTime }}</span>
                  <b-skeleton width="20%" size="is-small" v-else />
                </p>
                <div class="columns is-mobile">
                  <div class="column">
                    <p class="heading">Total Supply</p>
                    <p class="title is-size-3">
                        <balance
                            v-if="totalSupply"
                            :amount="totalSupply.toString()" 
                            :decimals="18"
                            :animationDuration="300"
                            :underlying="[address]"
                            symbol="yyAVAX"
                        />
                        <b-skeleton width="20%" size="is-large" v-else />
                    </p>
                  </div>
                  <div class="column has-text-right">
                    <p class="heading">Supply Cap</p>
                    <p class="title is-size-3">
                        <balance
                            :amount="supplyCap.toString()" 
                            :decimals="18"
                        />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column pt-6 is-4-desktop is-6-tablet is-12-mobile is-offset-1-desktop">
            <p class="title has-text-centered-mobile">
              yyAVAX
            </p>
            <div class="card">
              <div class="card-content">
                <p class="heading">Symbol</p>
                <p class="title is-size-3">
                  yyAVAX
                </p>
                <p class="heading">Market Cap</p>
                <p class="title is-size-3">
                  <span v-if="tvl">{{ tvl | usd }}</span>
                  <b-skeleton width="20%" size="is-large" v-else />
                </p>
                <p class="heading">Address</p>
                <p class="title is-size-3" v-if="address">
                  {{ address | shortEth }}
                  <ButtonCopy size="is-medium" :text="address" />
                </p>
                <p class="heading" v-if="account">My Wallet</p>
                <p class="title is-size-3" v-if="account">
                  <balance
                      v-if="tokenBalance"
                      :amount="tokenBalance.toString()" 
                      :decimals="18"
                      :animationDuration="300"
                      :underlying="[address]"
                      symbol="yyAVAX"
                  />
                  <b-skeleton width="20%" size="is-large" v-else />
                </p>
                <b-button 
                  type="is-success" expanded size="is-large"
                  tag="router-link" :to="{ name: 'Swap', query: { outputCurrency: address }}"
                >Get yyAVAX</b-button>
                <p class="is-size-6 pt-1 has-text-centered">You will be directed to Yak Swap. Yak Swap automatically gives you the best price for yyAVAX by minting or buying from market.</p>
              </div>
            </div>
          </div>
          <div class="column pt-6 is-12">
            <p class="title has-text-centered-mobile">
              Key Concepts
            </p>
          </div>
          <div class="column pt-6 is-5-desktop is-8-tablet is-12-mobile">
            <p class="title has-text-centered-mobile">
              Sustainable Yield
            </p>
            <p class="subtitle has-text-centered-mobile">
              Yield from liquid staking comes primarily from P-Chain reward emissions for securing the Avalanche network.
            </p>
            <div class="card">
              <div class="card-content">
                <p class="heading">P-Chain Account</p>
                <p class="title is-size-3" v-if="pChainAddress">
                  {{ pChainAddress | shortEth }}
                  <ButtonCopy size="is-medium" :text="pChainAddress" />
                </p>
                <b-button expanded icon-right="open-in-new" tag="a" href="https://explorer-xp.avax.network/address/avax1gfev5u7desmpc699x9yt683p09llf605e2z5nq" target="_blank">View on Avalanche Explorer</b-button>
              </div>
            </div>
          </div>
          <div class="column is-7-desktop is-4-tablet is-12-mobile"></div>
          <div class="column pt-6 is-5-desktop is-8-tablet is-12-mobile">
            <p class="title has-text-centered-mobile">
              Instant Exits
            </p>
            <p class="subtitle has-text-centered-mobile">
              The Withdrawal Pool facilitates swaps to AVAX. It serves as liquidity of last resort and may be replenished following each staking period.
            </p>
            <div class="card">
              <div class="card-content">
                <p class="heading">Balances</p>
                <p class="title is-size-3">
                  <balance
                      v-if="poolBalances"
                      :amount="poolBalances[0].toString()" 
                      :decimals="18"
                      :underlying="[zeroAddress]"
                      symbol="AVAX"
                  />
                  <b-skeleton width="20%" size="is-large" v-else />
                  <balance
                      v-if="poolBalances"
                      :amount="poolBalances[1].toString()" 
                      :decimals="18"
                      :underlying="[address]"
                      symbol="yyAVAX"
                  />
                  <b-skeleton width="20%" size="is-large" v-else />
                </p>
                <p class="heading">Address</p>
                <p class="title is-size-3" v-if="withdrawalPool">
                  {{ withdrawalPool | shortEth }}
                  <ButtonCopy size="is-medium" :text="withdrawalPool" />
                </p>
              </div>
            </div>
          </div>
          <div class="column is-7-desktop is-4-tablet is-12-mobile"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container is-fullhd mt-3 mb-6">
        <PageNotAvailable />
      </div>
    </div>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { utils, constants } from 'ethers'

  import Balance from "@/components/Balance";
  import ButtonDocs from "@/components/Buttons/Docs";
  import ButtonCopy from "@/components/Buttons/Copy";
  import DeployedAddresses from '@/contracts/Deployed';
  import PageNotAvailable from "@/components/PageNotAvailable";
  import Tool from "@/components/Home/Tool";

  import { ASSETS_URL } from '@/settings';

  const POLL_RATE = 60 * 1000;

  export default {
    components: {
      Balance,
      ButtonDocs,
      ButtonCopy,
      PageNotAvailable,
      Tool
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        chainId: 'accountModule/selectedChainId',
        tokenBalances: 'balances/balanceOfToken',
        apr: 'yyAvax/apr',
        pricePerShare: 'yyAvax/pricePerShare',
        totalSupply: 'yyAvax/totalSupply',
        tvl: 'yyAvax/tvl',
        lastReinvest: 'yyAvax/lastReinvest',
        poolBalances: 'yyAvax/poolBalances',
        isLiquidStakingSupported: "yyAvax/isSupported"
      }),
      tokenBalance: function () {
        return this.tokenBalances(this.address)
      },
      pricePerShareInverted: function () {
        return utils.parseUnits("1").mul(utils.parseUnits("1")).div(this.pricePerShare)
      },
      one: function () {
        return utils.parseUnits("1")
      },
      supplyCap: function () {
        return utils.parseUnits("100000")
      },
      zeroAddress: function () {
        return constants.AddressZero
      },
      address: function () {
        return DeployedAddresses[this.chainId].liquidStaking.address;
      },
      pChainAddress: function () {
        return "avax1gfev5u7desmpc699x9yt683p09llf605e2z5nq"
      },
      withdrawalPool: function () {
        return DeployedAddresses[this.chainId].liquidStaking.withdrawalPool;
      },
      mediumPostUrl: function () {
          return "https://medium.com/@yieldyak/yyavax-to-launch-soon-with-capped-100k-yyavax-token-opening-ceremony-and-rewards-for-lps-d5844ac24e9a"
      }
    },
    data() {
      return {
        polling: null,
        invertPrice: false,
        ASSETS_URL
      }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        await this.$store.dispatch('yyAvax/loadData')
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      toggleInvertPrice() {
        this.invertPrice = !this.invertPrice
      },
    },
  };
</script>