<template>
    <section class="card">
            <div class="card-content">
        <!-- <div class="columns is-multiline"> -->
            <!-- <div class="column is-12"> -->
                <p class="title">
                    Deposit
                </p>
                <div class="box is-dark px-0">
                <b-tabs size="is-medium" expanded>
                    <b-tab-item label="Deposit" headerClass="has-text-weight-semibold">
                        <div class="block p-4">
                            <b-field>
                                <template #label>
                                    <div class="columns is-mobile">
                                        <div class="column">
                                            <p class="heading">Deposit</p>
                                        </div>
                                        <div class="column">
                                            <p v-if="account && yyTokenBalance" class="has-text-right">
                                                <balance
                                                    :amount="yyTokenBalance"
                                                    :symbol="`yy${symbol}`"
                                                    :useText="true"
                                                    :animationDuration="100"
                                                />
                                            </p>
                                            <p v-else-if="account" class="has-text-right">
                                                Loading...
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <p class="control">
                                    <b-button
                                        type="is-dark" 
                                        :disabled="depositing"
                                    >
                                        <Tokens 
                                            :tokens="[yyTokenAddress]" 
                                            size="is-small" 
                                            alignment="is-left"
                                        />
                                    </b-button>
                                </p>
                                <b-input 
                                    v-model="depositAmount"
                                    type="is-dark"
                                    size="is-medium"
                                    :disabled="depositing"
                                    :placeholder="`0 yy${symbol}`"
                                    expanded
                                ></b-input>
                                <p class="control">
                                    <b-button type="is-dark" size="is-medium" @click="setMaxDeposit" :disabled="isMaxDisabled" expanded>Max</b-button>
                                </p>
                            </b-field>
                            <b-button
                                expanded
                                size="is-large"
                                type="is-primary"
                                :loading="depositing"
                                :disabled="isDepositDisabled"
                                @click="deposit"
                            >
                                Deposit
                            </b-button>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Withdraw" headerClass="has-text-weight-semibold">
                        <div class="block p-4">
                            <b-field>
                                <template #label>
                                    <div class="columns is-mobile">
                                        <div class="column">
                                            <p class="heading">Withdraw</p>
                                        </div>
                                        <div class="column">
                                            <p v-if="account && accountStakedBalance(symbol)" class="has-text-right">
                                                <balance
                                                    :amount="accountStakedBalance(symbol)"
                                                    :symbol="`yy${symbol}`"
                                                    :useText="true"
                                                    :animationDuration="100"
                                                />
                                            </p>
                                            <p v-else-if="account" class="has-text-right">
                                                Loading...
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <p class="control">
                                    <b-button
                                        type="is-dark" 
                                        :disabled="withdrawing"
                                    >
                                        <Tokens 
                                            :tokens="[yyTokenAddress]" 
                                            size="is-small" 
                                            alignment="is-left"
                                        />
                                    </b-button>
                                </p>
                                <b-input 
                                    v-model="withdrawAmount"
                                    type="is-dark"
                                    size="is-medium"
                                    :disabled="withdrawing"
                                    :placeholder="`0 yy${symbol}`"
                                    expanded
                                ></b-input>
                                <p class="control">
                                    <b-button type="is-dark" size="is-medium" @click="setMaxWithdraw" :disabled="isMaxDisabled" expanded>Max</b-button>
                                </p>
                            </b-field>
                            <b-button
                                expanded
                                size="is-large"
                                type="is-primary"
                                :loading="withdrawing"
                                :disabled="isWithdrawDisabled"
                                @click="withdraw"
                            >
                                Withdraw
                            </b-button>
                        </div>
                    </b-tab-item>
                </b-tabs>
                </div>
            <!-- </div> -->
        <!-- </div> -->
        </div>
    </section>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { utils } from 'ethers';

  import Balance from "@/components/Balance";
  import Tokens from "@/components/Tokens";

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
        Balance, Tokens
    },
    props: [
        'stakingAddress',
        'symbol',
        'yyTokenAddress'
    ],
    data() {
        return {
            depositAmount: null,
            withdrawAmount: null,
            depositing: false,
            withdrawing: false,
            polling: null
        }
    },
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            tokenBalances: 'balances/data',
            stakedBalance: 'yyPtp/stakedBalance',
            accountStakedBalance: 'yyPtp/accountStakedBalance'
        }),
        yyTokenBalance: function () {
            try {
                return this.tokenBalances[this.yyTokenAddress];
            }
            catch {}
        },
        isDepositDisabled: function () {
            return !this.account 
                || !this.depositAmount 
                || utils.parseUnits(this.depositAmount).eq("0")
                || utils.parseUnits(this.depositAmount).gt(this.yyTokenBalance)
        },
        isMaxDisabled: function () {
            return this.depositing || !this.account
        },
        isWithdrawDisabled: function () {
            return !this.account
                || !this.withdrawAmount 
                || utils.parseUnits(this.withdrawAmount).eq("0")
                || utils.parseUnits(this.withdrawAmount).gt(this.accountStakedBalance(this.symbol))
                // || !this.accountStakedBalance(this.symbol)
                // || this.accountStakedBalance(this.symbol).eq("0")
        }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
        async handlePolling() {
            const payload = {
                symbol: this.symbol
            }
            await Promise.all([
                this.$store.dispatch('yyPtp/loadData', payload),
                // this.$store.dispatch('yyPtp/loadStakingData', payload)
            ])

            this.polling = setTimeout(this.handlePolling, POLL_RATE);
        },
        setMaxDeposit() {
            this.depositAmount = utils.formatUnits(this.yyTokenBalance)
        },
        setMaxWithdraw() {
            this.withdrawAmount = utils.formatUnits(this.accountStakedBalance(this.symbol))
        },
        async deposit() {
            this.depositing = true;
            let result = false;
            try {
                let payload = {
                    address: this.stakingAddress,
                    amount: utils.parseUnits(this.depositAmount),
                }
                // result = await this.$store.dispatch('yyPtp/depositToStaking', payload);
                result = await this.$store.dispatch('deposit', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (result) {
                this.depositAmount = null;
                let payload = {
                    symbol: this.symbol
                }
                await Promise.all([
                    this.$store.dispatch('yyPtp/loadData', payload),
                    // this.$store.dispatch('yyPtp/loadStakingData', payload)
                ])
            }

            this.depositing = false;
        },
        async withdraw() {
            this.withdrawing = true;
            let result = false;
            try {
                let payload = {
                    address: this.stakingAddress,
                    amount: utils.parseUnits(this.withdrawAmount),
                }
                result = await this.$store.dispatch('withdraw', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (result) {
                this.withdrawAmount = null;
                let payload = {
                    symbol: this.symbol
                }
                await Promise.all([
                    this.$store.dispatch('yyPtp/loadData', payload),
                    // this.$store.dispatch('yyPtp/loadStakingData', payload)
                ])
            }

            this.withdrawing = false;
        },
    }
  };
</script>
