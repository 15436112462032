<template>
  <div class="block">
    <b-table
        :data="data"
        :loading="loading"
    >
        <b-table-column field="blockTimestamp" label="Time" v-slot="props">
            {{ props.row.blockTimestamp | relativeTime }}
        </b-table-column>
        <b-table-column field="event" label="Action" v-slot="props">
            {{ props.row.event || "" }}
        </b-table-column>
        <b-table-column field="args" label="Account" v-slot="props">
            {{ props.row.by.id | shortEth }}
        </b-table-column>
        <b-table-column field="transactionHash" label="Tx" v-slot="props">
            <a :href="explorerUrl(props.row.transactionHash)" target="_blank"
                >{{ props.row.transactionHash | veryShortEth }}&nbsp;&nearr;</a
            >
        </b-table-column>

        <template #empty>
            <div class="has-text-centered">None found</div>
        </template>
    </b-table>
  </div>
</template>
<script>
import { getExplorerDetails } from '@/services/NetworkHelpers'
import { getRecentFarmActivity } from "@/services/ReinvestSubgraph"

const POLL_RATE = 60 * 1000;

export default {
  components: {},
  data() {
    return {
      loading: false,
      polling: null,
      data: [],
    };
  },
  props: ['chainId', 'address'],
  mounted() {
    this.handlePolling();
  },
  beforeDestroy() {
    clearTimeout(this.polling);
  },
  methods: {
    async handlePolling() {
        await this.getActivity();
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
    },
    async getActivity() {
        this.loading = !this.polling && true; // only show for first load
        this.data = await getRecentFarmActivity(this.chainId, this.address)
        this.loading = false;
    },
    explorerUrl(txHash) {
        const baseUrl = getExplorerDetails(this.chainId).url
        return `${baseUrl}tx/${txHash}`
    },
  },
};
</script>