import { SnackbarProgrammatic as Snackbar } from 'buefy'

function showSnackbar(type, message) {
    Snackbar.open({
      duration: 10000,
      message,
      type,
      position: 'is-bottom-right',
      queue: false,
    })

}

function showSnackbarSuccess(title, content) {
    showSnackbar('is-success', `<p class="title is-size-5">${title}</p><p class="subtitle">${content}</p>`);
}

function showSnackbarDanger(title, content) {
    showSnackbar('is-danger', `<p class="title is-size-5">${title}</p><p class="subtitle">${content}</p>`);
}

export {
    showSnackbarSuccess,
    showSnackbarDanger
}