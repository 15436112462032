<template>
    <div class="card">
        <router-link
            :to="{ name: 'Swap', query: { outputCurrency: address == '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7' ? '0x0000000000000000000000000000000000000000' : address } }"
            class="with-hover">
        <div class="card-content fixed-height">
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <figure class="image is-64x64">
                            <img :src="ASSETS_URL + '/emoji/astronaut_right.png'" />
                        </figure>
                    </div>
                    <div class="level-item">
                        <div>
                            <div class="level is-mobile title is-size-5">
                                <div class="level-left mb-4">
                                    <div class="level-item">
                                        <span>
                                            Get
                                        </span>
                                    </div>
                                    <div class="level-item is-narrow">
                                        <Tokens
                                            :chainId="chainId"
                                            :tokens="underlying" 
                                            alignment="is-left"
                                        />
                                    </div>
                                    <div class="level-item">
                                        <span>
                                            {{ symbol }} on Yak Swap
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <p class="subtitle">
                                Best price with one-click
                            </p>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <b-icon icon="arrow-right" size="is-medium" class="has-text-grey" />
                    </div>
                </div>
            </div>
        </div>
        </router-link>
    </div>
</template>

<style scoped>
.fixed-height {
    height: 64px;
    padding-top: 0;
    padding-bottom: 0;
}
</style>

<script>
  import Tokens from '@/components/Tokens';
import { ASSETS_URL } from '@/settings';

  export default {
    components: {
      Tokens
    },
    data() {
      return {
        ASSETS_URL
      }
    },
    props: [
        'chainId',
        'platform',
        'underlying',
        'symbol',
    ],
    computed: {
        address: function() {
            return this.underlying[0]
        },
    },
  };
</script>
