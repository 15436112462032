<template>
    <div class="block p-4">
        <div class="level my-0">
            <div class="level-left">
                <div class="level-item">
                    <slot></slot>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-field>
                        <b-radio-button v-model="timeFilterSeconds" size="is-normal"
                            native-value="604800"
                        >7d</b-radio-button>
                        <b-radio-button v-model="timeFilterSeconds" size="is-normal"
                            native-value="2592000"
                        >30d</b-radio-button>
                        <b-radio-button v-model="timeFilterSeconds" size="is-normal"
                            native-value="7776000"
                        >90d</b-radio-button>
                    </b-field>
                </div>
            </div>
        </div>
        <apexchart height="350" width="100%" type="line" :options="options" :series="series"></apexchart>
    </div>
</template>
<script>
  import {mapGetters} from 'vuex';

  import VueApexCharts from 'vue-apexcharts';
  export default {
      components: {
          'apexchart': VueApexCharts
      },
      props: [
          'address'
      ],
      computed: {
        ...mapGetters({
            depositsDataForFarm: 'farms/depositsDataForFarm',
        }),
        series: function () {
            const type = "line"
            const name = "Deposits"
            const curve = "smooth"
            let data = []

            try {
                data = this.depositsDataForFarm(this.address)
                data = data.filter(r => parseInt(r.x) >= parseInt(new Date() / 1) - parseInt(this.timeFilterSeconds) * 1000)
            }
            catch {}

            return [{ name, data }]
        },
      },
      data() {
            return {
                timeFilterSeconds: "604800",
                options: {
                    chart: {
                        id: 'vuechart-deposits',
                        toolbar: {
                        show: false
                        },
                    },
                    colors: ["#00C864"],
                    grid: { borderColor: "#7A7A7A" },
                    stroke: { curve: 'smooth', width: 8 },
                    lagend: { show: true },
                    markers: { size: 4, strokeWidth: 1, strokeColors: '#F0F0F0', strokeOpacity: 0.9, },
                    tooltip: { 
                        // enabled: false 
                        theme: 'dark',
                        x: {
                        format: 'd MMM HH:mm'
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                        style: { colors: '#F0F0F0' },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: { colors: '#F0F0F0' },
                            formatter: val => this.$options.filters.number(val)
                        },
                        min: 0,
                        forceNiceScale: true,
                    },
                },
          }
      }
  }
</script>