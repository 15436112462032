<template>
  <p class="control">
    <b-button
      type="is-ghost"
      size="is-large"
      style="margin-left: -0.5rem;"
      class="px-2"
      @click="isModalShown = true"
    >
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <Tokens
              v-if="selectedToken.underlying"
              :chainId="chainId"
              :tokens="selectedToken.underlying" 
              size="is-small"
              alignment="is-left"
            />
            <Tokens
              v-else
              :chainId="chainId"
              :tokens="[selectedToken.address]" 
              size="is-small"
              alignment="is-left"
            />
          </div>
          <div class="level-item">
            <p class="is-size-3">
                {{ selectedToken.symbol }}
            </p>
          </div>
          <div class="level-item" v-if="!hideDropdown">
              <b-icon icon="chevron-down" size="is-normal" class="px-4"></b-icon>
          </div>
        </div>
      </div>
    </b-button>
    <b-modal v-model="isModalShown" :width="400" v-if="!hideDropdown">
      <div class="card">
        <div class="card-content">
          <div class="level is-mobile mb-2">
            <div class="level-left">
              <div class="level-item">
                <p class="title is-size-4">
                  Choose a Token
                </p>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-button 
                  type="is-ghost is-dark"
                  size="is-small"
                  @click="handleClose"
                >
                  <b-icon icon="close" />
                </b-button>
              </div>
            </div>
          </div>
          <b-field>
            <b-input
              v-model="searchTerm"
              type="search"
              icon="magnify"
              placeholder="Search Tokens"
              expanded
              :autofocus="true"
            ></b-input>
          </b-field>
        </div>
        <hr class="dropdown-divider" />
        <div class="card-content p-0 is-scrollable">
          <div
              v-for="token in sortedList"
              :value="token"
              :key="token.address"
              class="card"
          >
            <a class="with-hover" @click="setSelectedToken(token)">
              <div class="card-content py-2" style="border-radius: 0;">
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      <Tokens
                          :chainId="selectedChainId"
                          :tokens="[token.address]" 
                          size="is-small"
                          alignment="is-left"
                      />
                    </div>
                    <div class="level-item">
                      <div class="content has-text-left">
                        <p class="title is-size-5 has-text-weight-normal">
                          {{ token.symbol }}
                        </p>
                        <p class="subtitle is-size-6 has-text-grey" v-if="token.name">
                          {{ token.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="level-right" v-if="tokenBalance(token.address) && tokenBalance(token.address).gt('0')">
                    <div class="level-item">
                      <div class="content has-text-right">
                        <p class="title is-size-5 has-text-weight-normal">
                           {{ formattedBalance(token) }}
                        </p>
                        <p class="subtitle is-size-6 has-text-grey">
                            {{ usdValue(tokenBalance(token.address), token) | usd }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div v-if="isSearchEmpty">
            <div class="card">
              <div class="card-content">
                <p class="title is-size-5 has-text-warning">
                  No results found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </p>
</template>

<style scoped>
.is-scrollable {
  height: 50vh;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.is-scrollable::-webkit-scrollbar {
  display: none;
}
</style>

<script>
import {mapGetters} from 'vuex';
import { utils } from "ethers";

import Tokens from '@/components/Tokens';

export default {
  components: {
    Tokens,
  },
  props: [
    'chainId',
    'selectedToken',
    'hideDropdown'
  ],
  emits: [
    'updateSelectedToken',
  ],
  computed: {
    ...mapGetters({
      account: 'accountModule/account',
      selectedChainId: 'accountModule/selectedChainId',
      tokenBalance: 'balances/balanceOfToken',
      priceForSymbol: 'prices/priceForSymbol',
      usdValue: 'prices/usdValue',
      tokenList: 'swap/tokenList'
    }),
    filteredList: function () {
      let searchTerm = this.searchTerm;
      let list = this.tokenList;
      list = list.filter(row => !row.hideFromDisplay)

      if (searchTerm) {
        list = list.filter(row => {
          return row.symbol.toLowerCase().includes(searchTerm.toLowerCase())
            || row.name?.toLowerCase().includes(searchTerm.toLowerCase())
        })
      }

      return list;
    },
    sortedList: function () {
      const list = this.filteredList.sort((a, b) => {
        try {
          let fa = parseFloat(this.usdValue(this.tokenBalance(a.address), a)) || 0
          let fb = parseFloat(this.usdValue(this.tokenBalance(b.address), b)) || 0
  
          if (fa > fb) return -1
          else if (fa < fb) return 1
        }
        catch (err) {
          console.log("debug::sortedList", err)
        }
        return 0
      })
      return list
    },
    isSearchEmpty: function () {
      return this.filteredList.length == 0 && this.searchTerm !== ""
    }
  },
  data() {
    return {
      isModalShown: false,
      searchTerm: ""
    }
  },
  methods: {
    formattedBalance: function (token) {
      let stringValue = utils.formatUnits(this.tokenBalance(token.address), token.decimals);
      let formattedValue = this.$options.filters.number(stringValue);
      return `${formattedValue} ${token.symbol}`
    },
    setSelectedToken(val) {
      this.$emit('updateSelectedToken', val)
      this.handleClose()
    },
    handleClose() {
      this.isModalShown = false
      this.searchTerm = ""
    },
  }
};
</script>
