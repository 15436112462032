<template>
<div>
    <div class="card mb-2">
        <router-link :to="{ name: 'Detail', params: { address }}" :class="hoverable ? 'with-hover' : 'without-hover'">
            <div class="card-image card-image-offset" v-if="!hidePlatformImage">
                <figure class="image is-3by1">
                    <img v-if="farm.platform" :src="`${ASSETS_URL}/platforms/${farm.platform}/cover.jpeg`">
                </figure>
            </div>
            <div :class="`card-content ${hidePlatformImage && 'card-content-offset'}`">
                <div :class="`columns is-mobile ${hidePlatformImage && 'card-tokens-offset'} mb-0`">
                    <div class="column">
                        <Tokens 
                            :chainId="farm.chainId"
                            :tokens="farm.depositToken.underlying" 
                            size="is-medium" 
                            alignment="is-left"
                        />
                    </div>
                    <div class="column is-narrow mt-auto">
                        <p class="subtitle has-text-grey ">
                            {{ platformName }}
                        </p>
                    </div>
                </div>
                <div class="mb-1">
                    <p class="title is-size-2">
                        <span>{{ name }}</span>
                    </p>
                </div>
                <b-taglist v-if="farm && (farm.deprecated || farm.upgrade)">
                    <b-tag type="is-danger" size="is-medium" v-if="farm.deprecated">
                        Rewards Ended
                    </b-tag>
                    <b-tag type="is-success" size="is-medium" v-if="farm.upgrade">
                        Upgrade Available
                    </b-tag>
                    <b-tag type="is-dark" size="is-medium" v-if="farm.tags && farm.tags.includes('superfarm')">
                        Superfarm
                    </b-tag>
                    <b-tag type="is-success" size="is-medium" v-if="farm.tags && farm.tags.includes('eligibleForStaking')">
                        Stake
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('superfarm'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        Superfarm
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('autopool'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        Auto Pool
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloArbMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        ARB Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloWbtcMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        WBTC Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloGmxMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        GMX Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloWstethMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        wstETH Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloRethMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        rETH Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloJoeMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        JOE Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloJonesMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        JONES Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloPendleMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        PENDLE Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('siloGrailMarket'))">
                    <b-tag type="is-dark is-rounded" size="is-medium">
                        GRAIL Market
                    </b-tag>
                </b-taglist>
                <b-taglist attached v-else-if="farm && farm.tags && (farm.tags.includes('esgmx'))">
                    <b-tag type="is-success is-rounded" size="is-medium">
                        &check;
                        Boosted
                    </b-tag>
                </b-taglist>
                <b-taglist v-else>
                    <b-tag style="background-color: transparent" size="is-medium">
                        &nbsp;
                    </b-tag>
                </b-taglist>
                <div class="columns is-multiline is-mobile is-variable is-1">
                    <div class="column">
                        <div class="box is-dark">
                            <p class="heading">
                                <span>TVL</span>
                            </p>
                            <p class="title is-size-3" v-if="tvl && !farm.deprecated && isPriceDataLoadingComplete">
                                {{ tvl | usd_compact }}
                            </p>
                            <p class="title is-size-3 has-text-grey" v-else-if="tvl && isPriceDataLoadingComplete">
                                {{ tvl | usd_compact }}
                            </p>
                            <p class="title is-size-3" v-else-if="!tvl && isPriceDataLoadingComplete">
                                <Balance
                                    :amount="totalDeposits?.toString()" 
                                    :decimals="0"
                                    :symbol="depositToken.symbol"
                                    :underlying="depositToken.underlying"
                                    :isCompact="true"
                                />
                            </p>
                            <p class="title is-size-3 has-text-grey" v-else>
                                <b-skeleton width="40%" size="is-large" />
                            </p>
                        </div>
                    </div>
                    <div class="column">
                        <div :class="`box is-dark ${ !farm.deprecated && !statsOutdated && `has-border` }`" v-if="apyCompound">
                            <div class="level is-mobile m-0">
                                <div class="level-left">
                                    <div class="level-item">
                                        <p :class="`heading ${ !farm.deprecated && !statsOutdated && `is-success` }`">APY</p>
                                    </div>
                                </div>
                                <div class="level-right">
                                    <div class="level-item" v-if="statsOutdated && lastReinvest">
                                        <p class="heading">
                                            <b-icon icon="clock-outline" size="is-small" class="has-text-grey" />
                                            <span>{{ lastReinvest  | tinyTime }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p class="title is-size-3 has-text-grey" v-if="farm.deprecated && farm.upgrade">
                                Upgrade
                            </p>
                            <p class="title is-size-3 has-text-grey" v-else-if="farm.deprecated">
                                Ended
                            </p>
                            <p :class="`title is-size-3 ${ !statsOutdated && `is-success` }`" v-else-if="apyCompound > 99999">
                                {{ 99999 | percentageMax }}
                            </p>
                            <p :class="`title is-size-3 ${ !statsOutdated && `is-success` }`" v-else-if="apyCompound >= 100">
                                {{ apyCompound | percentage }}
                            </p>
                            <p :class="`title is-size-3 ${ !statsOutdated && `is-success` }`" v-else-if="apyCompound >= 10">
                                {{ apyCompound | percentage_1 }}
                            </p>
                            <p :class="`title is-size-3 ${ !statsOutdated && `is-success` }`" v-else>
                                {{ apyCompound | percentage_1 }}
                            </p>
                        </div>
                        <div class="box is-dark" v-else>
                            <p class="heading">
                                <span>APY</span>
                            </p>
                            <p class="title is-size-3 has-text-grey">
                                tbd
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
        <!-- <hr class="dropdown-divider mt-0" /> -->
        <div class="card-content py-3" v-if="!hideAccountSection && account">
            <div class="level is-mobile mb-1">
                <div class="level-left">
                    <div class="level-item">
                        <p class="subtitle is-size-5">
                            <span>My Account</span>
                        </p>
                    </div>
                </div>
                <div class="level-right" v-if="collapsible">
                    <div class="level-item">
                        <b-button size="is-small" type="is-ghost" @click="toggleExpanded">
                            <span v-if="expanded">Collapse</span>
                            <span v-else>Expand</span>
                            <span class="icon">
                                <i class="mdi mdi-chevron-down" v-if="!expanded"></i>
                                <i class="mdi mdi-chevron-up" v-else></i>
                            </span>
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="columns is-multiline is-mobile is-variable is-1">
                <div class="column">
                    <div class="box is-dark" v-if="accountStakedDepositTokens && accountStakedDepositTokens.gt('0') && accountTvl">
                        <p class="heading">
                            My TVL
                        </p>
                        <p class="title is-size-3">
                            <b-tooltip type="is-light" multilined append-to-body dashed>
                                {{ accountTvl | usd_compact }}
                                <template v-slot:content>
                                    <p v-html="userTVLToolTip" />
                                </template>
                            </b-tooltip>
                        </p>
                    </div>
                    <div class="box is-dark" v-else-if="accountStakedDepositTokens && accountStakedDepositTokens.gt('0') && !accountTvl">
                        <p class="heading">
                            My Deposits
                        </p>
                        <p class="title is-size-3">
                            <Balance
                                :amount="accountStakedDepositTokens.toString()" 
                                :decimals="depositToken.decimals"
                                :underlying="depositToken.underlying"
                                :isCompact="true"
                            />
                        </p>
                    </div>
                    <div class="box is-dark" v-else>
                        <p class="heading">My TVL</p>
                        <p class="title is-size-3 has-text-grey" v-if="accountStakedDepositTokens && accountStakedDepositTokens.eq('0')">
                            {{ 0 | usd_compact }}
                        </p>
                        <p class="title is-size-3 has-text-grey" v-else>
                            <b-skeleton width="40%" size="is-large" />
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="box is-dark">
                        <p class="heading">My Wallet</p>
                        <p class="title is-size-3">
                            <span v-if="depositTokenBalance && depositTokenBalance.gt('0')">
                                <Balance
                                    :amount="depositTokenBalance.toString()" 
                                    :decimals="depositToken.decimals"
                                    :underlying="depositToken.underlying"
                                    :isCompact="true"
                                />
                            </span>
                            <span class="has-text-grey" v-else-if="depositTokenBalance && !isTokenBalancesLoading">
                                0 {{ depositToken.symbol }}
                            </span>
                            <span class="has-text-grey" v-else>
                                <b-skeleton width="40%" size="is-large" />
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!hideAccountSection && account && !hideDetailActions && expanded">
        <DetailActions 
            :farm="farm" 
            :address="address" 
            size="is-medium"
            :isTiny="true"
        />
    </div>
</div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { utils, BigNumber } from "ethers";
  import { ASSETS_URL } from '@/settings';

  import Balance from "@/components/Balance";
  import Tokens from "@/components/Tokens";
  import DetailActions from "@/components/Farms/Actions";
  import { mapPlatformName } from '@/services/utils'

  export default {
    components: {
      Balance,
      Tokens,
      DetailActions
    },
    props: [
        'address',
        'depositToken',
        'lpToken', 
        'rewardToken',
        'name', 
        'token0',
        'token1',
        'totalSupply', 
        'totalDeposits', 
        'tvl',
        'farm',
        'hoverable',
        'collapsible',
        'hidePlatformImage',
        'hideAccountSection',
        'hideDetailActions'
    ],
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            accountInitialLoading: 'accountModule/initialLoading',
            apyForFarm: 'apy/apyForFarm',
            aprForFarm: 'apy/aprForFarm',
            lastReinvestForFarm: 'apy/lastReinvestForFarm',
            swapFeesForFarm: 'swapfees/swapFeesForFarm',
            tokenBalances: 'balances/data',
            accountFarmBalances: 'balances/farmBalances',
            activeDeposit: 'deposits/activeDeposit',
            initialLoading: 'balances/initialLoading',
            isPriceDataLoadingComplete: 'prices/initialLoadComplete'
        }),
        lastReinvest: function () {
            return this.lastReinvestForFarm(this.address)?.timestamp
        },
        statsOutdated: function () {
            if (!this.apyForFarm(this.address)) return true // no rewards apy
            try {
                let lastReinvest = this.lastReinvestForFarm(this.address).timestamp
                let threshold = 60 * 60 * 24 * 5
                let now = parseInt(new Date() / 1000)
                return lastReinvest + threshold < now // outdated stats
            }
            catch {}
        },
        apyCompound: function () {
            let swapFees = this.swapFeesForFarm(this.address) || 0
            let rewards = this.apyForFarm(this.address) || 0
            return swapFees + rewards
        },
        apySimple: function () {
            return this.aprForFarm(this.address)
        },
        accountStakedDepositTokens: function () {
            try {
                return this.accountFarmBalances(this.address).stakedDepositTokens
            }
            catch {
                return null //BigNumber.from("0")
            }
        },
        accountReceiptTokens: function () {
            try {
                return this.accountFarmBalances(this.address).receiptTokens
            }
            catch {
                return BigNumber.from("0")
            }
        },
        accountTvl: function () {
            try {
                return this.accountFarmBalances(this.address).tvl
            }
            catch {
                return BigNumber.from("0")
            }
        },
        platformName: function () {
            return mapPlatformName(this.farm.platform)
        },
        reinvestButtonToolTip: function () {
            return this.farm.reinvestRewardBips && `This farm has a ${ this.farm.reinvestRewardBips / 100 }% reinvest reward paid in ${this.farm.rewardToken.symbol}.`
        },
        simpleApyToolTip: function () {
            if (!this.apySimple) return "<b>Not enough data</b><br />Unable to calculate historical returns."
            try {
                return `<b>Historical APR</b><br />Based on recent performance and may change.<br /><br />${ this.$options.filters.percentage_2(this.apySimple / 365) } (1d)<br />${ this.$options.filters.percentage_2(this.apySimple / 52) } (1w)<br />${ this.$options.filters.percentage_2(this.apySimple / 12) } (1m)`
            }
            catch {
                return `<b>Historical APR</b><br />Based on recent performance and may change.`
            }
        },
        compoundApyToolTip: function () {
            if (!this.apySimple) return "<b>Not enough data</b><br />Unable to calculate APY."
            return `<b>Compound APY</b><br />Based on recent performance and may change.<br /><br />Calculated as recent daily returns compounded over one year.`
        },
        depositTokenBalance: function () {
            return this.tokenBalances && this.tokenBalances[this.depositToken.address] || BigNumber.from("0");
        },
        safePendingRewards: function () {
            return this.farm.pendingRewards && utils.parseUnits(this.farm.pendingRewards, this.rewardToken.decimals || 18) || BigNumber.from("0");
        },
        safeActiveDeposit: function () {
            return this.activeDeposit(this.address)
        },
        isTokenBalancesLoading: function () {
            return this.account && this.initialLoading
        },
        TVLToolTip: function () {
            let toolTip = `<b>Total Value Locked</b>`
            try {
                let underlyingFormatted = this.$options.filters.number(this.totalDeposits)
                toolTip = `<b>Total Deposits</b><br />${underlyingFormatted}&nbsp;${this.depositToken.symbol}`
            }
            catch {}

            try {
                let token0Formatted = this.$options.filters.number(utils.formatUnits(this.token0.balance, this.token0.decimals));
                let token1Formatted = this.$options.filters.number(utils.formatUnits(this.token1.balance, this.token1.decimals));
                
                toolTip += `<br /><br /><b>Underlying Assets</b><br />${token0Formatted}&nbsp;${this.token0.symbol}<br />${token1Formatted}&nbsp;${this.token1.symbol}`
            }
            catch {}
            return toolTip
        },
        userTVLToolTip: function () {
            let toolTip = `<b>Total Value Locked</b><br />${ this.$options.filters.usd(this.accountTvl) }`

            try {
                let underlyingFormatted = this.$options.filters.number(utils.formatUnits(this.accountStakedDepositTokens, this.depositToken.decimals || 18));
                toolTip += `<br /><br /><b>My Deposits</b><br />${underlyingFormatted}&nbsp;${this.depositToken.symbol}`
            }
            catch {}

            try {
                let userLpBalance = this.accountStakedDepositTokens;
                let token0Reserves = utils.parseUnits(this.token0.reserves, this.token0.decimals);
                let token1Reserves = utils.parseUnits(this.token1.reserves, this.token1.decimals);
                let lpTokenSupply = utils.parseUnits(this.lpToken.supply);

                let userToken0Balance = userLpBalance.mul(token0Reserves).div(lpTokenSupply);
                let userToken1Balance = userLpBalance.mul(token1Reserves).div(lpTokenSupply);

                let token0Formatted = this.$options.filters.number(utils.formatUnits(userToken0Balance, this.token0.decimals));
                let token1Formatted = this.$options.filters.number(utils.formatUnits(userToken1Balance, this.token1.decimals));
                
                toolTip += `<br /><br /><b>Underlying Assets</b><br />${token0Formatted}&nbsp;${this.token0.symbol}<br />${token1Formatted}&nbsp;${this.token1.symbol}`
            }
            catch {}

            return toolTip;
        },
    },
    data() {
      return {
        depositAmount: null,
        withdrawAmount: null,
        withdrawPercent: null,
        isDepositLoading: false,
        isReinvestLoading: false,
        isWithdrawLoading: false,
        expanded: !this.collapsible,
        ASSETS_URL
      };
    },
    watch: {
        withdrawPercent: function(val) {
            if (val) {
                this.withdrawAmount = utils.formatUnits(
                    this.accountStakedDepositTokens.mul(val).div("100"),
                    this.depositToken.decimals
                );
            }
            else {
                this.withdrawAmount = null
            }
        }
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
        setDepositAmount(percentOfBalance) {
            this.depositAmount = utils.formatUnits(
                this.depositTokenBalance.mul(percentOfBalance).div("100"),
                this.depositToken.decimals
            );
        },
        async withdrawAll() {
            this.setWithdrawPercent(100);
            await this.withdrawPercentage();
        },
        setWithdrawPercent(percentOfBalance) {
            this.withdrawPercent = percentOfBalance;
        },
        async deposit(amount) {
            this.isDepositLoading = true;
            let depositResult = false;
            try {
                let payload = {
                    address: this.address,
                    amount: utils.parseUnits(amount.toString(), this.depositToken.decimals),
                }
                depositResult = await this.$store.dispatch('deposit', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (depositResult) {
                this.depositAmount = null;
                await this.$store.dispatch('balances/loadData', [this.address, this.depositToken.address])
                this.$store.dispatch('deposits/loadData')
            }
            this.isDepositLoading = false;
        },
        async reinvest() {
            this.isReinvestLoading = true;
            let reinvestResult = false;
            try {
                reinvestResult = await this.$store.dispatch('reinvest', this.address);
            }
            catch (err) {
                console.log(err);
            }
            if (reinvestResult) {
                await this.$store.dispatch('balances/loadData', [this.address, this.rewardToken.address])
            }
            this.isReinvestLoading = false;
        },
        async withdraw() {

            // console.log(this.withdrawPercent)

            // console.log(this.withdrawAmount)
            // console.log(this.totalSupply)
            // console.log(this.totalDeposits)
            // let receiptTokensToWithdraw = this.withdrawAmount.div(this.accountStakedDepositTokens)

            let percentageToWithdraw = this.withdrawAmount / this.accountStakedDepositTokens

            let receiptTokensToWithdraw = this.withdrawAmount * this.totalSupply / this.totalDeposits;
            let tmp = utils.parseUnits(receiptTokensToWithdraw.toString())
            console.log("withdraw", 
                percentageToWithdraw,
                this.withdrawAmount.toString(),
                this.accountStakedDepositTokens.toString(),
                receiptTokensToWithdraw.toString(),
                tmp.toString(),
                this.accountReceiptTokens.toString()
            );
        },
        async withdrawPercentage() {
            let receiptTokensToWithdraw = this.accountReceiptTokens.mul(this.withdrawPercent).div("100");
            if (receiptTokensToWithdraw.eq("0")) return;

            this.isWithdrawLoading = true;
            let withdrawResult = false;

            try {
                let payload = {
                    address: this.address,
                    amount: receiptTokensToWithdraw,
                }
                withdrawResult = await this.$store.dispatch('withdraw', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (withdrawResult) {
                await this.$store.dispatch('balances/loadData', [this.address, this.depositToken.address])
                this.$store.dispatch('deposits/loadData')
                this.withdrawPercent = null;
            }
            this.isWithdrawLoading = false;
        },
    },
  };
</script>
