<template>
    <div class="card">
        <div class="card-content">
            <p class="title is-size-5">
                Trade Route
            </p>
            <p class="subtitle">
                Yak Swap did not find a route for your trade
            </p>
            <div class="box is-dark is-outlined">
                <div class="level is-mobile" style="justify-content: center;">
                    <div class="level-item is-narrow">
                        <Tokens 
                            :tokens="[fromTokenAddress]"
                            size="is-normal"
                        />
                    </div>
                    <div class="level-item is-narrow">
                        <b-icon icon="cancel" size="is-medium" />
                    </div>
                    <div class="level-item is-narrow">
                        <Tokens 
                            :tokens="[toTokenAddress]"
                            size="is-normal"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tokens from "@/components/Tokens";

export default {
    components: {
        Tokens
    },
    props: [
      'fromToken',
      'toToken'
    ],
    computed: {
      fromTokenAddress: function() {
          return this.fromToken.address
      },
      toTokenAddress: function() {
          return this.toToken.address
      }
    },
};
</script>