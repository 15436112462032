<template>
    <b-button
        tag="a"
        :expanded="expanded"
        :href="explorerUrl"
        target="_blank"
    >
        <div class="level is-mobile">
            <div class="level-item">
                <figure class="image is-24x24 mx-0">
                    <img class="is-rounded" width="24" height="24" :src="explorerSrc" :alt="explorerName" />
                </figure>
            </div>
            <div class="level-item">
                <span>
                    View on {{ explorerName }}
                </span>
            </div>
        </div>
    </b-button>
</template>

<script>
  import { ASSETS_URL } from '@/settings';
  import { getExplorerDetails } from '@/services/NetworkHelpers'

  export default {
    props: [
        'chainId',
        'address',
        'expanded'
    ],
    data() {
      return {
        ASSETS_URL
      };
    },
    computed: {
        explorerUrl: function() {
            const baseUrl = getExplorerDetails(this.chainId).url
            return `${baseUrl}address/${this.address}`
        },
        explorerName: function() {
            return getExplorerDetails(this.chainId).name
        },
        explorerSrc: function() {
            // const name = getExplorerDetails(this.chainId).name
            return `${ASSETS_URL}/logos/${this.explorerName.toLowerCase()}.png`
        }
    }
  };
</script>
