<template>
  <div>
    <div class="container is-fullhd mt-6">
      <div class="columns is-multiline is-centered pb-6">
        <div class="column pt-3 is-12 has-text-centered">
          <p class="title is-success">
            YAK Bonds
          </p>
          <!-- <p class="subtitle is-size-3">
            Sold Out
          </p> -->
        </div>
        <div class="column pt-4 is-9-desktop is-12-mobile" v-for="bond in bonds" :key="bond.address">
          <bond :bond="bond" />
        </div>
        <div class="column pt-6 is-12 has-text-centered">
          <p class="subtitle is-size-3">
            My Bonds
          </p>
        </div>
        <div class="column pt-4 is-3-desktop is-12-mobile" v-for="bond in bonds" :key="'v' + bond.address">
          <bond-vesting :bond="bond" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Bond from "@/components/Bonds/Bond";
  import BondVesting from "@/components/Bonds/Vesting";
  import DeployedAddresses from '@/contracts/Deployed';

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
      Bond, BondVesting
    },
    computed: {
      ...mapGetters({
        chainId: 'accountModule/selectedChainId',
        tokenBalances: 'balances/data',
      }),
      bonds: function () {
        try {
          return DeployedAddresses[this.chainId].bonds
        }
        catch {}
      },
    },
    data() {
      return {
        polling: null
      }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        // console.log("Bonds/handlePolling", new Date())
        const yakTokenAddress = DeployedAddresses[this.chainId].yakToken;
        const depositTokenBalances = this.bonds.map(bond => bond.depositToken.address);
        depositTokenBalances.push(yakTokenAddress)
        await Promise.all([
          this.$store.dispatch('bonds/loadData'),
          this.$store.dispatch('balances/loadData', depositTokenBalances)
        ])
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      async goToStaking() {
        console.log("go to staking")
      },
    },
  };
</script>