<template>
    <section>
      <div class="container is-fullhd mt-3 mb-6" v-if="isFarmsSupported">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-button
              type="is-ghost"
              icon-left="arrow-left"
              @click="$router.go(-1)"
            >Back</b-button>
          </div>
          <div class="column is-7-desktop">
            <div class="columns is-multiline">
              <div class="column is-12 is-hidden-tablet">
                <FarmCard 
                  v-if="farm"
                  :address="farm.address"
                  :depositToken="farm.depositToken"
                  :lpToken="farm.lpToken"
                  :rewardToken="farm.rewardToken"
                  :name="farm.name"
                  :token0="farm.token0"
                  :token1="farm.token1"
                  :totalDeposits="farm.totalDeposits"
                  :totalSupply="farm.totalSupply"
                  :tvl="farm.tvl"
                  :farm="farm"
                  :hidePlatformImage="false"
                  :hideAccountSection="false"
                  :hideDetailActions="false"
                  :hoverable="false"
                  :collapsible="false"
                ></FarmCard>
              </div>
              <div class="column is-12 is-hidden-mobile">
                <FarmCard 
                  v-if="farm"
                  :address="farm.address"
                  :depositToken="farm.depositToken"
                  :deprecated="farm.deprecated"
                  :lpToken="farm.lpToken"
                  :rewardToken="farm.rewardToken"
                  :name="farm.name"
                  :reinvestRewardBips="farm.reinvestRewardBips"
                  :timelock="farm.timelock"
                  :token0="farm.token0"
                  :token1="farm.token1"
                  :totalDeposits="farm.totalDeposits"
                  :totalSupply="farm.totalSupply"
                  :pendingRewards="farm.pendingRewards"
                  :platform="farm.platform"
                  :tags="farm.tags"
                  :tvl="farm.tvl"
                  :farm="farm"
                  :hidePlatformImage="false"
                  :hideAccountSection="false"
                  :hideDetailActions="true"
                  :hoverable="false"
                  :collapsible="false"
                ></FarmCard>
              </div>
              <div class="column is-12">
                <div class="card px-0 py-2" v-if="farm && !farm.deprecated">
                  <b-tabs size="is-large">
                    <b-tab-item label="Total Deposits" headerClass="has-text-weight-semibold">
                      <ChartDeposits :address="address">
                        <p class="title" v-if="farm && farm.totalDeposits">
                          <balance
                              :chainId="farm.chainId"
                              :amount="farm.totalDeposits.toString()" 
                              :symbol="farm.depositToken.symbol"
                              :decimals="'0'"
                              :underlying="farm.depositToken.underlying"
                              :animationDuration="300"
                          />
                        </p>
                      </ChartDeposits>
                    </b-tab-item>
                    <b-tab-item label="Growth" headerClass="has-text-weight-semibold">
                        <ChartGrowth :address="address">
                          <p class="title">Deposit asset growth</p>
                        </ChartGrowth>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
              <div class="column is-12">
                <div class="card" v-if="farm">
                  <div class="card-content">
                    <p class="title" v-if="farm">
                      Strategy Info
                    </p>
                    <Messages
                      v-if="farm"
                      :deprecated="farm.deprecated" 
                      :platform="farm.platform" 
                      :tags="farm.tags"
                      :upgrade="farm.upgrade"
                    />
                    <StrategyInfo 
                      v-if="farm"
                      :chainId="farm.chainId"
                      :address="address" 
                      :depositToken="farm.depositToken" 
                      :platform="farm.platform"
                      :tags="farm.tags" 
                    />
                    <PlatformInfo 
                      v-if="farm"
                      :platform="farm.platform"
                    />
                    <RecentPerformance
                      v-if="farm"
                      :deprecated="farm.deprecated"
                      :apr="apr"
                      :apy="apy"
                      :apyCompound="apyCompound"
                      :depositTokenSymbol="depositToken?.symbol"
                      :swapFees="swapFees"
                    />
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="card">
                  <div class="card-content content mb-0">
                    <p class="title">Recent Activity</p>
                  </div>
                  <div class="card-content pt-0 px-0">
                    <FarmActivity :chainId="farm.chainId" :address="address"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-5-desktop is-hidden-mobile">
            <div class="columns is-multiline">
              <div class="column is-12">
                <DetailActions 
                  :farm="farm" 
                  :address="address" 
                  size="is-large"
                  :showInfoTab="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="container is-fullhd mt-3 mb-6">
          <PageNotAvailable />
        </div>
      </div>
    </section>
</template>
<script>
  import {mapGetters} from 'vuex';

  import VueApexCharts from 'vue-apexcharts';
  import FarmActivity from '@/components/Detail/Activity';
  import FarmCard from '@/components/Farms/Card';
  import Balance from '@/components/Balance';
  import ButtonDocs from '@/components/Buttons/Docs';
  import Messages from "@/components/Detail/Messages";
  import StrategyInfo from "@/components/Detail/StrategyInfo";
  import PageNotAvailable from "@/components/PageNotAvailable";
  import PlatformInfo from "@/components/Detail/PlatformInfo";
  import RecentPerformance from "@/components/Detail/RecentPerformance";
  import DetailActions from "@/components/Farms/Actions";
  import ChartGrowth from "@/components/Detail/ChartGrowth";
  import ChartDeposits from "@/components/Detail/ChartDeposits";
  import { mapPlatformName } from '@/services/utils'

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
      Balance,
      ButtonDocs,
      FarmActivity,
      FarmCard, 
      Messages,
      StrategyInfo,
      PageNotAvailable,
      PlatformInfo,
      RecentPerformance,
      DetailActions,
      ChartDeposits,
      ChartGrowth,
      'apexchart': VueApexCharts,
    },
    props: [
      'from',
      'to',
      'address'
    ],
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        aprForFarm: 'apy/aprForFarm',
        apyForFarm: 'apy/apyForFarm',
        swapFeesForFarm: 'swapfees/swapFeesForFarm',
        dataForFarm: 'farms/dataForFarm',
        reinvestDataForFarm: 'farms/reinvestDataForFarm',
        depositsDataForFarm: 'farms/depositsDataForFarm',
        initialLoadComplete: 'farms/initialLoadComplete',
        // similarFarms: 'farms/similarFarms'
        isFarmsSupported: "farms/isSupported"
      }),
      swapFees: function () {
        return this.swapFeesForFarm(this.address)
      },
      apyCompound: function () {
          let swapFees = this.swapFees || 0
          let rewards = this.apyForFarm(this.address) || 0
          return swapFees + rewards
      },
      apy: function () {
          return this.apyForFarm(this.address)
      },
      apr: function () {
          return this.aprForFarm(this.address)
      },
      platformName: function () {
          return mapPlatformName(this.farm.platform)
      }
    },
    data() {
      return {
        polling: null,
        farm: null,
        collapses: [
          { title: "Info", isOpen: true },
          { title: "How to Deposit", isOpen: true },
          { title: "How to Withdraw", isOpen: false },
          { title: "Performance", isOpen: true },
          { title: "Charts", isOpen: true },
          { title: "Recent Activity", isOpen: true }
        ],
      }
    },
    beforeMount() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    watch: {
      account: function() {
        this.updateUserTokenBalances();
      },
      address: function () {
        if (this.address !== this.farm.address) {
          this.farm = null;
          this.lookupFarms()
        }
      }
    },
    methods: {
      async handlePolling() {
        // console.log("Detail/handlePolling", this.farm?.name, new Date())
        this.lookupFarms()
        await this.$store.dispatch('farms/loadFarmReinvests', this.address)
        await this.updateUserTokenBalances();
        await this.$store.dispatch('farms/loadData')
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      async updateUserTokenBalances() {
        await this.$store.dispatch('balances/loadData', [this.farm?.address, this.farm?.depositToken?.address])
      },
      lookupFarms() {
        if (this.address && !this.farm) {
          this.farm = this.dataForFarm(this.address)
        }
      }
    },
  };
</script>