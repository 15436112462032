import { request, gql } from 'graphql-request'

const GRAPH_ENDPOINT = "https://api.thegraph.com/subgraphs/name/yieldyak/yak-arc";

async function getLeaderboard(limit = 3, skip = 0) {
    const query = gql`
    {
        distributors(first: ${limit}, skip: ${skip}, orderBy:amount, orderDirection:desc) {
          id
          amount
          count
        }
    }
    `
    
    let data = await request(GRAPH_ENDPOINT, query);
    try {
        return data.distributors
    }
    catch (err) {
        console.log(err);
        throw("returns::getDistributions Could not fetch data");
    }
}

async function getDistributions(limit = 30, skip = 0) {
    const query = gql`
    {
        distributions(first: ${limit}, skip: ${skip}, orderBy:blockNumber, orderDirection:desc) {
          id
          by {
              id
          }
          amount
          usdValue
          blockTimestamp
          txHash
          payments {
            to {
              id
            }
            amount
          }
        }
    }
    `
    
    let data = await request(GRAPH_ENDPOINT, query);
    try {
        return data.distributions
    }
    catch (err) {
        console.log(err);
        throw("returns::getDistributions Could not fetch data");
    }
}

export {
    getDistributions, getLeaderboard
}