<template>
  <div class="columns is-justify-content-space-around my-6">
    <div class="column is-narrow has-text-centered-mobile">
      <p class="heading">Total Value Locked</p>
      <p class="title" v-if="tvl">{{ tvl | usd }}</p>
      <b-skeleton width="60%" size="is-large" v-else></b-skeleton>
    </div>
    <div class="column is-narrow has-text-centered-mobile">
      <p class="heading">Active Pools</p>
      <p class="title" v-if="farms">{{ farms }}</p>
      <b-skeleton width="60%" size="is-large" v-else></b-skeleton>
    </div>
    <!-- <div class="column is-narrow has-text-centered-mobile">
      <p class="heading">Weekly Revenue</p>
      <p class="title" v-if="revenue">{{revenue | usd_compact}} <span class="kpi-symbol">USD</span></p>
      <b-skeleton width="60%" size="is-large" v-else></b-skeleton>
    </div> -->
    <div class="column is-narrow has-text-centered-mobile">
      <p class="heading">YAK Staked</p>
      <p class="title" v-if="staked">{{ staked | percentage }}</p>
      <b-skeleton width="60%" size="is-large" v-else></b-skeleton>
    </div>
  </div>
</template>
<style scoped>
.heading {
  font-size: 1rem !important;
}

.title {
  font-weight: 600 !important;
  font-size: 2.2rem;
}
</style>
<script>
export default {
    props: [
        'tvl',
        'farms',
        'revenue',
        'staked'
    ],
};
</script>