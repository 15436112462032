<template>
    <div class="box has-border-top">
        <div class="columns">
            <div class="column">
                <div class="box is-dark">
                    <p class="heading">You give</p>
                    <p class="title">
                    <a :href="depositUrl(bond)" target="_blank">
                        {{ bond.depositToken.symbol }} {{ bond.name }}&nbsp;&nearr;
                    </a>
                    </p>
                    <p class="heading">Deposit Amount</p>
                    <b-field class="outlined">
                        <p class="control">
                            <b-button
                                type="is-dark" 
                                :disabled="depositing"
                            >
                                <div class="level is-mobile">
                                    <div class="level-left">
                                        <div class="level-item">
                                            <Tokens 
                                                :tokens="bond.depositToken.underlying" 
                                                size="is-small" 
                                                alignment="is-left"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </b-button>
                        </p>
                        <b-input 
                            v-model="depositAmount" 
                            size="is-medium"
                            :disabled="depositing"
                            :placeholder="`0 ${bond.depositToken.symbol}`"
                            expanded
                        ></b-input>
                        <p class="control">
                            <b-button type="is-dark" size="is-medium" @click="setMaxDeposit" :disabled="isMaxDisabled" expanded>Max</b-button>
                        </p>
                    </b-field>
                    <b-button
                        expanded
                        size="is-large"
                        type="is-primary"
                        :loading="depositing"
                        :disabled="isDepositDisabled || isSoldOut"
                        @click="deposit"
                    >
                        {{ isSoldOut ? "Sold Out" : "Bond" }}
                    </b-button>
                </div>
            </div>
            <div class="column">
            <div class="box is-dark">
                <p class="heading">You get</p>
                <p class="title" v-if="!account || !amountToReceive">
                    {{ bond.rewardToken.symbol }}
                </p>
                <p :class="discount && discount.gt('0') ? 'title is-success' : 'title'" v-else-if="amountToReceive && amountToReceive.gt('0')">
                    <balance
                        :amount="amountToReceive"
                        :symbol="bond.rewardToken.symbol"
                        :useText="true"
                        :animationDuration="100"
                    />
                </p>
                <p class="title has-text-grey" v-else>
                    0 {{ bond.rewardToken.symbol }}
                </p>
                <p class="heading">Discount</p>
                <p class="title is-success" v-if="discount && discount.gt('0')">+{{ discount | fromWei | percentage_2 }}</p>
                <p class="title has-text-warning" v-else-if="discount">{{ discount | fromWei | percentage_2 }}</p>
                <p class="title has-text-grey" v-else>n/a</p>
                <p class="heading">Vesting</p>
                <p class="title">7 days</p>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { utils } from 'ethers';
  import { getMoreDepositUrl } from '@/services/utils';

  import Balance from "@/components/Balance";
  import Tokens from "@/components/Tokens";

  import DeployedAddresses from '@/contracts/Deployed';

  const POLL_RATE = 5 * 1000;

  export default {
    components: {
        Balance, Tokens
    },
    props: [
        'bond'
    ],
    data() {
        return {
            depositAmount: null,
            amountToReceive: null,
            depositing: false,
            polling: null
        }
    },
    watch: {
        depositAmount: async function(val) {
            this.handlePolling()
        }
    },
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            chainId: 'accountModule/selectedChainId',
            tokenBalances: 'balances/data',
            maxPayout: 'bonds/maxPayout',
            trueBondPriceFor: 'bonds/trueBondPriceFor',
            discountFor: 'bonds/discountFor',
            initialLoading: 'bonds/initialLoading'
        }),
        yakBalance: function () {
            try {
                const yakTokenAddress = DeployedAddresses[this.chainId].yakToken;
                return this.tokenBalances[yakTokenAddress];
            }
            catch {}
        },
        isDepositDisabled: function () {
            return !this.account 
                || this.initialLoading
                || !this.depositAmount 
                || !this.discount
                || this.discount.lt('0')
                || utils.parseUnits(this.depositAmount, this.bond.depositToken.decimals || 18).eq("0")
                || utils.parseUnits(this.depositAmount, this.bond.depositToken.decimals || 18).gt(this.tokenBalances[this.bond.depositToken.address])
                || utils.parseUnits(this.depositAmount, this.bond.depositToken.decimals || 18).gt(this.maxPayout(this.bond.address))
        },
        isMaxDisabled: function () {
            return this.depositing || this.isSoldOut || this.initialLoading
        },
        isSoldOut: function () {
            const maxPayout = this.maxPayout(this.bond.address)
            return maxPayout && maxPayout.eq("0")
        },
        trueBondPrice: function () {
            return this.trueBondPriceFor(this.bond.address)
        },
        discount: function () {
            return this.discountFor(this.bond.address)?.mul("100")
        }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
        async handlePolling() {
            await this.updateAmountToReceive()
            this.polling = setTimeout(this.handlePolling, POLL_RATE);
        },
        async updateAmountToReceive () {
            if (!this.depositAmount) {
                this.amountToReceive = null;
                return;
            }

            try {
                let payload = {
                    address: this.bond.address,
                    amount: utils.parseUnits(this.depositAmount, this.bond.depositToken.decimals || 18),
                }
                let result = await this.$store.dispatch('bonds/payoutFor', payload);
                this.amountToReceive = result
            }
            catch (err) { console.log(err) }
        },
        depositUrl() {
            return getMoreDepositUrl(this.bond.platform, this.bond.depositToken.symbol, this.bond.depositToken.address, this.bond.depositToken.underlying)
        },
        setMaxDeposit() {
            // min of user balance and available
            const depositTokenBalance = this.tokenBalances[this.bond.depositToken.address]
            const maxPayout = this.maxPayout(this.bond.address)
            if (depositTokenBalance.lt(maxPayout)) {
                this.depositAmount = utils.formatUnits(depositTokenBalance, this.bond.depositToken.decimals || 18)
            }
            else {
                this.depositAmount = utils.formatUnits(maxPayout)
            }
        },
        async deposit() {
            this.depositing = true;
            let depositResult = false;
            try {
                let payload = {
                    address: this.bond.address,
                    depositTokenAddress: this.bond.depositToken.address,
                    amount: utils.parseUnits(this.depositAmount, this.bond.depositToken.decimals || 18),
                    maxPrice: this.trueBondPrice
                }
                depositResult = await this.$store.dispatch('bonds/deposit', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (depositResult) {
                this.depositAmount = null;
                await this.$store.dispatch('bonds/loadData')
            }

            this.depositing = false;
        },
    }
  };
</script>
