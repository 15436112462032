<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container is-fullhd">
                <p class="title is-size-2 has-text-centered-mobile mb-6">
                    Yield Yak Community
                </p>
                <div class="columns is-multiline">
                    <div class="column is-3-desktop is-6-tablet">
                        <CommunityButton
                            title="twitter"
                            subtitle="25,000+ followers"
                            href="https://x.com/yieldyak_"
                        ></CommunityButton>
                    </div>
                    <div class="column is-3-desktop is-6-tablet">
                        <CommunityButton
                            title="discord"
                            subtitle="5,000+ members"
                            href="https://discord.gg/JBbPKzX4rg"
                        ></CommunityButton>
                    </div>
                    <div class="column is-3-desktop is-6-tablet">
                        <CommunityButton
                            title="telegram"
                            subtitle="3,000+ members"
                            href="https://t.me/yieldyak"
                        ></CommunityButton>
                    </div>
                    <div class="column is-3-desktop is-6-tablet">
                        <CommunityButton
                            title="debank"
                            subtitle="9,000+ followers"
                            href="https://debank.com/official/Yield_Yak"
                        ></CommunityButton>
                    </div>
                    <!-- <div class="column is-3-desktop is-6-tablet">
                        <CommunityButton
                            title="medium"
                            subtitle="announcements"
                            href="https://yieldyak.medium.com/"
                        ></CommunityButton>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import CommunityButton from "@/components/Buttons/Community";

export default {
  components: {
    CommunityButton,
  },
};
</script>