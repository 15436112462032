<template>
  <div>
    <div class="level is-mobile mb-0">
      <div class="level-left">
        <div class="level-item">
          <TokenSelector
            v-bind:selectedToken="selectedToken"
            v-on:updateSelectedToken="$emit('updateSelectedToken', $event)"
            :chainId="chainId"
            :hideDropdown="hideDropdown"
          />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <p v-if="account && !initialLoading" class="has-text-right">
            <Balance
              align="is-right"
              :amount="selectedTokenBalance.toString()"
              :useText="true"
              :decimals="selectedToken.decimals"
              label="Balance:"
            />
          </p>
          <p v-else-if="account" class="has-text-right">Loading...</p>
        </div>
      </div>
    </div>
    <b-field>
      <b-input
        v-bind:value="amountIn"
        v-on:input="onAmountChanged"
        size="is-large"
        placeholder="0.0"
        expanded
        :readonly="readonly"
      ></b-input>
      <!-- <p v-if="usdValue(amountInBn, selectedToken)">
                {{ usdValue(amountInBn, selectedToken) | usd }}
            </p>
            <p v-else>$?</p> -->
    </b-field>
    <div class="columns is-mobile is-variable is-1" v-if="!readonly">
      <p class="column control is-3">
        <b-button
          outlined
          size="is-small"
          @click="setAmountInFromBalancePercent(25)"
          :disabled="isMaxDisabled"
          expanded
          >{{ 25 | percentage }}</b-button
        >
      </p>
      <p class="column control is-3">
        <b-button
          size="is-small"
          @click="setAmountInFromBalancePercent(50)"
          :disabled="isMaxDisabled"
          expanded
          >{{ 50 | percentage }}</b-button
        >
      </p>
      <p class="column control is-3">
        <b-button
          size="is-small"
          @click="setAmountInFromBalancePercent(75)"
          :disabled="isMaxDisabled"
          expanded
          >{{ 75 | percentage }}</b-button
        >
      </p>
      <p class="column control is-3">
        <b-button
          size="is-small"
          @click="setAmountInFromBalancePercent(100)"
          :disabled="isMaxDisabled"
          expanded
          >{{ 100 | percentage }}</b-button
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utils } from "ethers";

import Balance from "@/components/Balance";
import TokenSelector from "@/components/Swap/TokenSelector";

export default {
  components: {
    Balance,
    TokenSelector,
  },
  model: {
    prop: "selectedToken",
    event: "updateSelectedToken",
  },
  data: {
    debounce: null,
  },
  props: ["chainId", "amountIn", "selectedToken", "readonly", "hideDropdown"],
  emits: ["updateSelectedToken", "updateAmountIn"],
  computed: {
    ...mapGetters({
      selectedChainId: "accountModule/selectedChainId",
      account: "accountModule/account",
      tokenBalance: "balances/balanceOfToken",
      initialLoading: "balances/initialLoading",
      usdValue: "prices/usdValue",
    }),
    selectedTokenBalance: function () {
      return this.tokenBalance(this.selectedToken.address);
    },
    isMaxDisabled: function () {
      return !this.account || this.selectedTokenBalance?.eq("0");
    },
    // amountInBn: function () {
    //   try {
    //     return utils.parseUnits(this.amountIn);
    //   } catch {}
    // },
  },
  methods: {
    setAmountInFromBalancePercent(percent) {
      const amount = this.selectedTokenBalance.mul(percent).div("100");
      const fAmount = utils.formatUnits(amount, this.selectedToken.decimals);
      this.$emit("updateAmountIn", fAmount);
    },
    onAmountChanged(val) {
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.$emit("updateAmountIn", val);
      }, 300);
    },
  },
};
</script>
