<template>
    <div>
        <div class="mb-4" v-if="tags && tags.includes('eligibleForStaking')">
            <b-message type="is-success" has-icon icon="thumb-up" size="is-normal">
                <strong>Stake this Asset</strong><br />
                This asset is eligible for staking. After depositing, visit the <router-link :to="{ name: 'Stake' }">Stake page</router-link> to earn additional AVAX.
            </b-message>
        </div>
        <div class="mb-4" v-if="tags && tags.includes('experimental')">
            <b-message type="is-warning" has-icon icon="test-tube" size="is-normal">
                <strong>Before you Deposit</strong><br />
                This farm uses a new or experimental strategy. Use at your own risk.
            </b-message>
        </div>

        <div class="mb-4" v-if="tags && tags.includes('oldAsset')">
            <b-message type="is-warning" has-icon size="is-normal">
                <strong>Before you Deposit</strong><br />
                This farm uses old AB Bridge assets. The old assets are being deprecated across the ecosystem.
            </b-message>
        </div>
        <div class="mb-4" v-if="upgrade">
            <b-message type="is-success" has-icon icon="chevron-triple-up" size="is-normal">
                <strong>Upgrade Available</strong><br />
                This farm is getting an upgrade. Please move your funds to the <router-link :to="{ name: 'Detail', params: { address: upgrade }}">new version</router-link>. Deposits are safe.
            </b-message>
        </div>
        <div class="mb-4" v-else-if="deprecated && tags && tags.includes('depositsDisabled')">
            <b-message type="is-warning" has-icon size="is-normal">
                <strong>Deposits Disabled</strong><br />
                This farm is no longer accepting deposits.
            </b-message>
        </div>
        <div class="mb-4" v-else-if="deprecated && platform == 'blizz'">
            <b-message type="is-danger" has-icon icon="cash-remove" size="is-normal">
                <strong>Blizz Pools</strong><br />
                The underlying platform suffered an exploit. Join YY Telegram for more information.
            </b-message>
        </div>
        <div class="mb-4" v-else-if="deprecated && platform == 'vector'">
            <b-message type="is-danger" has-icon icon="cash-remove" size="is-normal">
                <strong>Vector Pools</strong><br />
                A security issue in the underlying-underlying protocol has paused deposits and withdrawals to this strategy. Existing deposits will be manually returned to users. No action is required.  For more information please visit our Community channels
            </b-message>
        </div>
        <div class="mb-4" v-else-if="deprecated && platform == 'echidna'">
            <b-message type="is-danger" has-icon icon="cash-remove" size="is-normal">
                <strong>Echidna Pools</strong><br />
                A security issue in the underlying-underlying protocol has paused deposits and withdrawals to this strategy. Existing deposits will be manually returned to users. No action is required.  For more information please visit our Community channels
            </b-message>
        </div>
        <div class="mb-4" v-else-if="deprecated && platform == 'platypus'">
            <b-message type="is-danger" has-icon icon="cash-remove" size="is-normal">
                <strong>Platypus Pools</strong><br />
                A security issue in the underlying protocol has paused deposits and withdrawals to this strategy. Existing deposits will be manually returned to users. No action is required.  For more information please visit our Community channels
            </b-message>
        </div>
        <div class="mb-4" v-else-if="deprecated">
            <b-message type="is-danger" has-icon icon="cash-remove" size="is-normal">
                <strong>Rewards Ended</strong><br />
                This farm is no longer earning rewards. Please remove your funds. Deposits are safe.
            </b-message>
        </div>
    </div>
</template>

<script>
  export default {
    props: [
        'deprecated',
        'platform',
        'tags',
        'upgrade'
    ]
  };
</script>
