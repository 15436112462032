<template>
      <a class="with-hover" :href="href" target="_blank">
        <div class="card mobile-width">
            <div class="card-content p-4">
                <div class="columns is-mobile">
                    <div class="column is-narrow">
                        <figure class="image is-64x64">
                            <img width="64" height="64" :src="srcForTitle" :alt="title" class="is-rounded" />
                        </figure>
                    </div>
                    <div style="margin: auto" class="column">
                        <div class="content">
                            <p style="white-space: nowrap;" class="title is-size-4 is-uppercase">{{title}}</p>
                            <p style="white-space: nowrap;" class="subtitle is-size-5 is-uppercase">{{subtitle}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </a>
</template>
<script>
import { ASSETS_URL } from '@/settings';

export default {
    props: [
        'title',
        'subtitle',
        'href'
    ],
    data() {
      return {
        ASSETS_URL
      };
    },
    computed: {
        srcForTitle: function() {
            return `${this.ASSETS_URL}/logos/${this.title}.png`
        }
    }
};
</script>