import { Contract, utils, BigNumber, constants } from 'ethers';
import Deployed from '@/contracts/Deployed';
import BalancesOfABI from '@/abis/BalancesOfHelper';
import Vue from 'vue';

const state = () => ({
    data: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  data: state => {
    return state.data
  },
  balanceOfNative: state => {
      try {
          return state.data[constants.AddressZero]
      }
      catch {
          return BigNumber.from("0")
      }
  },
  balanceOfToken: state => token => {
      try {
        return state.data[token]
      }
      catch {
        return BigNumber.from("0")
      }
  },
  farmBalances: (state, getters, rootState, rootGetters) => farmAddress => {
      try {
        let receiptTokens = getters.balanceOfToken(farmAddress);
        let farm = rootGetters['farms/dataForFarm'](farmAddress);
        let stakedDepositTokens, tvl;
        
        let totalDeposits = utils.parseUnits(farm.totalDeposits, farm.depositToken.decimals || 18)
        let totalSupply = utils.parseUnits(farm.totalSupply, 18);
        if (totalDeposits.mul(totalSupply).eq("0")) {
          stakedDepositTokens = receiptTokens;
          tvl = BigNumber.from("0");
        }
        else {
          stakedDepositTokens = receiptTokens.mul(totalDeposits).div(totalSupply);
          try {
            tvl = receiptTokens.mul(parseInt(farm.tvl)).div(totalSupply);
          }
          catch {}
        }
  
        return {
            receiptTokens,
            stakedDepositTokens,
            tvl
        }
      }
      catch {}
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  }
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("balances / setValue err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async loadData({ state, commit, rootGetters }, payload=null) {
    if (state.isLoading) {
      console.log('vuex/balances/loadData -- already loading')
      return;
    }
    commit('setIsLoading', true)
    let account = rootGetters['accountModule/account']
    let chainId = rootGetters['accountModule/selectedChainId']
    let provider = rootGetters['accountModule/provider']
    
    if (account && chainId && provider) {
      let helpers = Deployed[chainId].helpers
      let tokenList = rootGetters['swap/tokenList']
      let tokensToQuery = []

      if (payload) {
        tokensToQuery = payload
      }
      else  {
        if (tokenList) {
          tokensToQuery = tokensToQuery.concat(
            tokenList.map(token => token.address)
          );
        }

        let existingFarmList = rootGetters['farms/data']
        if (existingFarmList) {
          tokensToQuery = tokensToQuery.concat(
            existingFarmList.flatMap(farm => [farm.depositToken.address, farm.address])
          );
        }
      }
      
      tokensToQuery = [...new Set(tokensToQuery)]
      
      let balancesOfHelperContract = new Contract(helpers.balances, BalancesOfABI, provider);
      try {
        let balances = await balancesOfHelperContract.accountBalancesOf(account, tokensToQuery);
        for (let i = 0; i < tokensToQuery.length; i++) {
          commit('setValue', { key: tokensToQuery[i], value: balances[i] })
        }
      }
      catch (err) {
        console.log("could not load token balances", err);
      }
      commit('setInitialLoadComplete', true)
    }
    commit('setIsLoading', false)
  },
  clearData({ commit }) {
    commit('setInitialLoadComplete', false)
    commit('set', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}