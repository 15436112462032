<template>
    <section class="hero is-medium">
        <div class="hero-body">
            <div class="container is-fullhd">
                <div class="content">
                    <p class="title is-size-2 has-text-centered-mobile">
                        Most Popular on Yield Yak
                    </p>
                    <p class="subtitle has-text-centered-mobile" style="max-width: 720px;">
                        Yield Yak autocompounds farming rewards to earn more of your deposited assets. Follow the top pools by total deposits, or explore the huge selection of pools.
                    </p>
                </div>
                <div class="columns is-multiline is-variable">
                    <section v-for="farm in filteredFarmList" :key="farm.address" class="column is-3-desktop is-6-tablet is-12-mobile my-3">
                        <FarmCard 
                            :address="farm.address"
                            :depositToken="farm.depositToken"
                            :deprecated="farm.deprecated"
                            :lpToken="farm.lpToken"
                            :rewardToken="farm.rewardToken"
                            :name="farm.name"
                            :reinvestRewardBips="farm.reinvestRewardBips"
                            :timelock="farm.timelock"
                            :token0="farm.token0"
                            :token1="farm.token1"
                            :totalDeposits="farm.totalDeposits"
                            :totalSupply="farm.totalSupply"
                            :pendingRewards="farm.pendingRewards"
                            :platform="farm.platform"
                            :tags="farm.tags"
                            :tvl="farm.tvl"
                            :farm="farm"
                            :hoverable="true"
                            :collapsible="true"
                            :hidePlatformImage="true"
                            :hideAccountSection="true"
                        ></FarmCard>
                    </section>
                    <div class="column is-12 is-hidden-mobile">
                        <div class="buttons mt-6 is-justify-content-right">
                            <b-button size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'Farms' }">Explore Yak Pools</b-button>
                            <b-button size="is-extra-large" class="ml-2" tag="a" href="https://docs.yieldyak.com/for-farmers/pool" target="_blank" icon-right="open-in-new">Read the Docs</b-button>
                        </div>
                    </div>
                    <div class="column is-12 is-hidden-tablet is-hidden-desktop">
                        <div class="buttons mt-6">
                            <b-button expanded size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'Farms' }">Explore Yak Pools</b-button>
                            <b-button expanded size="is-extra-large" tag="a" href="https://docs.yieldyak.com/for-farmers/pool" target="_blank" icon-right="open-in-new">Read the Docs</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters} from 'vuex';

import FarmCard from '@/components/Farms/Card';

export default {
    components: {
        FarmCard
    },
    computed: {
        ...mapGetters({
            // account: 'accountModule/account',
            // farmList: 'farms/data',
            // accountHasDeprecatedFarms: 'farms/accountHasDeprecatedFarms',
            // isInitialLoadComplete: 'farms/initialLoadComplete',
            // balancesInitialLoading: 'balances/initialLoading',
            sortedFarmList: 'farms/sortedFarmList',
        }),
        filteredFarmList: function () {
            let filterObject = {
                tab: "explore",
                // platformFilter: this.platformFilter,
                // farmTypeFilter: this.farmTypeFilter.value,
                // searchTerm: this.searchTerm
            }
            return this.sortedFarmList("tvl", filterObject).slice(0,4);
        },
    }
};
</script>