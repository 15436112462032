<template>
    <div>
        <div v-if="isLpToken || isSpecialToken">
            <ExternalPanel
                :chainId="chainId"
                :platform="platform"
                :depositTokenAddress="depositTokenAddress"
                :underlying="underlying"
                :symbol="symbol"
            />
        </div>
        <div v-else>
            <YakSwapPanel
                :chainId="chainId"
                :platform="platform"
                :underlying="underlying"
                :symbol="symbol"
            />
        </div>
    </div>
</template>

<script>
  import { getMoreDepositUrl } from '@/services/utils';

  import Tokens from '@/components/Tokens';
  import YakSwapPanel from '@/components/Detail/YakSwapPanel';
  import ExternalPanel from '@/components/Detail/ExternalPanel';

  export default {
    components: {
      ExternalPanel,
      Tokens,
      YakSwapPanel
    },
    props: [
        'chainId',
        'customClass',
        'platform',
        'deprecated',
        'underlying',
        'depositTokenAddress',
        'symbol',
        'showUnderlying',
        'type',
        'expanded'
    ],
    computed: {
        getMoreDepositUrl: function() {
            return getMoreDepositUrl(this.chainId, this.platform, this.symbol, this.depositTokenAddress, this.underlying);
        },
        isLpToken: function () {
            return this.underlying.length > 1
        },
        isSpecialToken: function () {
            return ["aHYPE", "K3C", "BLP", "fsGLP", "S*USDC", "S*USDT", "jrLLP", "mzeLLP", "srLLP"].includes(this.symbol)
        },
    },
  };
</script>
