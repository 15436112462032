<template>
    <div class="card">
        <div class="card-image">
            <figure class="image is-3by1">
                <img :src="ASSETS_URL + '/banners/spooky.jpeg'" />
            </figure>
        </div>
        <div class="card-content">
            <p class="title has-text-centered">This page is not available</p>
        </div>
    </div>
</template>

<script>
import { ASSETS_URL } from '@/settings';

export default {
    data() {
        return {
        ASSETS_URL
        }
    }
};
</script>
