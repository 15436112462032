import { Contract, BigNumber } from 'ethers';
import Deployed from '@/contracts/Deployed';
import YakARCABI from '@/abis/YakARC';
import Vue from 'vue';
import { 
  getDistributions, getLeaderboard
} from '@/services/ArcSubgraph';

const state = () => ({
    data: {},
    balance: null,
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  data: state => {
    return state.data
  },
  distributions: state => {
    try {
      return state.data['distributions']
    }
    catch {
      return null
    }
  },
  leaderboard: state => {
    try {
      return state.data['leaderboard']
    }
    catch {
      return null
    }
  },
  balance: state => {
    try {
      return state.data['balance']
    }
    catch {
      return BigNumber.from("0")
    }
  },
  nextEpoch: state => {
    try {
      return state.data['nextEpoch']
    }
    catch {
      return BigNumber.from("0")
    }
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  }
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("balances / setValue err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async distribute({ state, commit, rootGetters }) {
    let signer = rootGetters['accountModule/signer']
    let chainId = rootGetters['accountModule/selectedChainId']
    if (signer && chainId) {
      let arcAddress = Deployed[chainId].arc
      let arcContract = new Contract(arcAddress, YakARCABI, signer);
      let tx = await arcContract.distribute();
      let txReceipt = await tx.wait();
      return txReceipt.status;
    }
    return false;
  },
  async loadData({ state, commit, rootGetters }, payload=null) {
    if (state.isLoading) {
      console.log('vuex/arc/loadData -- already loading')
      return;
    }
    commit('setIsLoading', true)
    let chainId = rootGetters['accountModule/selectedChainId']
    let provider = rootGetters['accountModule/provider']
    if (chainId && provider) {
      try {
        let arcAddress = Deployed[chainId].arc
        let arcContract = new Contract(arcAddress, YakARCABI, provider);
        try {
          let balance = await arcContract.currentBalance();
          commit('setValue', { key: 'balance', value: balance })
        }
        catch (err) {
          console.log("vuex/arc/loadData could not load current balance", err);
        }

        try {
          let nextEpoch = await arcContract.nextEpoch();
          commit('setValue', { key: 'nextEpoch', value: nextEpoch })
        }
        catch (err) {
          console.log("vuex/arc/loadData could not load next epoch", err);
        }
      }
      catch {}

      commit('setInitialLoadComplete', true)
    }

    try {
      let distributions = await getDistributions();
      commit('setValue', { key: 'distributions', value: distributions })
    } catch {}

    try {
      let leaderboard = await getLeaderboard();
      commit('setValue', { key: 'leaderboard', value: leaderboard })
    } catch {}

    commit('setIsLoading', false)
  },
  clearData({ commit }) {
        commit('setInitialLoadComplete', false)
        commit('set', {})
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}