<template>
    <div class="card">
        <div class="card-content">
            <p class="heading">Boosted TVL</p>
            <p class="title" v-if="tvl">
                {{ tvl | usd }}
            </p>
            <p class="title has-text-grey" v-else>
                <b-skeleton width="40%" size="is-large"></b-skeleton>
            </p>
            <p class="heading">Accrued ve{{ symbol }}</p>
            <p class="title" v-if="veBalanceFor(symbol)">
                <balance
                    :amount="veBalanceFor(symbol)"
                    :symbol="`ve${symbol}`"
                    :useText="true"
                    :animationDuration="100"
                    :decimalPlaces="0"
                    :underlying="[veTokenAddress]"
                />
            </p>
            <p class="title has-text-grey" v-else>
                <b-skeleton width="20%" size="is-large"></b-skeleton>
            </p>
            <p class="heading">Next ve{{ symbol }} Claim</p>
            <p class="title" v-if="veClaimableBalanceFor(symbol)">
                <balance
                    :amount="veClaimableBalanceFor(symbol)"
                    :symbol="`ve${symbol}`"
                    :useText="true"
                    :animationDuration="100"
                    :decimalPlaces="0"
                    :underlying="[veTokenAddress]"
                />
            </p>
            <p class="title has-text-grey" v-else>
                <b-skeleton width="20%" size="is-large"></b-skeleton>
            </p>
            <p class="heading">Underlying {{ symbol }}</p>
            <p class="title">
                <balance
                    v-if="underlyingBalanceFor(symbol)"
                    :amount="underlyingBalanceFor(symbol)"
                    :symbol="symbol"
                    :useText="true"
                    :animationDuration="100"
                    :decimalPlaces="0"
                    :underlying="[tokenAddress]"
                />
                <b-skeleton width="20%" size="is-large" v-else></b-skeleton>
            </p>
            <p v-if="boostEndTimestampFor(symbol) && boostEndTimestampFor(symbol).gt('0')">
                <b-tag type="is-success" size="is-large">BOOSTED</b-tag>
                <span>&nbsp;Bonus speed ends {{ boostEndTimestampFor(symbol) | relativeTime }}</span>
            </p>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import Balance from "@/components/Balance";

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
        Balance
    },
    props: [
        'symbol',
        'tokenAddress',
        'veTokenAddress',
        'yyTokenAddress',
        'underlyingBalance'
    ],
    data() {
        return {
            claiming: false,
            polling: null
        }
    },
    computed: {
        ...mapGetters({
            initialLoading: 'yyPtp/initialLoading',
            veBalanceFor: 'yyPtp/veBalanceFor',
            underlyingBalanceFor: 'yyPtp/underlyingBalanceFor',
            veClaimableBalanceFor: 'yyPtp/veClaimableBalanceFor',
            boostEndTimestampFor: 'yyPtp/boostEndTimestampFor',
            farmList: 'farms/data',
        }),
        tvl: function() {
            try {
                return this.farmList.filter(farm => farm.tags?.includes(`ve${this.symbol.toLowerCase()}`)).map(farm => parseFloat(farm.tvl) || 0).reduce((acc, v) => v + acc, 0)
            }
            catch {}
        },
        percentFull: function () {
            let veBalance = this.veBalanceFor(this.symbol)
            let maxVeBalance = this.underlyingBalanceFor(this.symbol).mul("100")
            return parseInt(veBalance.mul("10000").div(maxVeBalance).toString())
        },
    },
    mounted() {
    //   this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
        async updateBalances() {
            const payload = {
                symbol: this.symbol
            }
            await this.$store.dispatch('yyPtp/loadData', payload)
        },
        async handlePolling() {
            await this.updateBalances()
            this.polling = setTimeout(this.handlePolling, POLL_RATE);
        },
        async claim() {
            this.claiming = true;
            let claimResult = false;
            try {
                let payload = {
                    symbol: this.symbol,
                    address: this.yyTokenAddress,
                }
                claimResult = await this.$store.dispatch('yyPtp/claim', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (claimResult) {
                await this.updateBalances()
            }

            this.claiming = false;
        }
    }
  };
</script>
