<template>
     <section class="hero">
      <div class="hero-body">
        <div class="container is-fullhd">
          <p class="title is-size-2 has-text-centered-mobile">Yield Yak DeFi tools</p>
          <b-tabs position="is-right" size="is-large">
            <b-tab-item label="YAK SWAP" headerClass="has-text-weight-semibold">
              <div class="card mt-6">
                  <div class="card-content">
                  <div class="columns is-multiline">
                    <div class="column is-6-desktop is-12-tablet">
                        <p class="title has-text-centered-mobile is-uppercase">Yak Swap</p>
                        <img class="is-hidden-touch" src="@/assets/home/swap.png" alt="Yak Swap" />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <tool
                            title="Best Price Search"
                            subtitle="Yak Swap compares the prices available on dex's and finds the best one for you."
                            img="swap1"
                        ></tool>
                        <tool
                            title="Price Slippage"
                            subtitle="Yak Swap takes price slippage and gas costs into account to offer you the best execution route."
                            img="swap2"
                        ></tool>
                        <tool
                            title="One-Click Execution"
                            subtitle="Yak Swap detects all possible multi-step execution routes. We offer the best price so you can execute it with one click."
                            img="swap3"
                        ></tool>
                        <div class="buttons m-4 is-justify-content-center">
                            <b-button expanded size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'Swap' }">Go to Yak Swap</b-button>
                            <b-button expanded size="is-extra-large" tag="a" href="https://docs.yieldyak.com/for-traders/swap" target="_blank" icon-right="open-in-new">Read the Docs</b-button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="YAK POOLS" headerClass="has-text-weight-semibold">
                <div class="card mt-6">
                    <div class="card-content">
                        <div class="columns">
                            <div class="column is-6">
                                <p class="title has-text-centered-mobile is-uppercase">Yak Pools</p>
                                <img class="is-hidden-mobile" src="@/assets/home/farms.png" alt="Yak Pools" />
                            </div>
                            <div class="column is-6">
                                <tool
                                    title="Save Time"
                                    subtitle="Reward tokens are converted into deposit tokens on behalf of all users in the pool many times per day, automatically."
                                    img="farms1"
                                ></tool>
                                <tool
                                    title="Earn More Yield"
                                    subtitle="Yield Yak helps all farmers earn more yield by pooling assets and socializing the costs of compounding."
                                    img="farms2"
                                ></tool>
                                <tool
                                    title="Reduce Variance"
                                    subtitle="Asset reward prices fluctuate. Autocompounding means achieving a blended price on rewards."
                                    img="farms3"
                                ></tool>
                                <div class="buttons m-4 is-justify-content-center">
                                    <b-button expanded size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'Farms' }">Go to Pools</b-button>
                                    <b-button expanded size="is-extra-large" tag="a" href="https://docs.yieldyak.com/for-farmers/pool" target="_blank" icon-right="open-in-new">Read the Docs</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="LIQUID STAKING" headerClass="has-text-weight-semibold">
                <div class="card mt-6">
                    <div class="card-content">
                        <div class="columns">
                            <div class="column is-6">
                                <p class="title has-text-centered-mobile is-uppercase">Liquid Staking</p>
                                <!-- <img class="is-hidden-mobile" src="@/assets/home/swap.png" alt="Liquid Staking" /> -->
                            </div>
                            <div class="column is-6">
                                <tool
                                    title="yyAVAX"
                                    subtitle="Convert AVAX to yield-bearing yyAVAX."
                                    img="liquidstaking1"
                                ></tool>
                                <tool
                                    title="Yield Bearing AVAX"
                                    subtitle="Get staking rewards from the Avalanche P-Chain staking incentives."
                                    img="liquidstaking2"
                                ></tool>
                                <tool
                                    title="Liquid Staking"
                                    subtitle="Enter and exit any time without lockup periods or cooldowns."
                                    img="liquidstaking3"
                                ></tool>
                                <div class="buttons">
                                    <b-button expanded size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'LiquidStaking' }">Go to Liquid Staking</b-button>
                                    <b-button disabled expanded size="is-extra-large" tag="a" href="https://docs.yieldyak.com/for-farmers/pool" target="_blank" icon-right="open-in-new">Read the Docs</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="EARN" headerClass="has-text-weight-semibold">
                <div class="card mt-6">
                    <div class="card-content">
                        <div class="columns">
                            <div class="column is-6">
                                <p class="title has-text-centered-mobile is-uppercase">Earn</p>
                                <img class="is-hidden-mobile" src="@/assets/home/earn.png" alt="Earn" />
                            </div>
                            <div class="column is-6">
                                <tool
                                    title="Stake YAK"
                                    subtitle="YAK is the native token to Yield Yak. YAK is a fixed-supply token that case be staked."
                                    img="earn1"
                                ></tool>
                                <tool
                                    title="Choose Your Rewards"
                                    subtitle="Stake YAK to earn AVAX, or use the autocompounder to earn more YAK."
                                    img="earn2"
                                ></tool>
                                <tool
                                    title="Be a Yak Hero"
                                    subtitle="Yield Yak fees are collected into a special contract which allows anyone to distribute each day's fees."
                                    img="earn3"
                                ></tool>
                                <div class="buttons">
                                    <b-button expanded size="is-extra-large" type="is-success" tag="router-link" :to="{ name: 'Stake' }">Go to Earn</b-button>
                                    <b-button expanded size="is-extra-large" tag="a" href="https://docs.yieldyak.com/token/details" target="_blank" icon-right="open-in-new">Read the Docs</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
</template>
<script>
  import Tool from "@/components/Home/Tool";

  export default {
    components: {
      Tool
    },
  };
</script>