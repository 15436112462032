<template>
  <b-button
    type="is-ghost" 
    @click="toggleReversePrice"
    class="has-text-grey"
  >
    <!-- <a class="has-text-grey" @click="toggleReversePrice"> -->
      <!-- <b-icon icon="swap-horizontal-bold" /> -->
      <span v-if="reversePrice">1 {{ fromToken.symbol }} = {{ price | fromWei | number }} {{ toToken.symbol}}</span>
      <span v-else>1 {{ toToken.symbol }} = {{ price | fromWei | number }} {{ fromToken.symbol}}</span>
    <!-- </a> -->
  </b-button>
</template>

<script>
  import { utils } from "ethers";

  export default {
    props: [
        'fromToken',
        'toToken',
        'trade'
    ],
    computed: {
      price: function () {
        if (this.trade) {
          if (this.reversePrice) {
            let mantissa = 18 + this.fromToken.decimals - this.toToken.decimals
            return this.trade.amounts[this.trade.amounts.length - 1].mul(utils.parseUnits("1", mantissa)).div(this.trade.amounts[0])
          }
          else {
            let mantissa = 18 + this.toToken.decimals - this.fromToken.decimals
            return this.trade.amounts[0].mul(utils.parseUnits("1", mantissa)).div(this.trade.amounts[this.trade.amounts.length - 1])
          }
        }
        return null;
      },
    },
    data() {
      return {
        reversePrice: false,
      }
    },
    methods: {
      toggleReversePrice() {
        this.reversePrice = !this.reversePrice
      },
    }
  };
</script>
