import {
    getFirehose,
    getReinvestLeaderboard
} from '@/services/ReinvestSubgraph';

const state = () => ({
    activity: {},
    leaderboard: {},
});

const getters = {
    activity: (state) => state.activity,
    leaderboard: (state) => state.leaderboard,
};

const mutations = {
    setActivity(state, activity) {
        state.activity = activity;
    },
    setLeaderboard(state, leaderboard) {
        state.leaderboard = leaderboard;
    },
};

const actions = {
    async updateActivity({ commit, rootGetters }) {
        try {
            let chainId = rootGetters['accountModule/selectedChainId']
            let activity = await getFirehose(chainId);
            commit('setActivity', activity);
        } catch {}

        try {
            let chainId = rootGetters['accountModule/selectedChainId']
            let leaderboard = await getReinvestLeaderboard(chainId);
            commit('setLeaderboard', leaderboard)
        } catch {}
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}