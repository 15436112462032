<template>
    <div class="content mb-4">
        <p class="heading">
            Recent Performance
        </p>
        <p v-if="deprecated">
            This strategy is inactive.
        </p>
        <p v-else-if="!apy">
            This strategy is active, but there is not enough data to determine recent performance.
        </p>
        <p v-else-if="apy">
            This strategy has returned <strong>{{ apyCompound | percentage_2 }} APY on {{ depositTokenSymbol }}</strong> based on recent performance.
        </p>
        <ul v-if="swapFees">
            <li v-if="swapFees">{{ swapFees | percentage_2 }} from swap fees</li>
            <li v-if="apy">{{ apy | percentage_2 }} from farm rewards</li>
            <li v-else>??? from farm rewards</li>
        </ul>
        <p v-if="apr">
            Without compounding effects{{ swapFees && ` nor swap fees`}}, this strategy has returned <strong>{{ apr | percentage_2 }} APR on {{ depositTokenSymbol }}</strong> from farm rewards. The figure is extrapolated over a year based on recent performance. Over other time periods:
            <ul>
            <li>{{ apr / 365 | percentage_2 }} per day</li>
            <li>{{ apr / 52 | percentage_2 }} per week</li>
            <li>{{ apr / 12 | percentage_2 }} per month</li>
            </ul>
        </p>
        <p>
            Yield Yak calculates returns based on actual performance, and includes factors like platform fees and trade slippage. Returns are variable and you may not realize the full impacts of APY if a farm stops emitting rewards or other factors change.
        </p>
        <p>
            <ButtonDocs
                expanded
                label="Returns and Tracking" 
                href="https://docs.yieldyak.com/for-farmers/returns"
            />
        </p>
    </div>
</template>

<script>
  import ButtonDocs from "@/components/Buttons/Docs";

  export default {
    components: {
        ButtonDocs,
    },
    props: [
        'deprecated',
        'apr',
        'apy',
        'apyCompound',
        'depositTokenSymbol',
        'swapFees'
    ],
  };
</script>
