<template>
  <div>
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-centered pb-6">
            <div class="column pt-3 is-12 has-text-centered">
              <h2 class="title is-size-1 is-success">
                Yak Rush: Banker Joe Edition
              </h2>
              <h2 class="subtitle is-size-3">
                50,000,000 mYAK available to claim from November 2, 2021
              </h2>
            </div>
            <div class="column pt-6 is-9-desktop is-12-mobile">
              <div class="box has-border-top">
                <div class="columns">
                  <div class="column">
                    <div class="box is-dark">
                      <p class="heading">AVAILABLE TO CLAIM</p>
                      <p class="title is-size-2 has-text-grey" v-if="!account || !amountToClaim">
                        tbd
                      </p>
                      <p class="title is-size-2 is-success" v-else-if="!isClaimDisabled">
                        <balance
                          :amount="amountToClaim"
                          decimals="12"
                          decimalPlaces="0"
                          symbol="mYAK"
                          :useText="true"
                        />
                      </p>
                      <p class="title is-size-2 has-text-grey" v-else>
                        0 mYAK
                      </p>
                      <b-button
                        expanded
                        size="is-large"
                        type="is-primary"
                        :loading="isLoading"
                        :disabled="isClaimDisabled"
                        @click="claim"
                      >
                        Claim
                      </b-button>
                      <b-button
                        expanded
                        size="is-normal"
                        type="is-dark"
                        tag="a"
                        href="https://yieldyak.medium.com/first-edition-of-yak-rush-recap-and-50-000-000-mini-yak-claim-details-18dd4026d6be"
                        target="_blank"
                      >
                        About Yak Rush&nbsp;&nearr;
                      </b-button>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box is-dark">
                      <p class="heading">YOUR WALLET</p>
                      <p class="title is-size-2 has-text-grey" v-if="!account">
                        tbd
                      </p>
                      <p class="title is-size-2 is-sucess" v-else-if="mYakBalance && mYakBalance.gt('0')">
                        <balance
                          :amount="mYakBalance"
                          decimals="12"
                          decimalPlaces="0"
                          symbol="mYAK"
                          :useText="true"
                        />
                      </p>
                      <p class="title is-size-2 has-text-grey" v-else>
                        0 mYAK
                      </p>
                      <b-button
                        expanded
                        size="is-normal"
                        type="is-dark"
                        tag="a"
                        href="https://cchain.explorer.avax.network/address/0xdDAaAD7366B455AfF8E7c82940C43CEB5829B604/transactions"
                        target="_blank"
                      >
                        Mini YAK Token Details&nbsp;&nearr;
                      </b-button>
                    </div>
                  </div>
                </div>
                <!-- <b-button
                  expanded
                  size="is-large"
                  type="is-primary"
                  @click="goToStaking"
                >
                  Go to Staking
                </b-button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Balance from "@/components/Balance";
  import DeployedAddresses from '@/contracts/Deployed';

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
      Balance,
    },
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        amountToClaim: 'amountToClaim',
        chainId: 'accountModule/selectedChainId',
        tokenBalances: 'balances/data'
      }),
      mYakBalance: function () {
        try {
          const miniYakTokenAddress = DeployedAddresses[this.chainId].miniYakToken;
          return this.tokenBalances[miniYakTokenAddress];
        }
        catch {}
      },
      isClaimDisabled: function () {
        // return true;
        return !this.account || !this.amountToClaim || this.amountToClaim.eq("0");
      },
    },
    data() {
      return {
        isLoading: false,
        polling: null
      }
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
      async handlePolling() {
        const miniYakTokenAddress = DeployedAddresses[this.chainId].miniYakToken;
        await Promise.all([
          this.$store.dispatch('getAmountToClaimForUser'),
          this.$store.dispatch('balances/loadData', [miniYakTokenAddress])
        ])
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      async goToStaking() {
        console.log("go to staking")
      },
      async claim() {
        const miniYakTokenAddress = DeployedAddresses[this.chainId].miniYakToken;
        this.isLoading = true;
        let result = await this.$store.dispatch('claimAirdrop');
        this.isLoading = false;
        // check yak balance
        if (result) {
          await Promise.all([
            this.$store.dispatch('getAmountToClaimForUser'),
            this.$store.dispatch('balances/loadData', [miniYakTokenAddress])
          ])
          this.$confetti.start();
          await new Promise(r => setTimeout(r, 5000));
          this.$confetti.stop();
        }
      },
    },
  };
</script>