import { Contract, BigNumber } from 'ethers';
import Deployed from '@/contracts/Deployed';
import VotingPowerABI from '@/abis/VotingPower';
import Vue from 'vue';

const state = () => ({
    data: {},
    initialLoadComplete: false,
    isLoading: false,
})

const getters = {
  data: state => {
    return state.data
  },
  votingPower: state => {
      try {
          return state.data.votingPower
      }
      catch {
          return BigNumber.from("0")
      }
  },
  isLoading: state => {
    return state.isLoading
  },
  initialLoading: state => {
    return !state.initialLoadComplete
  }
}
  
const mutations = {
    set(state, data) {
      state.data = data
    },
    setValue(state, payload) {
      try {
        Vue.set(state.data, payload.key, payload.value)
      }
      catch (err) {
        console.log("voting / setValue err", err)
      }
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setInitialLoadComplete(state, payload) {
        state.initialLoadComplete = payload
    }
}

const actions = {
  async load({ commit }, payload) {
    commit('set', payload)
  },
  async loadData({ state, commit, rootGetters }, payload=null) {
    if (state.isLoading) {
      console.log('vuex/voting/loadData -- already loading')
      return;
    }
    commit('setIsLoading', true)
    let account = rootGetters['accountModule/account']
    let chainId = rootGetters['accountModule/selectedChainId']
    let provider = rootGetters['accountModule/provider']
    if (account && chainId && provider) {
        let votingPowerAddress = Deployed[chainId].votingPower
        let votingPowerContract = new Contract(votingPowerAddress, VotingPowerABI, provider);
        try {
          let votingPower = await votingPowerContract.balanceOf(account);
          commit('setValue', { key: 'votingPower', value: votingPower })
        }
        catch (err) {
          console.log("could not load token balances", err);
        }

        // commit('set', data)
        commit('setInitialLoadComplete', true)
    }
    commit('setIsLoading', false)
  },
  clearData({ commit }) {
        commit('setInitialLoadComplete', false)
        commit('set', {})
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}