import { providers } from 'ethers';

function toHex(value) {
    return '0x'+value.toString(16);
}

function getExplorerDetails(chainId) {
    switch(chainId) {
        case '42161':
            return { name: "Arbiscan", url: supportedChains[chainId].blockExplorerUrls[0] }
        case '43114':
            return { name: "Snowtrace", url: supportedChains[chainId].blockExplorerUrls[0] }
        default:
            // Default to Avalanche explorer details
            return { name: "Snowtrace", url: supportedChains["43114"].blockExplorerUrls[0] }
    }
}

function getChainName(chainId) {
    try {
        return supportedChains[chainId].chainName
    }
    catch {
        return null
    }
}

function getChainRPC(chainId) {
    try {
        return supportedChains[chainId].rpcUrls[0]
    }
    catch {
        return null
    }
}

function getPublicProvider(chainId) {
    try {
        const RPC_URL = getChainRPC(chainId)
        return new providers.JsonRpcProvider(RPC_URL);
    }
    catch {
        return null
    }
}

const supportedChains = {
    // "324": {
    //     chainId: toHex(324),
    //     chainName: "zkSync Era Mainnet",
    //     nativeCurrency: {
    //         name: "Ethereum",
    //         symbol: "ETH",
    //         decimals: 18
    //     },
    //     rpcUrls: [ "https://mainnet.era.zksync.io" ],
    //     blockExplorerUrls: [ "https://explorer.zksync.io/" ]
    // },
    // "2000": {
    //     chainId: toHex(2000),
    //     chainName: "DogeChain",
    //     nativeCurrency: {
    //         name: "Dogecoin",
    //         symbol: "DOGE",
    //         decimals: 18,
    //     },
    //     rpcUrls: [ "https://rpc01-sg.dogechain.dog" ],
    //     blockExplorerUrls: [ "https://explorer.dogechain.dog" ]
    // },
    "42161": {
        chainId: toHex(42161),
        chainName: "Arbitrum",
        nativeCurrency: {
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: [ "https://arb1.arbitrum.io/rpc" ],
        blockExplorerUrls: [ "https://arbiscan.io/" ]
    },
    "43114": {
        chainId: toHex(43114), // A 0x-prefixed hexadecimal string
        chainName: "Avalanche",
        nativeCurrency: {
            name: "Avalanche",
            symbol: "AVAX", // 2-6 characters long
            decimals: 18,
        },
        rpcUrls: [ "https://api.avax.network/ext/bc/C/rpc" ],
        blockExplorerUrls: [ "https://snowtrace.io/" ]
    }
}

async function connectToChain(id, account) {
    if (window && window.ethereum && account) {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [supportedChains[id], account]
            });
            return true;
        }
        catch (err) {
            console.log("debug::connectToChain::err", err);
        }
        return false;
    }
}

function getWalletName() {
    if (window && window.ethereum) {
        if (window.ethereum.isTokenPocket) return 'TokenPocket'
        if (window.ethereum.isCoin98) return 'Coin98'
        if (window.ethereum.isAvalanche) return 'Core'
        if (window.ethereum.isImToken) return 'imToken'
        if (window.ethereum.isToshi) return 'Coinbase'
        if (window.ethereum.isRabby) return 'Rabby'
        if (window.ethereum.isMetaMask) return 'MetaMask'
    }
    return 'Wallet'
  }

export {
    getWalletName,
    connectToChain, supportedChains,
    getChainName, getChainRPC, getPublicProvider,
    getExplorerDetails
}