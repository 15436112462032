<template>
    <div class="card">
      <div class="card-content">
          <p class="heading">Symbol</p>
          <p class="title is-size-3">
              {{ symbol }}
          </p>
          <p class="heading">Total Supply</p>
          <p class="title is-size-3">
              <balance
                  v-if="totalSupply"
                  :amount="totalSupply.toString()" 
                  :decimals="18"
                  :animationDuration="300"
                  :underlying="[address]"
                  :symbol="symbol"
              />
              <b-skeleton width="20%" size="is-large" v-else />
          </p>
          <p class="heading">Address</p>
          <p class="title is-size-3" v-if="address">
              {{ address | shortEth }}
              <ButtonCopy size="is-medium" :text="address" />
          </p>
          <p class="heading" v-if="account">My Wallet</p>
          <p class="title is-size-3" v-if="account">
              <balance
                  v-if="tokenBalance"
                  :amount="tokenBalance.toString()" 
                  :decimals="18"
                  :animationDuration="300"
                  :underlying="[address]"
                  :symbol="symbol"
              />
              <b-skeleton width="20%" size="is-large" v-else />
          </p>
          <b-button 
              type="is-success" expanded size="is-large"
              tag="router-link" :to="{ name: 'Swap', query: { inputCurrency: underlyingAddress, outputCurrency: address }}"
          >Get {{ symbol }}</b-button>
          <p class="is-size-6 pt-1 has-text-centered">You will be directed to Yak Swap. Yak Swap automatically gives you the best price for {{ symbol }} by minting or buying from market.</p>
      </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import Balance from "@/components/Balance";
  import ButtonCopy from "@/components/Buttons/Copy";

  export default {
    components: {
      Balance,
      ButtonCopy,
    },
    props: [
        'address',
        'symbol',
        'underlyingAddress',
        'underlyingSymbol'
    ],
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        tokenBalances: 'balances/balanceOfToken',
        underlyingBalanceFor: 'yyPtp/underlyingBalanceFor',
      }),
      tokenBalance: function () {
        return this.tokenBalances(this.address)
      },
      totalSupply: function () {
        return this.underlyingBalanceFor(this.underlyingSymbol)
      }
    },
  }
</script>