import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import {ethers, Contract, BigNumber, constants } from 'ethers';

import ClaimABI from '@/abis/Claim';
import RouterABI from '@/abis/Router';
import PairABI from '@/abis/Pair';
import StrategyABI from '@/abis/DexStrategy';
import DexStrategyV2ABI from '@/abis/DexStrategyV2';
import IceQueenStrategyV2ABI from '@/abis/IceQueenStrategyV2';
import YakStrategyV2PayableABI from '@/abis/YakStrategyV2Payable';
import YakRouterABI from '@/abis/YakRouter';
import YakWrapRouterABI from '@/abis/YakWrapRouter';
import MasterYakABI from '@/abis/MasterYak';

import DeployedAddresses from '@/contracts/Deployed';
import TokenList from '@/contracts/TokenList';

import {
  RPC_URL,
} from "@/settings";

import accountModule from './account';
import activity from './activity';
import prices from './prices';
import farms from './farms';
import apy from './apy';
import arc from './arc';
import balances from './balances';
import bonds from './bonds';
import bridge from './bridge';
import deposits from './deposits';
import swap from './swap'
import swapfees from './swapfees';
import voting from './voting';
import yyAvax from './yyAvax';
import yyPtp from './yyPtp';
import { 
  showSnackbarSuccess,
  showSnackbarDanger
} from '@/services/Snackbar';

const PRICES_POLL_RATE = 30 * 1000;
const APY_POLL_RATE = 30 * 1000;
const SWAP_FEE_POLL_RATE = 300 * 1000;

let provider = new ethers.providers.JsonRpcProvider(RPC_URL);

export default new Vuex.Store({
  modules: {
    accountModule,
    activity,
    apy,
    arc,
    balances,
    bonds,
    bridge,
    deposits,
    prices,
    farms,
    swap,
    swapfees,
    voting,
    yyAvax,
    yyPtp
  },
  state: {
    amountToClaim: null,
    stakingData: null,
  },
  mutations: {
    setAmountToClaim(state, amountToClaim) {
      state.amountToClaim = amountToClaim;
    },
    setStakingData(state, stakingData) {
      state.stakingData = stakingData;
    },
  },
  actions: {
    async handleFirstUpdate({ dispatch }) {
      // console.log("handleFirstUpdate", new Date())
      await Promise.all([
        dispatch('prices/loadData'),
        dispatch('apy/loadData'),
        dispatch('arc/loadData'),
        dispatch('swapfees/loadData'),
        dispatch('farms/loadData'),
        dispatch('fetchStakingData'),
        dispatch('yyAvax/loadData'),
        // dispatch('yyPtp/loadData'),
        dispatch('accountModule/connect', false)
      ])

      setTimeout(() => dispatch('handlePriceUpdates'), PRICES_POLL_RATE);
      setTimeout(() => dispatch('handleApyUpdates'), APY_POLL_RATE);
      setTimeout(() => dispatch('handleSwapFeeUpdates'), SWAP_FEE_POLL_RATE);
    },
    async handlePriceUpdates({dispatch}) {
      await dispatch('prices/loadData');
      setTimeout(() => dispatch('handlePriceUpdates'), PRICES_POLL_RATE);
    },
    async handleApyUpdates({dispatch}) {
      await dispatch('apy/loadData');
      setTimeout(() => dispatch('handleApyUpdates'), APY_POLL_RATE);
    },
    async handleSwapFeeUpdates({dispatch}) {
      await dispatch('swapfees/loadData');
      setTimeout(() => dispatch('handleSwapFeeUpdates'), SWAP_FEE_POLL_RATE);
    },
    async getAmountToClaimForUser({state, commit, rootGetters}) {
      let chainId = rootGetters['accountModule/selectedChainId']
      let account = rootGetters['accountModule/account']
      if (chainId && account) {
        const claimAddress = DeployedAddresses[chainId].claim;
        try {
          let claimContract = new Contract(claimAddress, ClaimABI, provider);
          let grantAmount = await claimContract.tokenGrants(account);
          commit('setAmountToClaim', grantAmount);
        }
        catch (err) {
          console.log(err)
        }
      }
    },
    async claimAirdrop({state, rootGetters}) {
      let chainId = rootGetters['accountModule/selectedChainId']
      let signer = rootGetters['accountModule/signer']
      if (chainId && signer) {
        const claimAddress = DeployedAddresses[chainId].claim;
        try {
          let signedClaimContract = new Contract(claimAddress, ClaimABI, signer);
          let tx = await signedClaimContract.claim();
          await tx.wait(1);
          return true;
        }
        catch (err) {
          console.log(err);
          return false;
        }
      }
      return false;
    },
    async reinvest({ rootGetters }, address) {
      let signer = rootGetters['accountModule/signer']
      if (signer) {
        try {
          let signedStrategyContract = new Contract(address, StrategyABI, signer);
          let tx = await signedStrategyContract.reinvest();
          showSnackbarSuccess("Reinvest Pending", "Transaction sent");
          let txReceipt = await tx.wait(1);
          if (txReceipt.status) {
            showSnackbarSuccess("Reinvest Success", "Transaction confirmed");
          }
          else {
            showSnackbarDanger("Reinvest Failed", "Transaction failed");
          }
          return txReceipt.status;
        }
        catch (err) {
          console.log(err);
          showSnackbarDanger("Transaction not sent", err.reason);
          return false;
        }
      }
    },
    async withdraw({ rootGetters }, payload) {
      const { address, amount } = payload;
      let signer = rootGetters['accountModule/signer']
      if (signer) {
        try {
          let signedStrategyContract = new Contract(address, StrategyABI, signer);
          let tx = await signedStrategyContract.withdraw(amount);
          showSnackbarSuccess("Withdraw Pending", "Transaction sent");
          let txReceipt = await tx.wait(1);
          if (txReceipt.status) {
            showSnackbarSuccess("Withdraw Success", "Transaction confirmed");
          }
          else {
            showSnackbarDanger("Withdraw Failed", "Transaction failed");
          }
          return txReceipt.status;
        }
        catch (err) {
          console.log(err);
          showSnackbarDanger("Transaction not sent", err.data.message);
          return false;
        }
      }
      return false;
    },
    async deposit({ dispatch, rootGetters }, payload) {
      const { address, amount } = payload;
      let account = rootGetters['accountModule/account']
      let provider = rootGetters['accountModule/provider']
      let signer = rootGetters['accountModule/signer']
      let chainId = rootGetters['accountModule/selectedChainId']

      if (signer && chainId && account) {
        let signedStrategyContract, depositTokenAddress, permitSupported;
        try {
          signedStrategyContract = new Contract(address, IceQueenStrategyV2ABI, signer);
          depositTokenAddress = await signedStrategyContract.depositToken();

          if (depositTokenAddress == constants.AddressZero) {
            // Case for YakStrategyV2Payable
            try {
              signedStrategyContract = new Contract(address, YakStrategyV2PayableABI, signer);
              let tx = await signedStrategyContract['deposit()']({value: amount.toString()});
              showSnackbarSuccess("Deposit Pending", "Transaction sent");
              let txReceipt = await tx.wait(1);
              if (txReceipt.status) {
                showSnackbarSuccess("Deposit Success", "Transaction confirmed");
              }
              else {
                showSnackbarDanger("Deposit Failed", "Transaction failed");
              }
              return txReceipt.status;
            }
            catch (err) {
              console.log(err);
              showSnackbarDanger("Transaction not sent", err.data.message);
              return false
            }
          }

          try {
            // Case for IceQueenStrategyV2
            let lpTokenAddress = await signedStrategyContract.lpToken();
            permitSupported = false;
          }
          catch {
            // Case for DexStrategyV2
            signedStrategyContract = new Contract(address, DexStrategyV2ABI, signer);
            permitSupported = true;
          }
        }
        catch (err) {
          // Case for DexStrategy
          signedStrategyContract = new Contract(address, StrategyABI, signer);
          depositTokenAddress = await signedStrategyContract.lpToken();
          permitSupported = true;
        }

        try {
          let depositTokenContract = new Contract(depositTokenAddress, PairABI, signer);
          let approvedBalance = await depositTokenContract.allowance(account, address);

          try {
            if (!permitSupported) throw new Error("Permit not supported");
            if (approvedBalance.gte(amount)) throw new Error("Permit not needed");

            let deadline = parseInt(Date.now() / 1000) + 1200; // now plus 20 mins
            let params = await dispatch('getPermitParams', {
              tokenContract: depositTokenContract,
              chainId: chainId,
              account: account,
              spender: address,
              amount,
              deadline
            });

            const signature = await provider.send("eth_signTypedData_v4", params);
            let tx = await signedStrategyContract.depositWithPermit(
              amount.toString(),
              deadline.toString(),
              parseInt(signature.substring(2).substring(128, 130), 16),
              ethers.utils.arrayify("0x" + signature.substring(2).substring(0,64)),
              ethers.utils.arrayify("0x" + signature.substring(2).substring(64, 128)),
            );
            showSnackbarSuccess("Deposit Pending", "Transaction sent");
            let txReceipt = await tx.wait(1);
            if (txReceipt.status) {
              showSnackbarSuccess("Deposit Success", "Transaction confirmed");
            }
            else {
              showSnackbarDanger("Deposit Failed", "Transaction failed");
            }
            return txReceipt.status;
          }
          catch (err) {
            try {
              if (approvedBalance.lt(amount)) {
                let tx = await depositTokenContract.approve(address, amount);
                showSnackbarSuccess("Approval Pending", "Transaction sent");
                let txReceipt = await tx.wait(1);
                if (txReceipt.status) {
                  showSnackbarSuccess("Approval Success", "Transaction confirmed");
                }
                else {
                  showSnackbarDanger("Approval Failed", "Transaction failed");
                }
              }
              
              let tx = await signedStrategyContract.deposit(amount.toString());
              showSnackbarSuccess("Deposit Pending", "Transaction sent");
              let txReceipt = await tx.wait(1);
              if (txReceipt.status) {
                showSnackbarSuccess("Deposit Success", "Transaction confirmed");
              }
              else {
                showSnackbarDanger("Deposit Failed", "Transaction failed");
              }
              return txReceipt.status;
            }
            catch (err) {
              console.log(err);
              showSnackbarDanger("Transaction not sent", err.data.message);
              return false;
            }
          }
        }
        catch (err) {
          return false;
        }
      }
      return false;
    },
    async depositToMasterYak({ dispatch, rootGetters }, payload) {
      const { pid, depositTokenAddress, amount } = payload;
      let account = rootGetters['accountModule/account']
      let chainId = rootGetters['accountModule/selectedChainId']
      let provider = rootGetters['accountModule/provider']
      let signer = rootGetters['accountModule/signer']
      if (chainId && account) {
        const masterYakAddress = DeployedAddresses[chainId].masterYak.address;
        let signedMasterYakContract = new Contract(masterYakAddress, MasterYakABI, signer);
        let depositTokenContract = new Contract(depositTokenAddress, PairABI, signer);

        try {

          try {
            if (amount.eq("0")) throw new Error("No approval needed");

            let deadline = parseInt(Date.now() / 1000) + 1200; // now plus 20 mins
            let params = await dispatch('getPermitParams', {
              tokenContract: depositTokenContract,
              chainId: chainId,
              account: account,
              spender: masterYakAddress,
              amount,
              deadline
            });

            const signature = await provider.send("eth_signTypedData_v4", params);
            let tx = await signedMasterYakContract.depositWithPermit(
              pid,
              amount.toString(),
              deadline.toString(),
              parseInt(signature.substring(2).substring(128, 130), 16),
              ethers.utils.arrayify("0x" + signature.substring(2).substring(0,64)),
              ethers.utils.arrayify("0x" + signature.substring(2).substring(64, 128)),
            );
            showSnackbarSuccess("Deposit Pending", "Transaction sent");
            let txReceipt = await tx.wait(1);
            if (txReceipt.status) {
              showSnackbarSuccess("Deposit Success", "Transaction confirmed");
            }
            else {
              showSnackbarDanger("Deposit Failed", "Transaction failed");
            }
            return txReceipt.status;
          }
          catch (err) {
            // Use on-chain approval method
            try {
              let approvedBalance = await depositTokenContract.allowance(account, masterYakAddress);
              if (approvedBalance.lt(amount)) {
                let tx = await depositTokenContract.approve(masterYakAddress, ethers.constants.MaxUint256);
                let txReceipt = await tx.wait(1);
              }
              
              let tx = await signedMasterYakContract.deposit(pid, amount.toString());
              showSnackbarSuccess("Deposit Pending", "Transaction sent");
              let txReceipt = await tx.wait(1);
              if (txReceipt.status) {
                showSnackbarSuccess("Deposit Success", "Transaction confirmed");
              }
              else {
                showSnackbarDanger("Deposit Failed", "Transaction failed");
              }
              return txReceipt.status;
            }
            catch (err) {
              console.log(err);
              return false;
            }
          }
        }
        catch (err) {
          return false;
        }
      }
      return false;
    },
    async withdrawFromMasterYak({ rootGetters }, payload) {
      const { pid, amount } = payload;
      let account = rootGetters['accountModule/account']
      let chainId = rootGetters['accountModule/selectedChainId']
      let signer = rootGetters['accountModule/signer']
      if (chainId && account) {
        const masterYakAddress = DeployedAddresses[chainId].masterYak.address;
        let signedMasterYakContract = new Contract(masterYakAddress, MasterYakABI, signer);

        try {
          let tx = await signedMasterYakContract.withdraw(pid, amount.toString());
          showSnackbarSuccess("Withdraw Pending", "Transaction sent");
          let txReceipt = await tx.wait(1);
          if (txReceipt.status) {
            showSnackbarSuccess("Withdraw Success", "Transaction confirmed");
          }
          else {
            showSnackbarDanger("Withdraw Failed", "Transaction failed");
          }
          return txReceipt.status;
        }
        catch (err) {
          return false;
        }
      }
      return false;
    },
    async getPermitParams({}, payload) {
      // Approval function for EIP-712
      // Note: this solution uses provider.sent("eth_signTypedData_v4", ...)
      // instead of experimental _signTypedData. This is not supported by Ledger
      // and Trezor. There is no fallback for normal approvals.
      // See https://github.com/ethers-io/ethers.js/issues/298
      const { tokenContract, account, chainId, spender, amount, deadline } = payload;

      const name = await tokenContract.name(); // token name
      const version = "1";
      const verifyingContract = ethers.utils.getAddress(tokenContract.address);

      const nonce = await tokenContract.nonces(account);

      const domain = {
        name,
        version,
        chainId,
        verifyingContract
      };

      const types = {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ]
      };

      const value = {
        owner: ethers.utils.getAddress(account),
        spender: ethers.utils.getAddress(spender),
        value: amount.toString(),
        nonce: nonce.toString(),
        deadline: deadline.toString(),
      };

      const msgParams = JSON.stringify({
        types,
        domain,
        primaryType: "Permit",
        message: value,
      });

      const params = [account, msgParams];
      return params;
    },
    async swap({ dispatch, rootGetters }, payload) {
      let chainId = rootGetters['accountModule/selectedChainId']
      let account = rootGetters['accountModule/account']
      let provider = rootGetters['accountModule/provider']
      let signer = rootGetters['accountModule/signer']

      if (chainId && account) {
        const { trade, fromNative, toNative } = payload;
        const yakRouterAddress = DeployedAddresses[chainId].yakSwap.router;
        const yakRouterContract = new Contract(yakRouterAddress, YakRouterABI, signer);
        const fee = 0;
        const gasLimit = trade.gasEstimate.mul("12").div("10").add("100000") // 120% + 100,000
        try {
          if (fromNative) {
            let tx = await yakRouterContract.swapNoSplitFromAVAX({
                amountIn: trade.amounts[0],
                amountOut: trade.amounts[trade.amounts.length - 1],
                path: trade.path,
                adapters: trade.adapters
              }, 
              account, 
              fee, 
              { 
                value: trade.amounts[0], 
                gasLimit
              });
            showSnackbarSuccess("Swap Pending", "Transaction sent");
            let txReceipt = await tx.wait(1);
            console.log(txReceipt)
            if (txReceipt.status) {
              showSnackbarSuccess("Swap Success", "Transaction confirmed");
            }
            else {
              showSnackbarDanger("Swap Failed", "Transaction failed");
            }
            return txReceipt.status;
          }
          else {
            // handle approval
            let fromTokenAddress = trade.path[0];
            const permitSupported = TokenList[chainId].filter(token => token.address.toLowerCase() == fromTokenAddress.toLowerCase())[0].permitSupported;
            const tokenContract = new Contract(fromTokenAddress, PairABI, signer);
            const approvedBalance = await tokenContract.allowance(account, yakRouterAddress);
            try {
              if (!permitSupported) throw new Error("Permit not supported");
              if (approvedBalance.gte(trade.amounts[0])) throw new Error("Permit not needed");

              let deadline = parseInt(Date.now() / 1000) + 1200; // now plus 20 mins
              let params = await dispatch('getPermitParams', {
                tokenContract,
                account,
                chainId,
                spender: yakRouterAddress,
                amount: trade.amounts[0],
                deadline
              });

              const signature = await provider.send("eth_signTypedData_v4", params);

              if (toNative) {
                let tx = await yakRouterContract.swapNoSplitToAvaxWithPermit({
                    amountIn: trade.amounts[0],
                    amountOut: trade.amounts[trade.amounts.length - 1],
                    path: trade.path,
                    adapters: trade.adapters
                  },
                  account, 
                  fee,
                  deadline.toString(),
                  parseInt(signature.substring(2).substring(128, 130), 16),
                  ethers.utils.arrayify("0x" + signature.substring(2).substring(0,64)),
                  ethers.utils.arrayify("0x" + signature.substring(2).substring(64, 128)),
                  { 
                    gasLimit
                  }
                );
                showSnackbarSuccess("Swap Pending", "Transaction sent");
                let txReceipt = await tx.wait(1);
                if (txReceipt.status) {
                  showSnackbarSuccess("Swap Success", "Transaction confirmed");
                }
                else {
                  showSnackbarDanger("Swap Failed", "Transaction failed");
                }
                return txReceipt.status;

              }
              else {
                let tx = await yakRouterContract.swapNoSplitWithPermit({
                    amountIn: trade.amounts[0],
                    amountOut: trade.amounts[trade.amounts.length - 1],
                    path: trade.path,
                    adapters: trade.adapters
                  },
                  account, 
                  fee,
                  deadline.toString(),
                  parseInt(signature.substring(2).substring(128, 130), 16),
                  ethers.utils.arrayify("0x" + signature.substring(2).substring(0,64)),
                  ethers.utils.arrayify("0x" + signature.substring(2).substring(64, 128)),
                  { 
                    gasLimit
                  }
                );
                showSnackbarSuccess("Swap Pending", "Transaction sent");
                let txReceipt = await tx.wait(1);
                if (txReceipt.status) {
                  showSnackbarSuccess("Swap Success", "Transaction confirmed");
                }
                else {
                  showSnackbarDanger("Swap Failed", "Transaction failed");
                }
                return txReceipt.status;

              }
            }
            catch {
              // Use on-chain approval method
              try {
                if (approvedBalance.lt(trade.amounts[0])) {
                  let tx = await tokenContract.approve(yakRouterAddress, ethers.constants.MaxUint256);
                  let txReceipt = await tx.wait(1);
                  // console.log("debug::txReceipt", txReceipt)
                }
                
                if (toNative) {
                  let tx = await yakRouterContract.swapNoSplitToAVAX({
                      amountIn: trade.amounts[0],
                      amountOut: trade.amounts[trade.amounts.length - 1],
                      path: trade.path,
                      adapters: trade.adapters
                    },
                    account, 
                    fee,
                    { 
                      gasLimit
                    }
                  );
                  showSnackbarSuccess("Swap Pending", "Transaction sent");
                  let txReceipt = await tx.wait(1);
                  if (txReceipt.status) {
                    showSnackbarSuccess("Swap Success", "Transaction confirmed");
                  }
                  else {
                    showSnackbarDanger("Swap Failed", "Transaction failed");
                  }
                  return txReceipt.status;
                }
                else {
                  let tx = await yakRouterContract.swapNoSplit({
                      amountIn: trade.amounts[0],
                      amountOut: trade.amounts[trade.amounts.length - 1],
                      path: trade.path,
                      adapters: trade.adapters
                    },
                    account, 
                    fee,
                    { 
                      gasLimit
                    }
                  );
                  showSnackbarSuccess("Swap Pending", "Transaction sent");
                  let txReceipt = await tx.wait(1);
                  if (txReceipt.status) {
                    showSnackbarSuccess("Swap Success", "Transaction confirmed");
                  }
                  else {
                    showSnackbarDanger("Swap Failed", "Transaction failed");
                  }
                  return txReceipt.status;
                }
              }
              catch (err) {
                console.log("swap::err", err);
                return false;
              }
            }
          }
        }
        catch (err) {
          console.log("swap::err", err);
          return false;
        }
      }
    },
    async fetchOnchainPricesV2({rootGetters}, payload) {
      // console.log("fetchOnchainPricesV2", payload)
      let chainId = rootGetters['accountModule/selectedChainId']
      let provider = rootGetters['accountModule/provider']
      if (chainId && provider) {
        const { fromToken, toToken, amountIn } = payload;
        const yakRouterAddress = DeployedAddresses[chainId].yakSwap.router;
        const yakRouterContract = new Contract(yakRouterAddress, YakRouterABI, provider);
        const gasPrice = await provider.getGasPrice();
        const maxSteps = 3;

        try {
          let data = await yakRouterContract.findBestPathWithGas(
            amountIn, fromToken, toToken, maxSteps, gasPrice, { gasLimit: 1e9 }
          );
          return data;
        }
        catch {
          // console.log("err::fetchOnChainPricesV2");
        }
      }
    },
    async fetchOnchainPricesV3({rootGetters}, payload) {
      // console.log("fetchOnchainPricesV3", payload)
      let chainId = rootGetters['accountModule/selectedChainId']
      let provider = rootGetters['accountModule/provider']
      if (chainId && provider) {
        const { fromToken, toToken, amountIn } = payload;
        const yakRouterAddress = DeployedAddresses[chainId].yakSwap.wrapRouter;
        const yakRouterContract = new Contract(yakRouterAddress, YakWrapRouterABI, provider);
        const gasPrice = await provider.getGasPrice();
        const maxSteps = 2;

        try {
          const wrapper = DeployedAddresses[chainId].helpers.glpWrapper;
          if (
            fromToken == "0x5643F4b25E36478eE1E90418d5343cb6591BcB9d"
          ) {
            const result = await yakRouterContract.unwrapAndFindBestPath(
              amountIn, toToken, wrapper, maxSteps, gasPrice
            );
            // const data = {
            //   adapters: result.adapters,
            //   amounts: result.amounts,
            //   gasEstimate: result.gasEstimate,
            //   path: result.path
            // }
            return result;
          }
          else if (
            toToken == "0x5643F4b25E36478eE1E90418d5343cb6591BcB9d"
          ) {
            const result = await yakRouterContract.findBestPathAndWrap(
              amountIn, fromToken, wrapper, maxSteps, gasPrice
            );
            // const data = {
            //   adapters: result.adapters,
            //   amounts: result.amounts,
            //   gasEstimate: result.gasEstimate,
            //   path: result.path
            // }
            return result;
          }
          else {
            throw new Error("Token not found")
          }
        }
        catch {
          // console.log("err::fetchOnChainPricesV3");
        }
      }
    },
    async fetchOnchainPrices({dispatch, rootGetters}, payload) {
      let chainId = rootGetters['accountModule/selectedChainId']
      let provider = rootGetters['accountModule/provider']
      if (chainId && provider) {
        const { fromToken, toToken, amountIn } = payload;
        const routers = [];//DeployedAddresses[chainId].routers || [];
        const nativeWrappedToken = DeployedAddresses[chainId].yakSwap.nativeWrappedToken;

        let prices = await Promise.all(
          routers.map(
            async (router) => {
              let amountOut;
              let routerContract = new Contract(router.address, RouterABI, provider);

              try {
                let path = [fromToken, toToken];
                let amountsOut = await routerContract.getAmountsOut(amountIn, path);
                amountOut = amountsOut[amountsOut.length - 1];
              }
              catch {
                amountOut = BigNumber.from("0");
              }

              if (
                fromToken.toLowerCase() != nativeWrappedToken.toLowerCase()
                && toToken.toLowerCase() != nativeWrappedToken.toLowerCase()
                // && amountOut.eq("0")
              ) {
                  try {
                    let path = [fromToken, nativeWrappedToken, toToken]
                    let amountsOut = await routerContract.getAmountsOut(amountIn, path);
                    let tmpAmountOut = amountsOut[amountsOut.length - 1];
                    amountOut = amountOut.gt(tmpAmountOut) ? amountOut : tmpAmountOut;
                  }
                  catch {}
              }

              return {
                platform: router.platform,
                amountOut
              };
            }
          )
        );

        try {
          let yakOffer = await dispatch('fetchOnchainPricesV2', payload);
          prices.push({
            platform: "yak",
            amountOut: yakOffer.amounts[yakOffer.amounts.length - 1],
            yakOffer
          })
        }
        catch (err) {
          // console.log(err)
        }

        try {
          let yakOffer = await dispatch('fetchOnchainPricesV3', payload);
          prices.push({
            platform: "yak",
            amountOut: yakOffer.amounts[yakOffer.amounts.length - 1],
            yakOffer
          })
        }
        catch (err) {
          // console.log(err)
        }

        return prices;
      }
    },
    async fetchStakingData({ commit, rootGetters }) {
      let account = rootGetters['accountModule/account']
      let chainId = rootGetters['accountModule/selectedChainId']
      let provider = rootGetters['accountModule/provider']
      if (chainId && provider) {
        try {
        const masterYakAddress = DeployedAddresses[chainId].masterYak.address;
        const masterYakPools = DeployedAddresses[chainId].masterYak.pools;

        const masterYakContract = new Contract(masterYakAddress, MasterYakABI, provider);
        let rewardsPerSecond, totalAllocPoint, endTimestamp;
        [
          rewardsPerSecond,
          totalAllocPoint,
          endTimestamp
        ] = await Promise.all([
          masterYakContract.rewardsPerSecond(),
          masterYakContract.totalAllocPoint(),
          masterYakContract.endTimestamp()
        ])

        let stakingData = {
          rewardsPerSecond,
          totalAllocPoint,
          endTimestamp,
          pools: []
        };

        let tmp = await Promise.all(masterYakPools.map(async (pool, i) => {

          let poolData = await masterYakContract.poolInfo(i);

          let userData, pendingRewards;
          if (account) {
            [
              userData, pendingRewards
            ] = await Promise.all([
              masterYakContract.userInfo(i, account),
              masterYakContract.pendingRewards(i, account)
            ])
          }

          return {
            token: masterYakPools[i],
            totalStaked: poolData.totalStaked,
            allocPoint: poolData.allocPoint,
            userStaked: userData?.amount || BigNumber.from("0"),
            pendingRewards: pendingRewards || BigNumber.from("0"),
            pid: i
          }
        }))
        tmp = tmp.filter(pool => pool.allocPoint.gt("0") || pool.userStaked.gt("0"))
        stakingData['pools'] = tmp
        
        commit('setStakingData', stakingData);
      }
      catch {}
    }
    }
  },
  getters: {
    amountToClaim: (state) => state.amountToClaim,
    pollRate: (state) => state.pollRate,
    stakingData: (state) => state.stakingData,
  },
  beforeDestroy() {
    clearTimeout(this.handlePriceUpdates);
    clearTimeout(this.handleApyUpdates);
    clearTimeout(this.handleSwapFeeUpdates);
  }
});
