import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Buefy from "buefy";
import moment from 'moment';
import VueConfetti from 'vue-confetti';

Vue.use(VueConfetti);
Vue.use(Buefy);

Vue.config.productionTip = false;

Vue.filter("to2Dp", function (value) {
  if (!value) return value;
  return parseFloat(value).toFixed(2);
});

Vue.filter("to6Dp", function (value) {
  if (!value) return value;
  return parseFloat(value).toFixed(6);
});

Vue.filter("fromWei", function (value) {
  if (!value) return value;
  return parseFloat(value) / 10 ** 18;
});

Vue.filter("explorerTx", function (value) {
  return `https://snowtrace.io/tx/${value}`;
})

Vue.filter("portfolioTrackerAccount", function (value) {
  return `https://debank.com/profile/${value}`;
})

Vue.filter("relativeTime", function(value) {
  return moment.utc(value * 1000).fromNow();
});

Vue.filter("tinyTime", function(value) {
  const now = moment.utc()
  const diff = now.diff(moment.utc(value * 1000), 'days')
  return diff + 'd';
});

Vue.filter('contractName', function (value) {
  if (value == "0xdcedf06fd33e1d7b6eb4b309f779a0e9d3172e44") return "Treasury"
  else if (value == "0x5925c5c6843a8f67f7ef2b55db1f5491573c85eb") return "Treasury"
  else if (value == "0x0cf605484a512d3f3435fed77ab5ddc0525daf5f") return "Staking"
  else if (value == "0x73f2ee05e02c972a23222e60bd08ed66dc89d7a5") return "Contributors"
  else if (value == "0xd221fb68f3b15308b4ea1bd278b3057386330520") return "Contributors"
  else return value
})

Vue.filter('number', function (value) {
  value = parseFloat(value)

  if (typeof value !== "number") {
      return value;
  }

  let minimumFractionDigits = 0
  if (value < 10000) minimumFractionDigits = 2
  if (value < 10) minimumFractionDigits = 4
  if (value < 0.01) minimumFractionDigits = 6
  if (value < 0.0001) minimumFractionDigits = 8
  if (value < 0.000001) minimumFractionDigits = 10
  if (value == 0) minimumFractionDigits = 0

  var formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits
  });
  return formatter.format(value);
});

Vue.filter('compact', function (value) {
    value = parseFloat(value)

    if (typeof value !== "number") {
        return value;
    }

    let maximumSignificantDigits = 3

    var formatter = new Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumSignificantDigits
    });
    return formatter.format(value);
});

Vue.filter('usd_compact', function (value) {
    value = parseFloat(value)

    if (typeof value !== "number") {
        return value;
    }

    let maximumSignificantDigits = 3

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: 'compact',
        maximumSignificantDigits
    });
    return formatter.format(value);
});

import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, [
  { // default name 'currency'
    symbol: "",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
  {
    name: "percentage",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "percentage_1",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 1,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "percentage_2",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "percentage_4",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 4,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "percentage_6",
    symbol: "%",
    thousandsSeparator: ",",
    fractionCount: 6,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "percentageMax",
    symbol: "%+",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "back",
    symbolSpacing: false,
  },
  {
    name: "usd",
    symbol: "$",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
  {
    name: "usd_2",
    symbol: "$",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
  {
    name: "usd_4",
    symbol: "$",
    thousandsSeparator: ",",
    fractionCount: 4,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
  },
]);

Vue.filter("shortEth", function (value) {
  if (!value) return value;

  return `
  ${value.substr(0, 7)}...${value.substr(value.length - 5, value.length)}
  `;
});

Vue.filter("veryShortEth", function (value) {
  if (!value) return value;

  return `
  ${value.substr(0, 4)}...${value.substr(value.length - 2, value.length)}
  `;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
