<template>
  <div class="card" style="height: calc(100% - 48px); max-height: 256px">
    <a :class="'with-hover'" @click="click">
      <div :class="`card-content card-content-offset is-success`" style="height: 100%;">
        <div class="columns is-mobile">
          <!-- <div class="mb-1"> -->
          <div class="column">
            <p class="title is-size-2">
              {{ title }}
            </p>
          </div>
          <div class="column is-narrow">
            <b-icon icon="arrow-right" size="is-large" class="has-text-grey" />
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  import { ASSETS_URL } from '@/settings';

  export default {
    props: [
        'title',
        'click'
    ],
    data() {
      return {
        ASSETS_URL
      };
    },
  };
</script>
