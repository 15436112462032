<template>
  <section>
    <div v-if="isSwapSupported">
      <div class="container is-fullhd">
        <tool
          class="mt-3"
          title="Yak Swap"
          subtitle="Search for the best price, including gas costs and swap fees, execute with one click."
        ></tool>
        <div class="columns is-multiline is-centered pb-6">
          <div class="column is-4-desktop is-6-tablet is-12-mobile">
            <div class="card">
              <div class="card-content">
                <BalanceInput
                  v-bind:selectedToken="fromToken"
                  v-on:updateSelectedToken="onFromTokenChanged"
                  v-bind:amountIn="amountIn"
                  v-on:updateAmountIn="onAmountInChanged"
                  :selectedTokenBalance="fromTokenBalance"
                  :hideDropdown="false"
                  :readonly="false"
                />
              </div>
            </div>
              <b-field class="toggle-button has-text-centered">
                <b-button
                  class="p-4"
                  type="is-dark"
                  rounded
                  @click="toggle()"
                  :disabled="isSwapping"
                >
                  <b-icon icon="arrow-down" class="has-text-grey" />
                </b-button>
              </b-field>
            <div class="card">
              <div class="card-content">
                <BalanceInput
                  v-bind:selectedToken="toToken"
                  v-on:updateSelectedToken="onToTokenChanged"
                  v-bind:amountIn="bestAmountOut"
                  :selectedTokenBalance="toTokenBalance"
                  :hideDropdown="false"
                  :readonly="true"
                />
              </div>
            </div>
            <div class="level is-mobile mt-1 mb-0">
              <div class="level-left">
                <div class="level-item">
                  <ReversiblePriceButton
                    v-if="trade"
                    class="pl-4"
                    :fromToken="fromToken"
                    :toToken="toToken"
                    :trade="trade"
                  />
                </div>
              </div>
              <div class="level-right mr-4">
                <div class="level-item">
                  <b-tooltip label="Refresh price" type="is-light">
                    <b-button
                      type="is-ghost is-dark"
                      class="p-2"
                      rounded
                      expanded
                      :loading="isFetching"
                      :disabled="!isValidInputs || isSwapping"
                      @click="getPrices"
                    >
                      <b-icon icon="refresh" custom-class="has-text-grey" />
                    </b-button>
                  </b-tooltip>
                </div>
                <div class="level-item">
                  <b-tooltip label="Settings" type="is-light">
                    <b-button
                      type="is-ghost is-dark"
                      class="p-2"
                      rounded
                      expanded
                      @click="isSettingsOpen = !isSettingsOpen" 
                    >
                      <b-icon icon="cog" />
                    </b-button>
                  </b-tooltip>
                </div>
              </div>
            </div>
            <b-collapse v-model="isSettingsOpen">
              <div class="card mb-2">
                <div class="card-content">
                  <div class="level is-mobile my-2">
                    <div class="level-left">
                      <div class="level-item">
                        <p>Allowed Slippage</p>
                      </div>
                    </div>
                    <div class="level-right has-text-weight-semibold">
                      <div class="level-item">
                        <b-field>
                            <b-radio-button v-model="slippageBips" size="is-small"
                                :native-value="0"
                            >{{ 0 | percentage_1 }}</b-radio-button>
                            <b-radio-button v-model="slippageBips" size="is-small"
                                :native-value="20"
                            >{{ 0.2 | percentage_1 }}</b-radio-button>
                            <b-radio-button v-model="slippageBips" size="is-small"
                                :native-value="50"
                            >{{ 0.5 | percentage_1 }}</b-radio-button>
                            <b-radio-button v-model="slippageBips" size="is-small"
                                :native-value="100"
                            >{{ 1 | percentage_1 }}</b-radio-button>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <div class="level is-mobile my-2">
                    <div class="level-left">
                      <div class="level-item">
                        <p>Min. to Receive</p>
                      </div>
                    </div>
                    <div class="level-right has-text-weight-semibold">
                      <div class="level-item">
                        <p v-if="minAmountOut">{{ minAmountOut | number }} {{ toToken.symbol}}</p>
                        <p v-else>0 {{ toToken.symbol}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="level is-mobile my-2">
                    <div class="level-left">
                      <div class="level-item">
                        <p>Yak Swap Fee</p>
                      </div>
                    </div>
                    <div class="level-right has-text-weight-semibold">
                      <div class="level-item">
                        <p>0 {{ toToken.symbol }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <div class="buttons">
              <b-button
                v-if="account && isBalanceSufficient"
                expanded
                type="is-primary" 
                size="is-large" 
                :loading="isSwapping"
                :disabled="isSwapDisabled"
                @click="swap"
              >
                Swap
              </b-button>
              <b-button
                v-else-if="account && !isBalanceSufficient"
                expanded
                type="is-primary" 
                size="is-large" 
                :loading="isSwapping"
                disabled
              >
                Insufficient Balance
              </b-button>
              <ConnectButton
                v-else
                expanded 
                type="is-primary" 
                size="is-large"
                customVerb="to Swap"
                :hideIcon="true"
                :hideLabel="false" 
              />
            </div>
          </div>
          <div class="column pt-3 pl-3 is-8-desktop is-6-tablet is-12-mobile">
            <div class="columns is-multiline">
              <div class="column is-12" v-if="!trade && !isFetching && !isErrorFindingTrade">
                <IntroPanel />
              </div>
              <!-- <div class="column is-12" v-else-if="!trade && isFetching">
                Loading...
              </div> -->
              <div class="column is-12" v-else-if="!trade && !isFetching && isErrorFindingTrade">
                <TradeRouteNotFoundPanel :fromToken="fromToken" :toToken="toToken" />
              </div>
              <div class="column is-12" v-else-if="trade">
                <TradeRoutePanel :trade="trade" />
              </div>
              <!-- <div class="column is-12" v-if="trade">
                <PoolsAvailablePanel :trade="trade" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container is-fullhd mt-3 mb-6">
        <PageNotAvailable />
      </div>
    </div>
  </section>
</template>

<style scoped>
.toggle-button {
  height: 0.5rem;
  z-index: 1;
  position: relative;
  margin-top: -0.5rem;
}
</style>

<script>
  import { mapGetters } from 'vuex';
  import { constants, utils } from "ethers";

  import Balance from "@/components/Balance";
  import BalanceInput from "@/components/Swap/BalanceInput";
  import ConnectButton from "@/components/Buttons/Connect";
  import MaxButton from "@/components/Buttons/Max";
  import PageNotAvailable from "@/components/PageNotAvailable";
  import ReversiblePriceButton from "@/components/Buttons/ReversiblePrice";
  import Tokens from '@/components/Tokens';
  import DeployedAddresses from '@/contracts/Deployed';
  import TokenListAddresses from '@/contracts/TokenList';
  import Tool from "@/components/Home/Tool";
  import TokenSelector from '@/components/Swap/TokenSelector';
  import IntroPanel from '@/components/Swap/IntroPanel';
  import TradeRoutePanel from '@/components/Swap/TradeRoutePanel'
  import TradeRouteNotFoundPanel from '@/components/Swap/TradeRouteNotFoundPanel'
  import PoolsAvailablePanel from '@/components/Swap/PoolsAvailablePanel'

  const POLL_RATE = 5 * 1000;

  export default {
    components: {
      Balance,
      BalanceInput,
      ConnectButton, 
      MaxButton,
      PageNotAvailable,
      ReversiblePriceButton,
      Tokens,
      TokenSelector,
      Tool,
      IntroPanel,
      TradeRoutePanel,
      TradeRouteNotFoundPanel,
      PoolsAvailablePanel
    },
    props: [
      'inputCurrency',
      'outputCurrency'
    ],
    computed: {
      ...mapGetters({
        account: 'accountModule/account',
        chainId: 'accountModule/selectedChainId',
        tokenBalance: 'balances/balanceOfToken',
        isSwapSupported: 'swap/isSupported',
        tokenList: 'swap/tokenList'
      }),
      wrappedAssetAddress: function () {
        return DeployedAddresses[this.chainId].yakSwap.nativeWrappedToken
      },
      minAmountOut: function () {
        if (this.trade) {
          let amountWithSlippage = this.trade.amounts[this.trade.amounts.length - 1].mul(10000 - this.slippageBips).div("10000");
          return utils.formatUnits(amountWithSlippage, this.toToken.decimals);
        }
        return null;
      },
      bestAmountOut: function () {
        if (this.trade) {
          let amountWithoutSlippage = this.trade.amounts[this.trade.amounts.length - 1];//.mul(10000 - this.slippageBips).div("10000");
          return utils.formatUnits(amountWithoutSlippage, this.toToken.decimals);
        }
        return null;
      },
      fromTokenBalance: function () {
        return this.tokenBalance(this.fromToken.address)
      },
      toTokenBalance: function () {
        return this.tokenBalance(this.toToken.address)
      },
      isBalanceSufficient: function () {
        let tmp
        if (!this.amountIn) {
          tmp = "0"
        }
        else {
          tmp = utils.parseUnits(this.amountIn.toString(), this.fromToken.decimals)
        }
        return this.account && this.fromTokenBalance?.gte(tmp)
      },
      isSwapDisabled: function () {
          return this.isFetching || !this.account || !this.trade || this.fromTokenBalance.lt(
            this.trade.amounts[0]
          );
      },
      isValidInputs: function () {
        if (!this.amountIn || this.amountIn == 0 || !this.fromToken || !this.toToken) {
          return false;
        }
        else if (this.fromToken.address == this.toToken.address) {
          return false;
        }
        return true;
      },
    },
    data() {
      return {
        amountIn: null,
        slippageBips: 20,
        fromToken: null,
        toToken: null,
        isFetching: false,
        isSwapping: false,
        polling: null,
        prices: null,
        trade: null,
        isErrorFindingTrade: false,
        isSettingsOpen: false
      }
    },
    beforeMount() {
      this.fromToken = this.tokenList && this.tokenList[0];
      this.toToken = this.tokenList && this.tokenList[1];
      if (this.inputCurrency) {
        let found = this.tokenList && this.tokenList.find(row => row.address.toLowerCase() == this.inputCurrency.toLowerCase())
        if (found) {
          this.fromToken = found
        }
      }
      if (this.outputCurrency) {
        let found = this.tokenList && this.tokenList.find(row => row.address.toLowerCase() == this.outputCurrency.toLowerCase())
        if (found) {
          this.toToken = found
        }
      }
      this.getPrices();
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    watch: {
      tokenList: function(val) {
        // note: tokenList changes when chainId changes
        this.fromToken = val && val[0];
        this.toToken = val && val[1];
        this.amountIn = null;
        this.trade = null;
        this.isErrorFindingTrade = false;
      },
      fromToken: function () {
        this.prices = null;
        this.trade = null;
        this.isErrorFindingTrade = false;
        this.getPrices();
        this.updateUserTokenBalances();
      },
      toToken: function () {
        this.prices = null;
        this.trade = null;
        this.isErrorFindingTrade = false;
        this.getPrices();
        this.updateUserTokenBalances();
      },
      amountIn: function () {
        this.prices = null;
        this.trade = null;
        this.isErrorFindingTrade = false;
      },
      account: function() {
        this.updateUserTokenBalances();
      }
    },
    methods: {
      async handlePolling() {
        // console.log("Swap/handlePolling", new Date())
        await this.updateUserTokenBalances();
        this.polling = setTimeout(this.handlePolling, POLL_RATE);
      },
      mapTokenSymbol(address) {
        if (this.chainId) {
          try {
            let tmp = TokenListAddresses[this.chainId].filter(el => el.address.toLowerCase().indexOf(address.toLowerCase()) !== -1)
            return tmp[0].symbol;
          }
          catch (err) { console.log("debug::mapTokenSymbol", err) }
        }
        return "Unknown Token"
      },
      toggle() {
        let tmp = this.fromToken;
        this.amountIn = this.bestAmountOut;
        this.fromToken = this.toToken;
        this.toToken = tmp;
        if (this.amountIn) {
          this.getPrices();
        }
      },
      async updateUserTokenBalances() {
        await this.$store.dispatch('balances/loadData', [this.fromToken.address, this.toToken.address])
      },
      onAmountInChanged(val) {
        if (!val) {
          this.trade = null
        }
        this.amountIn = val
        this.getPrices()
      },
      onFromTokenChanged(val) {
        this.amountIn = null
        this.fromToken = val
      },
      onToTokenChanged(val) {
        this.toToken = val
      },
      async swap() {
        this.isSwapping = true;

        let tradeWithSlippage = {
          ...this.trade,
          amounts: this.trade.amounts.map((amount, index) => index == 0 ? amount : amount.mul(10000 - this.slippageBips).div("10000"))
        }

        let payload = {
          trade: tradeWithSlippage,
          fromNative: this.fromToken.address == constants.AddressZero,
          toNative: this.toToken.address == constants.AddressZero
        }
        let result = await this.$store.dispatch('swap/swap', payload);
        if (result) {
          this.prices = null;
          this.trade = null;
          this.isErrorFindingTrade = false;
        }
        this.updateUserTokenBalances();
        this.isSwapping = false;
      },
      async getPrices() {
        if (!this.amountIn) {
          return
        }

        this.isFetching = true;
        const payload = {
          fromToken: this.fromToken.address == constants.AddressZero ? this.wrappedAssetAddress : this.fromToken.address,
          toToken: this.toToken.address == constants.AddressZero ? this.wrappedAssetAddress : this.toToken.address,
          amountIn: utils.parseUnits(this.amountIn.toString(), this.fromToken.decimals)
        }

        let result = await this.$store.dispatch('fetchOnchainPrices', payload);
        if (!result) {
          this.isFetching = false;
          this.trade = null;
          this.isErrorFindingTrade = false;
          return;
        }

        function compare(a, b) {
          if (a.lt(b)) {
            return 1
          }
          else if (a.gt(b)) {
            return -1
          }
          return 0
        }

        let sortedPrices = result
          .filter((row) => row.amountOut)
          .sort((a, b) => compare(a.amountOut, b.amountOut))

        for (let i in sortedPrices) {
          let bestPrice = sortedPrices[0].amountOut.mul(10000 - this.slippageBips).div("10000");
          let amountOut = sortedPrices[i].amountOut.mul(10000 - this.slippageBips).div("10000");
          let vsBestPrice = amountOut.sub(bestPrice);
          let vsBestPricePercent = parseFloat(vsBestPrice) / parseFloat(bestPrice);
          
          sortedPrices[i]['vsBestPrice'] = vsBestPrice;
          sortedPrices[i]['vsBestPricePercent'] = vsBestPricePercent;

          if (sortedPrices[i].yakOffer) {
            this.trade = sortedPrices[i].yakOffer;
          }
        }

        this.prices = sortedPrices;
        this.isFetching = false;
        this.isErrorFindingTrade = true;
      }
    },
  };
</script>