<template>
    <div class="level is-mobile" v-if="tokens">
        <div class="level-left" v-if="alignment == 'is-left'">
            <div class="level-item">
                <figure :class="`image is-${height}x${height}`" :style="`z-index: ${tokens.length - (index+1)}; ${index > 0 && 'margin-left: -16px;'}`" v-for="(token, index) in tokens" :key="token">
                    <img :width="height" :height="height" class="is-rounded" :src="src(token)" />
                </figure>
            </div>
        </div>
        <div class="level-item" :style="`margin-left: ${marginLeft}px`" v-else>
            <figure :class="`image is-${height}x${height} m-0`" v-for="(token, index) in tokens" :key="token">
                <img :width="height" :height="height" :class="`is-rounded ${size}`" :style="`margin-left: -${16 * index}px;`" :src="src(token)" />
            </figure>
        </div>
    </div>
    <div class="level is-mobile" v-else>
        <div class="level-left" v-if="alignment == 'is-left'">
            <div class="level-item">
                <figure :class="`image is-${height}x${height}`">
                    <b-skeleton circle :width="height" :height="height" class="is-rounded"></b-skeleton>
                </figure>
            </div>
        </div>
        <div class="level-item" v-else>
            <figure :class="`image is-${height}x${height} m-0`">
                <b-skeleton circle :width="height" :height="height" class="is-rounded"></b-skeleton>
            </figure>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { srcForToken } from '@/services/utils'

  export default {
    props: [
        'chainId',
        'tokens',
        'size',
        'alignment'
    ],
    computed: {
        ...mapGetters({
            selectedChainId: 'accountModule/selectedChainId'
        }),
        height: function () {
            switch (this.size) {
                case "is-small":
                    return "32"
                case "is-normal":
                    return "48"
                case "is-medium":
                    return "64"
                case "is-large":
                    return "96"
                default:
                    return "24"
            }
        },
        marginLeft: function () {
            switch (this.alignment) {
                case "is-left":
                    return 0
                default:
                    return 8 * (this.tokens.length - 1)
            }
        },
    },
    methods: {
        src: function (asset) {
            return srcForToken(this.chainId || this.selectedChainId, asset)
        }
    },
  };
</script>
