<template>
    <b-navbar-item :active="$route.name == routeName" tag="router-link" :to="{ name: routeName }">
        <div class="level is-mobile">
            <div class="level-right">
                <div class="level-item is-hidden-tablet">
                    <b-icon :icon="icon" class="mr-2" />
                </div>
                <div class="level-item">
                    <p class="title is-size-5">{{ title }}</p>
                </div>
            </div>
        </div>
    </b-navbar-item>
</template>

<script>

    export default {
        props: [
            'routeName',
            'icon',
            'title',
            'subtitle'
        ]
    }

</script>