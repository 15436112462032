<template>
    <section class="hero">
        <div class="hero-head">
            <div class="universe-background"></div>
        </div>
            <!-- <div class="universe-background"></div> -->
        <div class="hero-body">
            <div class="container is-fullhd">
                <p class="title is-size-2 has-text-centered-mobile">
                    Yield Yak Universe
                </p>
                <div class="columns is-multiline is-mobile has-text-centered">
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/deltaprime.png'" alt="DeltaPrime" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/curve.png'" alt="Curve" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/defillama.png'" alt="DeFi Llama" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/cmc.png'" alt="CoinMarketCap" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/aave.png'" alt="Aave" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/avalanche.png'" alt="Avalanche" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/traderjoe.png'" alt="Trader Joe" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/coingecko.png'" alt="CoinGecko" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/gmx.png'" alt="GMX" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/graph.png'" alt="The Graph" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/pangolin.png'" alt="Pangolin" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/deltaprime.png'" alt="DeltaPrime" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/benqi.png'" alt="Benqi" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/balancer.png'" alt="Balancer" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/camelot.png'" alt="Camelot" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/synapse.png'" alt="Synapse" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/wombex.png'" alt="Wombex" />
                    </div>
                    <div class="column is-2-desktop is-3-tablet is-6-mobile py-5">
                        <img :src="ASSETS_URL + '/logos/rect/deltaprime.png'" alt="DeltaPrime" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.universe-background {
  background-image: url(../../assets/home/universe_background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 375px;
}
</style>

<script>
import { ASSETS_URL } from '@/settings';


export default {
    data() {
      return {
        ASSETS_URL
      };
    },
}
</script>