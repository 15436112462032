<template>
    <b-button type="is-dark">
        <div class="level is-mobile">
            <div class="level-item">
                <Tokens 
                    :tokens="tokens" 
                    size="is-small"
                    alignment="is-left"
                />
            </div>
            <div class="level-item" v-if="symbol">
                <span>
                    {{ symbol }}
                </span>
            </div>
        </div>
    </b-button>
</template>

<script>
  import Tokens from "@/components/Tokens";

  export default {
    components: {
        Tokens
    },
    props: [
        'symbol',
        'tokens'
    ],
  };
</script>
