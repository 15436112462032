<template>
    <div>
        <div class="card px-0 py-2">
            <b-tabs expanded :size="size" v-model="activeTab">
                <b-tab-item label="Deposit" headerClass="has-text-weight-semibold" :disabled="farm.deprecated">
                    <div class="block p-4">
                        <BalanceInput
                            v-bind:selectedToken="farm.depositToken"
                            v-bind:amountIn="depositAmount"
                            v-on:updateAmountIn="onDepositAmountChanged"
                            :chainId="farm.chainId"
                            :selectedTokenBalance="depositTokenBalance"
                            :hideDropdown="true"
                            :readonly="false"
                        />
                        <div v-if="!isTiny">
                            <p class="content is-size-6" v-if="isDepositFee">
                                <b-icon
                                    icon="alert"
                                    type="is-warning"
                                    size="is-small">
                                </b-icon>
                                Before you deposit. The underlying platform charges a variable fee on deposits. Do your own research.
                            </p>
                            <p class="content is-size-6" v-if="isWithdrawFee">
                                <b-icon
                                    icon="alert"
                                    type="is-warning"
                                    size="is-small">
                                </b-icon>
                                Before you deposit. The underlying platform charges a variable fee on withdrawals. Do your own research.
                            </p>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Withdraw" headerClass="has-text-weight-semibold">
                    <div class="block p-4">
                        <div v-if="!isTiny && account" class="mb-4">
                            <p class="heading">My Deposits</p>
                            <div v-if="accountStakedDepositTokens && accountStakedDepositTokens.gt('0')">
                                <p class="title is-size-3">
                                    <balance
                                        :amount="accountStakedDepositTokens.toString()"
                                        :symbol="farm.depositToken.symbol"
                                        :underlying="farm.depositToken.underlying"
                                        :decimals="farm.depositToken.decimals || 18"
                                        :animationDuration="300"
                                    />
                                </p>
                                <p class="subtitle is-size-5 has-text-grey" v-if="safeActiveDeposit">
                                    <!-- <span v-if="accountTvl">{{ accountTvl | usd_compact }}</span>
                                    <span v-if="safeActiveDeposit">{{ safeActiveDeposit | number }}</span> -->
                                    <balance
                                        label="Starting: "
                                        :amount="safeActiveDeposit"
                                        :decimals="farm.depositToken.decimals || 18"
                                    />
                                </p>
                            </div>
                            <div v-else-if="accountStakedDepositTokens && accountStakedDepositTokens.eq('0')">
                                <p class="title is-size-3 has-text-grey">
                                    0 {{ this.farm.depositToken.symbol }}
                                </p>
                                <p class="subtitle is-size-5 has-text-warning">
                                    You do not have any {{ this.farm.depositToken.symbol }} deposits.
                                </p>
                            </div>
                            <p class="title is-size-3 has-text-grey" v-else>
                                <b-skeleton width="40%" size="is-large" />
                            </p>
                        </div>
                    <p class="heading">Withdraw Amount</p>
                    <p class="title is-size-3">
                        {{ withdrawPercent | percentage }}
                    </p>
                    <p class="subtitle is-size-5 has-text-grey" v-if="withdrawAmount">
                        {{ withdrawAmount | number }} {{ this.farm.depositToken.symbol }}
                    </p>
                    <p class="subtitle is-size-5 has-text-grey" v-else>
                        0 {{ this.farm.depositToken.symbol }}
                    </p>
                    <b-field>
                        <b-slider 
                            v-model="withdrawPercent" 
                            :tooltip="false" 
                            type="is-primary" 
                            size="is-large" 
                            rounded
                            :disabled="isWithdrawLoading || isWithdrawDisabled"
                        />
                    </b-field>
                    <b-field>
                        <div class="columns is-mobile is-variable is-1">
                            <p class="column control is-3">
                                <b-button rounded size="is-small" @click="setWithdrawPercent(25)" :disabled="isWithdrawLoading || isWithdrawDisabled" expanded>{{ 25 | percentage }}</b-button>
                            </p>
                            <p class="column control is-3">
                                <b-button rounded size="is-small" @click="setWithdrawPercent(50)" :disabled="isWithdrawLoading || isWithdrawDisabled" expanded>{{ 50 | percentage }}</b-button>
                            </p>
                            <p class="column control is-3">
                                <b-button rounded size="is-small" @click="setWithdrawPercent(75)" :disabled="isWithdrawLoading || isWithdrawDisabled" expanded>{{ 75 | percentage }}</b-button>
                            </p>
                            <p class="column control is-3">
                                <b-button rounded size="is-small" @click="setWithdrawPercent(100)" :disabled="isWithdrawLoading || isWithdrawDisabled" expanded>{{ 100 | percentage }}</b-button>
                            </p>
                        </div>
                    </b-field>
                    </div>
                </b-tab-item>
                <b-tab-item label="Info" headerClass="has-text-weight-semibold" v-if="showInfoTab">
                    <div class="block p-4">
                        <StrategyInfo 
                            v-if="farm"
                            :chainId="farm.chainId"
                            :address="address" 
                            :depositToken="farm.depositToken" 
                            :deprecated="farm.deprecated" 
                            :platform="farm.platform"
                            :tags="farm.tags" 
                        />
                        <p class="heading">Last Reinvest</p>
                        <p class="title is-size-3">
                            <b-icon icon="clock-outline" />
                            <span v-if="lastReinvest">{{ lastReinvest  | relativeTime }}</span>
                            <span v-else>None</span>
                        </p>
                        <p class="heading">Next Reinvest</p>
                        <p class="title is-size-3">
                            <balance
                                :amount="safePendingRewards.toString()"
                                :decimals="farm.rewardToken.decimals"
                                :symbol="farm.rewardToken.symbol"
                                :underlying="[farm.rewardToken.address]"
                                :animationDuration="300"
                            />
                        </p>
                        <p class="content">
                            {{ reinvestButtonToolTip }}
                            Pressing the button is optional.
                            <a href="https://docs.yieldyak.com/for-farmers/reinvest" target="_blank">Learn more&nbsp;&nearr;</a>
                        </p>
                        <b-button 
                            expanded
                            :size="size" 
                            :loading="isReinvestLoading" 
                            :disabled="!isReinvestEnabled" 
                            @click="reinvest" 
                        >
                            Reinvest
                        </b-button>
                    </div>
                </b-tab-item>
            </b-tabs>
        </div>
        <div class="mt-2" v-if="activeTab == 0">
            <div class="block has-text-grey mx-4" v-if="!isTiny">
                <div class="level is-mobile my-0">
                    <div class="level-left">
                        <div class="level-item">
                            <p>Deposit Fee</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <p>{{ 0 | percentage }}</p>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile my-0">
                    <div class="level-left">
                        <div class="level-item">
                            <p>Withdraw Fee</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <p>{{ 0 | percentage }}</p>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile my-0">
                    <div class="level-left">
                        <div class="level-item">
                            <p>Performance Fee</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <p>{{ 10 | percentage }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <b-button
                    v-if="account && isBalanceSufficient"
                    expanded
                    :size="size"
                    type="is-primary"
                    :loading="isDepositLoading"
                    :disabled="isDepositDisabled || !isValidDepositAmount"
                    @click="deposit(depositAmount)"
                >
                    Deposit
                </b-button>
                <b-button
                    v-else-if="account && !isBalanceSufficient"
                    expanded
                    :size="size"
                    type="is-primary"
                    :loading="isDepositLoading"
                    disabled
                >
                    Insufficient Balance
                </b-button>
                <ConnectButton
                    v-else
                    expanded
                    type="is-primary"
                    :size="size"
                    customVerb="to Deposit"
                    :hideIcon="true"
                    :hideLabel="false"
                />
            </div>
            <div>
                <LiquidityAdd
                    v-if="farm"
                    :chainId="farm.chainId"
                    :platform="farm.platform"
                    :deprecated="farm.deprecated"
                    :depositTokenAddress="farm.depositToken.address"
                    :underlying="farm.depositToken.underlying"
                    :symbol="farm.depositToken.symbol"
                    :showUnderlying="false"
                    customClass="py-2"
                    type="is-rounded"
                />
            </div>
        </div>
        <div class="mt-2" v-else-if="activeTab == 1">
            <div class="buttons">
                <b-button
                    v-if="account"
                    expanded
                    type="is-primary"
                    :size="size" 
                    :loading="isWithdrawLoading"
                    :disabled="isWithdrawDisabled || !isValidWithdrawAmount"
                    @click="withdrawPercentage"
                >
                    Withdraw
                </b-button>
                <ConnectButton
                    v-else
                    expanded
                    type="is-primary"
                    :size="size"
                    customVerb="to Withdraw"
                    :hideIcon="true"
                    :hideLabel="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { utils, BigNumber } from "ethers";

  import Tokens from "@/components/Tokens";
  import Balance from "@/components/Balance";
  import BalanceInput from "@/components/Swap/BalanceInput";
  import LiquidityAdd from "@/components/Buttons/LiquidityAdd";
  import MaxButton from "@/components/Buttons/Max";
  import ConnectButton from "@/components/Buttons/Connect";
  import StrategyInfo from "@/components/Detail/StrategyInfo";

  import { mapPlatformName } from '@/services/utils'

  export default {
    components: {
      Balance,
      BalanceInput,
      ConnectButton,
      MaxButton,
      Tokens,
      StrategyInfo,
      LiquidityAdd
    },
    props: [
        'farm',
        'address',
        'size',
        'isTiny',
        'showInfoTab'
    ],
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            accountFarmBalances: 'balances/farmBalances',
            activeDeposit: 'deposits/activeDeposit',
            apyForFarm: 'apy/apyForFarm',
            tokenBalances: 'balances/data',
            lastReinvestForFarm: 'apy/lastReinvestForFarm',
        }),
        lastReinvest: function () {
            return this.lastReinvestForFarm(this.address)?.timestamp
        },
        isDepositFee: function () {
            return this.farm && this.farm.tags?.includes('depositFee')
        },
        isWithdrawFee: function () {
            return this.farm && this.farm.tags?.includes('withdrawFee')
        },
        accountStakedDepositTokens: function () {
            try {
                return this.accountFarmBalances(this.address).stakedDepositTokens
            }
            catch {
                // return BigNumber.from("0")
            }
        },
        accountReceiptTokens: function () {
            try {
                return this.accountFarmBalances(this.address).receiptTokens
            }
            catch {
                return BigNumber.from("0")
            }
        },
        accountTvl: function () {
            try {
                return this.accountFarmBalances(this.address).tvl
            }
            catch {
                return BigNumber.from("0")
            }
        },
        accountUnderlyingBalances: function () {
            let underlying
            try {
                underlying = this.farm.depositToken.underlying.map(address => {
                    if (address == "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7") address = "0x0000000000000000000000000000000000000000"
                    return {
                        address,
                        balance: this.tokenBalances[address] || BigNumber.from("0")
                    }
                })
            }
            catch (err) {
                console.log(err)
            }
            return underlying
        },
        depositTokenBalance: function () {
            return this.farm && this.tokenBalances && this.tokenBalances[this.farm.depositToken.address] || BigNumber.from("0");
        },
        reinvestButtonToolTip: function () {
            return this.farm.reinvestRewardBips && `This farm has a ${ this.farm.reinvestRewardBips / 100 }% reinvest reward paid in ${this.farm.rewardToken.symbol}.`
        },
        safeActiveDeposit: function () {
            return this.activeDeposit(this.address)
        },
        safePendingRewards: function () {
            return this.farm.pendingRewards && utils.parseUnits(this.farm.pendingRewards, this.farm.rewardToken.decimals || 18) || BigNumber.from("0");
        },
        isReinvestEnabled: function () {
            return this.account && this.safePendingRewards.gt("0");
        },
        isDepositDisabled: function () {
            return !this.account 
                || this.farm?.deprecated 
                || this.depositTokenBalance.eq("0");
        },
        isBalanceSufficient: function () {
            let tmp
            if (!this.depositAmount) {
                tmp = "0"
            }
            else {
                tmp = utils.parseUnits(this.depositAmount.toString(), this.farm.depositToken.decimals)
            }
            return this.account 
                && this.depositTokenBalance
                && this.depositTokenBalance.gt("0")
                && this.depositTokenBalance.gte(tmp)
        },
        isWithdrawDisabled: function () {
            return !this.account || this.accountReceiptTokens.eq("0");
        },
        isValidDepositAmount: function () {
            return this.account 
                && this.depositAmount 
                && utils.parseUnits(this.depositAmount, this.farm.depositToken.decimals).gt("0") 
                && utils.parseUnits(this.depositAmount, this.farm.depositToken.decimals).lte(this.depositTokenBalance);
        },
        isValidWithdrawAmount: function () {
            return this.account 
                && this.withdrawAmount 
                && utils.parseUnits(this.withdrawAmount, this.farm.depositToken.decimals).gt("0") 
                && utils.parseUnits(this.withdrawAmount, this.farm.depositToken.decimals).lte(this.accountStakedDepositTokens);
        },
        apyCompound: function () {
            return this.apyForFarm(this.address)
        },
        platformName: function () {
            return this.farm ? mapPlatformName(this.farm.platform) : null
        },
    },
    data() {
        return {
            activeTab: 0,
            depositAmount: null,
            withdrawAmount: null,
            withdrawPercent: null,
            isDepositLoading: false,
            isReinvestLoading: false,
            isWithdrawLoading: false
        }
    },
    watch: {
        withdrawPercent: function(val) {
            if (val) {
                this.withdrawAmount = utils.formatUnits(
                    this.accountStakedDepositTokens.mul(val).div("100"),
                    this.farm.depositToken.decimals
                );
            }
            else {
                this.withdrawAmount = null
            }
        }
    },
    methods: {
        onDepositAmountChanged(val) {
            const depositTokenDecimals = this.farm.depositToken.decimals

             // MetaMask has issue with precision over 20 decimals
            const maxPrecision = this.farm.depositToken.decimals - 20
            if (maxPrecision > 1) {
                const precision = utils.parseUnits("1", maxPrecision)
                const amountBN = utils.parseUnits(val, depositTokenDecimals).div(precision).mul(precision)
                this.depositAmount = utils.formatUnits(amountBN, depositTokenDecimals)
            }
            else {
                this.depositAmount = val
            }
        },
        setDepositAmount(percentOfBalance) {
            this.depositAmount = utils.formatUnits(
                this.depositTokenBalance.mul(percentOfBalance).div("100"),
                this.farm.depositToken.decimals
            );
        },
        setWithdrawPercent(percentOfBalance) {
            this.withdrawPercent = percentOfBalance;
        },
        async deposit() {
            this.isDepositLoading = true;
            let result = false;

            try {
                let payload = {
                    address: this.address,
                    amount: utils.parseUnits(this.depositAmount.toString(), this.farm.depositToken.decimals),
                }
                result = await this.$store.dispatch('deposit', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (result) {
                this.depositAmount = null;
                await this.$store.dispatch('balances/loadData', [this.address, this.farm.depositToken.address])
                this.$store.dispatch('deposits/loadData')
            }
            this.isDepositLoading = false;
        },
        async withdrawPercentage() {
            let receiptTokensToWithdraw = this.accountReceiptTokens.mul(this.withdrawPercent).div("100");
            if (receiptTokensToWithdraw.eq("0")) return;

            this.isWithdrawLoading = true;
            let result = false;

            try {
                let payload = {
                    address: this.address,
                    amount: receiptTokensToWithdraw,
                }
                result = await this.$store.dispatch('withdraw', payload);
            }
            catch (err) {
                console.log(err);
            }
            if (result) {
                await this.$store.dispatch('balances/loadData', [this.address, this.farm.depositToken.address])
                this.$store.dispatch('deposits/loadData')
                this.withdrawPercent = null;
            }
            this.isWithdrawLoading = false;
        },
        async reinvest() {
            this.isReinvestLoading = true;
            let result = false;

            try {
                result = await this.$store.dispatch('reinvest', this.address);
            }
            catch (err) {
                console.log(err);
            }
            if (result) {
                await this.$store.dispatch('balances/loadData', [this.address, this.farm.rewardToken.address])
            }

            this.isReinvestLoading = false;
        }
    }
  };
</script>
