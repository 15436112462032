<template>
<div>
    <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
            <b-button :size="size">
                <div class="level is-mobile">
                    <div class="level-item">
                        <figure class="image is-32x32">
                            <img width="24" height="32" class="is-rounded" :src="`${ASSETS_URL}/chains/${selectedChainId}.png`" :alt="name" />
                        </figure>
                    </div>
                    <div class="level-item" v-if="!isCompact">
                        <p>{{ name }}</p>
                    </div>
                    <div class="level-item">
                        <b-icon icon='chevron-up' size="is-normal" class="px-4" v-if="active" />
                        <b-icon icon='chevron-down' size="is-normal" class="px-4" v-else />
                    </div>
                </div>
            </b-button>
        </template>
        <b-dropdown-item 
            v-for="(chain, chainId) in supportedChains" 
            :key="chainId" 
            aria-role="menuitem" 
            @click="changeSelectedChainId(chainId)"
        >
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <figure class="image is-24x24">
                            <img width="24" height="24" class="is-rounded" :src="`${ASSETS_URL}/chains/${chainId}.png`" :alt="chain.chainName" />
                        </figure>
                    </div>
                    <div class="level-item">
                        <p class="title is-size-5">{{ chain.chainName }}</p>
                    </div>
                </div>
                <!-- <div class="level-left">
                    <div class="level-item">
                        <b-icon
                            v-if="selectedChainId == chainId && connectedChainId == chainId"
                            type="is-success"
                            icon="check-all">
                        </b-icon>
                        <b-icon
                            v-else-if="selectedChainId == chainId"
                            icon="check">
                        </b-icon>
                        <b-icon
                            v-else-if="connectedChainId == chainId"
                            type="is-warning"
                            size="is-small"
                            icon="wallet">
                        </b-icon>
                    </div>
                </div> -->
            </div>
        </b-dropdown-item>
    </b-dropdown>
</div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ASSETS_URL } from '@/settings';
  import { supportedChains } from '@/services/NetworkHelpers'

  export default {
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            selectedChainId: 'accountModule/selectedChainId',
            connectedChainId: 'accountModule/connectedChainId',
            shouldSwitchNetworkDisplay: 'accountModule/shouldSwitchNetworkDisplay',
            balance: 'balances/balanceOfNative'
        }),
        name: function() {
            try {
                let name = supportedChains[this.selectedChainId].chainName
                return name
            }
            catch {
                return null
            }
        },
    },
    props: [
        'isCompact',
        'size'
    ],
    data() {
      return {
        ASSETS_URL,
        supportedChains
      };
    },
    methods: {
        changeSelectedChainId(value) {
            this.$store.dispatch('accountModule/changeSelectedChainId', value)
        },
    }
  };
</script>
