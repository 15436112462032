<template>
    <section class="card">
            <div class="card-content">
        <!-- <div class="columns is-multiline"> -->
            <!-- <div class="column is-12"> -->
                <p class="title">
                    Claim
                </p>
                <div class="level is-mobile mb-1">
                    <div class="level-left">
                        <div class="level-item">
                            <p class="subtitle is-size-5">
                                <span>My Account</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="box is-dark">
                    <p class="heading">Staked</p>
                    <p class="title" v-if="accountStakedBalance(symbol)">
                        <balance
                            :amount="accountStakedBalance(symbol)"
                            :symbol="`yy${symbol}`"
                            :useText="true"
                            :animationDuration="100"
                            :underlying="[yyTokenAddress]"
                        />
                    </p>
                    <p class="title has-text-grey" v-else>
                        <balance
                            :amount="0"
                            :symbol="`yy${symbol}`"
                            :useText="true"
                            :animationDuration="100"
                            :underlying="[yyTokenAddress]"
                        />
                    </p>
                </div>
                <div class="box is-dark pb-4" v-if="pendingRewards(symbol)">
                    <p class="heading">Pending Rewards</p>
                    <p :class="`title ${item.amount.eq('0') && 'has-text-grey'}`" v-for="item in pendingRewards(symbol)" :key="item.address">
                        <balance
                            :amount="item.amount"
                            :symbol="item.symbol"
                            :useText="true"
                            :animationDuration="100"
                            :underlying="[item.address]"
                        />
                    </p>
                    <b-button 
                        size="is-large"
                        type="is-primary" 
                        :loading="claiming"
                        :disabled="isClaimDisabled"
                        @click="claim"
                        expanded
                    >Claim</b-button>
                </div>
            <!-- </div> -->
        <!-- </div> -->
        </div>
    </section>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { BigNumber } from 'ethers';

  import Balance from "@/components/Balance";
  import Tokens from "@/components/Tokens";

  const POLL_RATE = 10 * 1000;

  export default {
    components: {
        Balance, Tokens
    },
    props: [
        'symbol',
        'tokenAddress',
        'yyTokenAddress'
    ],
    data() {
        return {
            depositAmount: null,
            amountToReceive: null,
            claiming: false,
            polling: null
        }
    },
    watch: {
        depositAmount: async function() {
            this.handlePolling()
        }
    },
    computed: {
        ...mapGetters({
            account: 'accountModule/account',
            chainId: 'accountModule/selectedChainId',
            tokenBalances: 'balances/data',
            stakedBalance: 'yyPtp/stakedBalance',
            accountStakedBalance: 'yyPtp/accountStakedBalance',
            pendingRewards: 'yyPtp/pendingRewards'
        }),
        tokenBalance: function () {
            try {
                return this.tokenBalances[this.tokenAddress];
            }
            catch {}
        },
        yyTokenBalance: function () {
            try {
                return this.tokenBalances[this.yyTokenAddress];
            }
            catch {}
        },
        isClaimDisabled: function () {
            return !this.account 
                || !this.pendingRewards(this.symbol)
                || this.pendingRewards(this.symbol).reduce((sum, row) => sum = sum.add(row.amount), BigNumber.from("0")).eq("0")
        },
    },
    mounted() {
      this.handlePolling();
    },
    beforeDestroy() {
      clearTimeout(this.polling);
    },
    methods: {
        async updateData() {
            const payload = {
                symbol: this.symbol,
            }
            // await this.$store.dispatch('yyPtp/loadStakingData', payload)
        },
        async handlePolling() {
            await this.updateData()

            this.polling = setTimeout(this.handlePolling, POLL_RATE);
        },
        async claim() {
            this.claiming = true;
            let claimResult = false;
            try {
                const payload = {
                    symbol: this.symbol
                }
                claimResult = await this.$store.dispatch('yyPtp/claimRewards', payload)
            }
            catch (err) {
                console.log(err)
            }
            if (claimResult) {
                await this.updateData()
            }

            this.claiming = false;
        },
    }
  };
</script>
