<template>
    <div class="card">
        <div class="card-content">
            <p class="title is-size-5">
                Trade Route
            </p>
            <p class="subtitle">
                {{ `Yak Swap found a ${trade.adapters.length}-step route for your trade:` }}
            </p>
            <div class="box is-dark is-outlined">
                <div class="level is-mobile" style="justify-content: center;">
                    <div class="level-item is-narrow">
                        <Tokens 
                            :tokens="[fromTokenAddress]"
                            size="is-normal"
                        />
                    </div>
                    <div class="level-item is-narrow">
                        <p class="title is-size-3">{{ amountIn | number }} {{ mapTokenSymbol(fromTokenAddress) }}</p>
                    </div>
                    <div class="level-item is-narrow">
                        <b-icon icon="arrow-right" size="is-medium" />
                    </div>
                    <div class="level-item is-narrow">
                        <Tokens 
                            :tokens="[toTokenAddress]"
                            size="is-normal"
                        />
                    </div>
                    <div class="level-item is-narrow">
                        <p class="title is-size-3">{{ amountOut | number }} {{ mapTokenSymbol(toTokenAddress) }}</p>
                    </div>
                </div>
            </div>
            <div class="mb-2" v-for="(token, index) in trade.path" :key="index">
                <div class="box" v-if="index < trade.adapters.length">
                    <div class="level is-mobile">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="title is-size-5">{{ `Step ${index + 1}`}}</p>
                            </div>
                            <div class="level-item">
                                <figure class="image is-32x32 ml-2">
                                    <img class="is-rounded" :src="platformSource(mapPlatformId(trade.adapters[index]))" :alt="mapAdapterName(trade.adapters[index])" />
                                </figure>
                                <p class="subtitle ml-2">{{ mapAdapterName(trade.adapters[index]) }}</p>
                            </div>
                            <div class="level-item">
                                <Tokens 
                                    :tokens="[token]"
                                    :symbol="mapTokenSymbol(token)"
                                />
                            </div>
                            <div class="level-item">
                                <p class="title is-size-5">{{ mapTokenSymbol(token) }}</p>
                            </div>
                            <div class="level-item">
                                <b-icon icon="arrow-right" />
                            </div>
                            <div class="level-item">
                                <Tokens
                                    :tokens="[trade.path[index + 1]]"
                                    :symbol="mapTokenSymbol(trade.path[index + 1])"
                                />
                            </div>
                            <div class="level-item">
                                <p class="title is-size-5">{{ mapTokenSymbol(trade.path[index + 1]) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { utils } from 'ethers';
import ButtonToken from "@/components/Buttons/Token";
import Tokens from "@/components/Tokens";

import { mapPlatformName, mapPlatformSource } from '@/services/utils';
import DeployedAddresses from '@/contracts/Deployed';

export default {
    components: {
        ButtonToken,
        Tokens
    },
    props: [
      'trade'
    ],
    computed: {
      ...mapGetters({
        chainId: 'accountModule/selectedChainId',
        tokenList: 'swap/tokenList'
      }),
      fromTokenAddress: function() {
          return this.trade.path[0]
      },
      amountIn: function () {
          const amountIn = this.trade.amounts[0]
          const fromToken = this.mapTokenObject(this.fromTokenAddress)
          return utils.formatUnits(amountIn, fromToken.decimals)
      },
      toTokenAddress: function() {
          return this.trade.path[this.trade.path.length - 1]
      },
      amountOut: function() {
          const amountOut = this.trade.amounts[this.trade.amounts.length - 1]
          const toToken = this.mapTokenObject(this.toTokenAddress)
          return utils.formatUnits(amountOut, toToken.decimals)
      }
    },
    methods: {
      platformSource: function(platformId) {
        return mapPlatformSource(platformId)
      },
      mapPlatformId(adapterAddress) {
        try {
            return DeployedAddresses[this.chainId].yakSwap.adapters[adapterAddress].platform
        }
        catch { 
            console.log("debug::mapPlatformId", adapterAddress)
        }
      },
      mapTokenObject(address) {
          try {
            let tmp = this.tokenList.filter(el => el.address.toLowerCase().indexOf(address.toLowerCase()) !== -1)
            return tmp[0]
          }
          catch {
            console.log("debug::mapTokenObject token not found")
          }
      },
      mapTokenSymbol(address) {
        try {
            let token = this.mapTokenObject(address)
            return token.symbol;
        }
        catch (err) {
            console.log("debug::mapTokenSymbol", err)
            return "Unknown Token"
        }
      },
      mapAdapterName(address) {
        try {
            let platformId = this.mapPlatformId(address)
            return mapPlatformName(platformId)
        }
        catch { 
            console.log("debug::mapAdapterName", address)
            return "Unknown Dex"
        }
      },
    }
};
</script>